import { Component, Input } from '@angular/core';
// import { CustomRegisterationModel, EmailData, GenericRole, GenericUser, TokenRetrievalModel } from '../login/login.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { VisitorInfoService } from 'src/app/shared/services/visits/visitor-info.service';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { TokenRetrievalModel } from 'src/app/types/models/tokenretrieval.model';
import { EmailData } from 'src/app/types/models/emaildata.model';
import { CustomRegisterationModel } from 'src/app/types/models/customregistration.model';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { Observable, tap } from 'rxjs';
import { AppState } from 'src/app/ngrx/state/app.state';
import { select, Store } from '@ngrx/store';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { selectapplicationRoles } from 'src/app/ngrx/selectors/application-roles.selectors';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  allRole: GenericRole[] | any;

  genericUser: GenericUser | any;
  genericRoles: GenericRole[] | any;
  aPromoterRole: GenericRole | any;

  maiXpRoles: any;

  registrationForm: FormGroup | any;

  tokenModel: TokenRetrievalModel | any
  userToken: any;
  
  emailTemplate: string = '';
  regEmailDt: EmailData | any;

  passwordFieldType: string = 'password';

  appRoles$: Observable<GenericRole[] | null>
  

  @Input()
  asAPromoter: boolean = false

  constructor(private router: Router,
    private activtedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private visitorInfo: VisitorInfoService,
    private loadingService: LoaderService,
    private toastr: ToastrService,
    private store: Store<AppState>)
  {
    this.appRoles$! = this.store.pipe(select(selectapplicationRoles));

    this.registrationForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }


  ngOnInit()
  {

    this.activtedRoute.queryParams.subscribe((params) => {
      if (params['asAPromoter']) {
        this.asAPromoter = params['asAPromoter'] === 'true'; // Convert string to boolean
      }
    });

    this.appRoles$.pipe(
      tap(appRoles => {
        if(!environment.production)
        {
          console.log(`Current logged in user roles: ${JSON.stringify(appRoles)}`)
        }
      })
    ).subscribe()
    


    this.authService.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    .subscribe((data) => {

      if(!environment.production)
      {
        console.log(`All roles: ${JSON.stringify(data)}`);
      }
      
      this.allRole = data;
    })


  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  get password() {
    return this.registrationForm.get('password');
  }

  async onSubmit(isGeneral: boolean)
  {
    this.loadingService.show();

    // Handle form submission logic here
    if(this.registrationForm.valid)
    {
      if(!environment.production)
      {
        console.log(`Valid registration form values ${JSON.stringify(this.registrationForm?.value)}`);
      }
    
      const ipData = await this.visitorInfo.getIp();
      
      // generic user
      this.genericUser = new GenericUser(
        this.registrationForm?.value.username,
        this.registrationForm?.value.email,
        environment.AppBoundIdentifier, //app bound identifier
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ipData.ip.toString()
      )

      //If it is a general registration
      // role = mxp_General
      //Else
      // role = mxp_ActivityHost

      this.genericRoles = new Array<GenericRole>(); // Initialize the array
      
      //default user registration is generic role
      this.genericRoles.push(new GenericRole('mxp_General', environment.AppBoundIdentifier)); // Add an item

      if(this.asAPromoter)
      {
        this.genericRoles.push(new GenericRole('mxp_ActivityPromoter', environment.AppBoundIdentifier));
      }

      

      //pwd
      let pwd = this.registrationForm?.value.password;

      //
      let model = new CustomRegisterationModel(
        this.genericUser,
        this.genericRoles,
        pwd
      );

      if(!environment.production)
      {
        console.log(`Model to reg: ${JSON.stringify(model)}`);
      }

      //=========================================
      
      // #region send free tier email
      //send free tier email

      // const NotifyUserOfFreeTierObserver = {
      //   next: async (response: any) => {
      //     if(!environment.production)
      //     {
      //       console.log(`Response from email send out: ${response}`);
      //     }

      //     this.toastr.success("Email confirmation sent", "Success");

      //     this.loadingService.hide();
          
      //   },
      //   error: async (err: HttpErrorResponse) => {
      //     this.loadingService.hide();

      //     if(!environment.production)
      //     {
      //       console.log(`Error while sending email: ${JSON.stringify(err)}`);
      //     }

      //     this.toastr.error("Error while sending email", "Error");
      //   },
      //   complete: async () => {
      //     this.router.navigate(['login'])
      //   }
      // }

      // #endregion

      
      // #region assign user to free tier
      //assign user to free tier
      // const addUserToFreeTierObserver = {
      //   next: async (response: any) => {
      //     if(!environment.production)
      //     {
      //       console.log(`Response from email send out: ${response}`);
      //     }

      //     this.toastr.success("Email confirmation sent", "Success");

      //     this.loadingService.hide();
          
      //   },
      //   error: async (err: HttpErrorResponse) => {
      //     this.loadingService.hide();

      //     if(!environment.production)
      //     {
      //       console.log(`Error while sending email: ${JSON.stringify(err)}`);
      //     }

      //     this.toastr.error("Error while sending email", "Error");
      //   },
      //   complete: async () => {
      //     this.router.navigate(['login'])
      //   }
      // }

      // #endregion

      //email sender observer
      const emailUserObserver = {
        next: async (response: any) => {
          if(!environment.production)
          {
            console.log(`Response from email send out: ${response}`);
          }

          this.toastr.success("Email confirmation sent", "Success");

          this.loadingService.hide();
          
        },
        error: async (err: HttpErrorResponse) => {
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log(`Error while sending email: ${JSON.stringify(err)}`);
          }

          this.toastr.error("Error while sending email", "Error");
        },
        complete: async () => {
          this.router.navigate(['login'])
        }
      }

      //token Request Observer
      const tokenReqObserver = {
        next: async (data: any) => {
          //send email 
          this.userToken = data.Data.Value;
          
          if(!environment.production)
          {
            console.log(`Token reponse: ${JSON.stringify(this.userToken)}`);
          }

          //generate the registration template 
          let rstTemplateGenModel = {
            "encodedToken": this.userToken,
            "email": this.genericUser.Email,
          }

          this.authService.RegistrationTemplateGenerator(rstTemplateGenModel)
          .subscribe((response) => {

            (!environment.production) ?
            console.log(`Template gen response: ${response}`) : '';
            
            this.emailTemplate = response.toString();

            (!environment.production) ?
            console.log(`Email template response: ${this.emailTemplate}`) : '';
   
            this.regEmailDt = new EmailData(
              this.genericUser.Email,
              "Confirm Email",
              this.emailTemplate,
              environment.AppBoundIdentifier 
            )
            
            //send the email out 
            this.authService.SendConfirmRegistrationEmail(this.regEmailDt)
            .subscribe(emailUserObserver);
          })
        },
        error: async (err: HttpErrorResponse) => {
          console.log(`Token request error: ${JSON.stringify(err)}`);
          
        },
        complete: async () => {

        }
      } 
      

      //register user to a known application
      
      const registerObserver = {
        next: async (data: any) =>  {

          //notify user on success registration
          this.toastr.success('User registered!', 'Success')

          if(!environment.production)
          {
            console.log(`User registered successfully: ${JSON.stringify(data)}`);
          }
          
          //
          this.tokenModel = new TokenRetrievalModel(this.genericUser, true);
          
          if(!environment.production)
          {
            console.log(`User token model : ${this.tokenModel}`);
          }
          
          //request token to be used in email confirmation
          this.authService.RequestUserToken(this.tokenModel)
            .subscribe(tokenReqObserver)
        },
        error: (err: HttpErrorResponse) => 
        {
          this.loadingService.hide();

          const errResponse = err;

          if(!environment.production)
          {
            console.log(`Error response raw: ${errResponse} and JSON formatted ${JSON.stringify(err)}`);
          }

          //error can be different
          let possibleError = err.error?.Data?.Value[0]?.Description ??
            err.error?.Message;

          if(!environment.production)
          {
            console.log(`Possible error: ${possibleError}`);
          }

          this.toastr.error(`${possibleError}`, 'Registration error')

          //Email DEVELOPER on the error
        },
        complete: () => { 
          if(!environment.production)
          {
            console.log("User registration");
          }
        }
      }
      
      this.authService.RegisterUserForMaiXperience(model)
        .subscribe(registerObserver);
    }
  }

}
