<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Authentication">
        <div style="margin: 25px;">

            <!-- custom auth status -->
            <div>
                <label 
                    style="font-weight: bold;"
                    class="form-label"
                    for="stus">
                        Registration Status of MaiXperience on CustomAuth App: 
                </label>
        
                <div 
                class="input-group mb-3"
                *ngIf="appRegistedOnCustomAuth">
                    <!-- not registered -->
                    <button 
                    name="stus" 
                    disabled
                    class="btn btn-outline-danger form-control" 
                    style="color: red;">    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ban" viewBox="0 0 16 16">
                            <path d="M15 8a6.973 6.973 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8ZM2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Z"/>
                          </svg>
                    </button>
                </div>
        
                <div 
                class="input-group mb-3"
                *ngIf="!appRegistedOnCustomAuth">
                    <!-- registered -->
                    <button 
                    name="stus" 
                    disabled
                    class="btn btn-outline-success form-control" 
                    style="color: green;">    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                          </svg>
                    </button>
                </div>
            </div>
        
            <!-- custom auth activator -->
            
            <div 
                *ngIf="appRegistedOnCustomAuth">
            
                <label 
                    style="font-weight: bold;"
                    class="form-label"
                    for="isReg">
                        Click the button below to register to Custom Authenticator: 
                </label>
        
                <div class="input-group mb-3">
                    <button
                    name="isReg"
                    class="btn btn-primary form-control"
                    (click)="registerAppToCustomAuth()"
                    *ngIf="appRegistedOnCustomAuth">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="20" 
                            height="20" 
                            fill="currentColor" 
                            class="bi bi-r-circle" 
                            viewBox="0 0 16 16">
                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM5.5 4.002h3.11c1.71 0 2.741.973 2.741 2.46 0 1.138-.667 1.94-1.495 2.24L11.5 12H9.98L8.52 8.924H6.836V12H5.5V4.002Zm1.335 1.09v2.777h1.549c.995 0 1.573-.463 1.573-1.36 0-.913-.596-1.417-1.537-1.417H6.835Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </mat-tab>
    
    <!-- users tab -->
    <mat-tab label="Users">

        <div
            style="margin-top: 10px;"
            class="container">
            
            <button 
                style="float: right;"
                mat-raised-button
                (click)="ShowUserAddTemplate()"
                align="end" 
                color="primary">
                Add new user
            </button>
        </div>
    
        
        <br><br>

        <!-- no registered user -->
        <ng-container *ngIf="this.registeredUsers?.length == 0">
            
            <p 
            style="margin-top: 30px;"
            class="text-center">
                No user registered
            </p>
        </ng-container>

        <!-- we have user(s) -->
        <ng-container *ngIf="this.registeredUsers?.length > 0">
            <!-- {{this.registeredUsers | json}} -->
       
            <mat-accordion
            style="margin: 30px;"
            *ngFor="let user of this.registeredUsers">
                
                <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title> 
                            <!-- trash -->
                            <span 
                                (click)="removeUser(user.Id)"
                                style="color: red; margin-right: 10px;">
                            
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                </svg>
                            </span>
                            <!-- Fullname: {{user.Firstname ?? 'no-firstname'}} {{user.Lastname ?? 'no-lastname'}}      -->
                            {{user.UserName}}    
                        </mat-panel-title>
                        <mat-panel-description>

                            

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    Firstname: {{user.Firstname ?? 'no-firstname'}}  
                    <hr>
                    Lastname: {{user.Lastname ?? 'no-lastname'}}  
                    <hr>
                    Email: {{user.Email}}  
                    <hr>
                    Phone: {{user.PhoneNumber ?? 'no-phone'}}  
                    <hr>

                    <mat-action-row>
                        <button 
                        color="primary"
                        mat-flat-button 
                        (click)="showAddUserRoleDialog(user)">
                            Add role to user
                        </button>
                        <!-- <button mat-button (click)="nextStep()">End</button> -->
                    
                        
                        <!-- should only be visible if email is not confirmed -->
                        <button
                            *ngIf="!user.EmailConfirmed"
                            mat-raised-button
                            (click)="resendConfirmationEmail()"
                            color="primary">
                        
                            resend email confirm
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
              

        </ng-container>
          
    </mat-tab>

    <!-- roles tab -->
    <mat-tab label="Roles">
       
        <div
            style="margin-top: 10px;"
            class="container">
            
            <button 
                style="float: right;"
                mat-raised-button
                (click)="ShowRoleAddTemplate()"
                align="end" 
                color="primary">
                Add new role
            </button>
        </div>
    
        
        <br>
        <ng-container *ngIf="this.allRoles?.length == 0">
            
            <p 
            style="margin-top: 30px;"
            class="text-center">
                No role defined
            </p>
        </ng-container>

        <ng-container *ngIf="this.allRoles?.length > 0">
            <!-- {{this.allRoles | json}} -->

       
            <br>
            <mat-card *ngFor="let role of allRoles" style="margin: 30px;">
                <mat-card-header>
                    <mat-card-title>{{role.Name}}</mat-card-title>
                </mat-card-header>
                <mat-card-actions align="end">
                    <button 
                    (click)="removeRole(role.Id)"
                    style="color: red;"
                    mat-button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                        </svg>
                    </button>
                </mat-card-actions>
            </mat-card>
        </ng-container>
    </mat-tab>

    <!-- subscription(s) -->
    <mat-tab label="Subscriptions">
       
        <div
            style="margin-top: 10px;"
            class="container">
            
            <button 
                style="float: right;"
                mat-raised-button
                (click)="ShowSubscriptionTierAddTemplate()"
                align="end" 
                color="primary">
                Add Subscription Tier
            </button>
        </div>
    
        
        <br>
        <ng-container *ngIf="this.allSubTiers?.length == 0">
            
            <p 
            style="margin-top: 30px;"
            class="text-center">
                No subscription found
            </p>
        </ng-container>

        <ng-container *ngIf="this.allSubTiers?.length > 0">
       
            <br>
           
            <mat-accordion
            style="margin: 30px;"
            *ngFor="let subTier of allSubTiers">
                
                <mat-expansion-panel 
                    (opened)="panelOpenState.set(true)" 
                    (closed)="panelOpenState.set(false)">
                    
                    <mat-expansion-panel-header>
                        <mat-panel-title style="margin: 0"> 
                            <!-- trash -->
                            <span 
                                (click)="removeSubsciptionTier(subTier.Id)"
                                style="color: red; margin-right: 10px;">
                            
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                </svg>
                            </span>
                            
                            <div>
                                {{subTier.TierName}}   

                            </div>
                        </mat-panel-title>
                        <mat-panel-description>

                            

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    Max. No. of Activities: {{subTier.MaxActivitiesPerMonth}}
                    <hr>

                    Max. No. of Events: {{subTier.MaxEventsPerMonth}}
                    <hr>

                    Max. No. of Activity Promoters: {{subTier.MaxActivityPromoters ?? "0"}}
                    <hr>

                    Max. No. of Events Promoters: {{subTier.MaxEventPromoters ?? "0"}}
                    <hr>

                    Price: {{subTier.Price}}
                    <hr>

                    Plan Interval: {{subTier.planInterval}}
                    <hr>

                    Additional Features: {{subTier.AdditionalFeaturesCSV}}
                    <hr>
                    <!-- <mat-action-row>
                        <button 
                        color="primary"
                        mat-flat-button 
                        (click)="showAddUserRoleDialog(user)">
                            Add role to user
                        </button>
                        
                    </mat-action-row> -->
                </mat-expansion-panel>
            </mat-accordion>
              

        </ng-container>
    </mat-tab>

    <!-- user subscriptions -->
    <mat-tab label="User-Subscriptions">
       
        <div
            style="margin-top: 10px;"
            class="container">
            
            <button 
                style="float: right;"
                mat-raised-button
                (click)="ShowSubscriptionTierAddTemplate()"
                align="end" 
                color="primary">
                Add User Subscription Manually
            </button>
        </div>
    
        
        <br><br>
         <!-- no registered user -->
         <ng-container *ngIf="this.registeredUsers?.length == 0">
            
            <p 
            style="margin-top: 30px;"
            class="text-center">
                No user registered
            </p>
        </ng-container>

        <!-- we have user(s) -->
        
        <ng-container *ngIf="this.registeredUsers?.length > 0">
            <!-- {{this.registeredUsers | json}} -->
       
            <mat-accordion
            style="margin: 30px;"
            *ngFor="let usr of this.registeredUsers">
                
                <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                    <mat-expansion-panel-header>
                        <mat-panel-title> 
                            <!-- trash -->
                            <span 
                                (click)="removeUser(usr.Id)"
                                style="color: red; margin-right: 10px;">
                            
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                </svg>
                            </span>

                            {{usr.UserName}}    
                        </mat-panel-title>
                        <mat-panel-description>

                            

                        </mat-panel-description>
                    </mat-expansion-panel-header>


                    <mat-action-row 
                        *ngFor="let tier of allSubTiers">
                        <button 
                        color="primary"
                        mat-flat-button 
                        style="width: 100%;"
                        (click)="ManualUserSub(usr, tier)">
                            Add {{tier.TierName}} ({{tier.planInterval}}) to user
                        </button>
                       
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
              

        </ng-container>
          
    </mat-tab>
  </mat-tab-group>

