import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as SubscriptionTierActions from "../actions/current-user-subscription-tier.actions";

import { catchError, map, mergeMap, of, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/CustomAuthenticator/auth.service";
import { environment } from "src/environments/environment";
import { SubscriptionTier } from "src/app/types/models/SubscriptionRelated/SubscriptionTier.model";

@Injectable()
export class SubscriptionTierEffects {

    subscriptionTierOfUser$ = createEffect(() => this.actions$.pipe(
        ofType(SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER),
        mergeMap(action => this.authService.GetSubscriptionTierByIdForMaiXp(action.tierId)
        .pipe(
            tap((usersubscriptiontier: SubscriptionTier) => {
                if(!environment.production)
                {
                    console.log(`User subscription tier: ${ JSON.stringify(usersubscriptiontier) }`);
                }
            }),
            map(usersubscriptiontier => SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER_SUCCESS({ usersubscriptiontier })),
            catchError(error => of(SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}
