<div class="terms-container">
    <header class="header">
        <h1 style="text-decoration: underline;">
            Terms and Conditions
        </h1>
    </header>

    <section class="content">
      <p style="font-style: italic;">Last updated: October 29, 2024</p>

      <p>
        Welcome to MaiXperience! These Terms and Conditions ("Terms") govern your access to and use of the MaiXperience platform, including our website and mobile applications (collectively, the "Platform"). By accessing or using MaiXperience, you agree to abide by these Terms. If you do not agree with any part of these Terms, please do not use our Platform.

      </p>
      
        <h2>1. Introduction</h2>
        <p>
            1.1 MaiXperience provides a platform to connect users with a variety of activities, events, and experiences. We aim to facilitate memorable experiences and connections between event providers and individuals seeking unique experiences.
        </p>

        <p>
            1.2 Users include both "Hosts" who list activities, events, or services and "Guests" who participate in these experiences. The terms apply to both types of users.

        </p>
      
        <h2>2. Eligibility</h2>
        <p>
            2.1 You must be at least 18 years old to register, access, or use the Platform.
        </p>

        <p>
            2.2 By using the Platform, you represent and warrant that you have the right, authority, and capacity to agree to these Terms and to abide by all applicable laws and regulations.
        </p>
      
        <h2>3. Account Registration</h2>
        <p>
            3.1 To access certain features, you must register and create an account. You agree to provide accurate, current, and complete information during the registration process and update it as needed.

        </p>

        <p>
            3.2 You are responsible for maintaining the confidentiality of your account details and for any activity under your account.

        </p>

      
        <h2>4. Host Obligation(s)</h2>
        <p>
            4.1 Hosts must provide complete and accurate information about their listings, including descriptions, pricing, availability, and any specific terms or restrictions.

        </p>

        <p>
            4.2 Hosts are responsible for ensuring that their activities comply with applicable laws and regulations, including health, safety, and zoning regulations.
        </p>

        <p>
            4.3 Hosts agree to handle all payments and refunds in accordance with MaiXperience policies and are liable for any taxes related to the services they provide.

        </p>
      
        <h2>5. Guest Obligation(s)</h2>

        <p>
            5.1 Guests agree to book activities solely for personal, non-commercial use.

        </p>

        <p>
            5.2 Guests must adhere to any requirements specified by the Host, such as age, health, or fitness limitations, and comply with any additional rules set by the Host.
        </p>

        <p>
            5.3 Guests are responsible for arriving on time and notifying the Host of any changes to their booking as per the Host's cancellation policy.
        </p>

        <h2>6. Payments and Refunds</h2>

        <p>
            6.1 Payments on MaiXperience are processed by a third-party payment provider. By using the Platform, you agree to comply with the payment provider’s terms.
        </p>

        <p>
            6.2 Hosts set their own pricing, and all fees and charges will be communicated before booking. MaiXperience may charge a service fee for processing the transactions.
        </p>

        <p>
            6.3 Refunds, if applicable, are handled according to the Host’s specific refund and cancellation policy.

        </p>

        <h2>7. User Conduct</h2>
        <p>
            7.1 User agree to NOT: 
           
        </p>
        <ul>
            <li>
                Use the Platform for illegal activities
            </li>

            <li>
                Harass, abuse, or harm other users.
            </li>

            <li>
                Interfere with the Platform’s functionality or introduce viruses.

            </li>

            <li>
                Post or transmit any misleading, inappropriate, or offensive content.
            </li>

        </ul>

        <p>
            7.2 MaiXperience reserves the right to suspend or terminate accounts for violations of these Terms.

        </p>

        <h2>8. Disputes and Liability</h2>
        <p>
            8.1 MaiXperience acts as a facilitator and is not responsible for the quality, safety, legality, or accuracy of the activities offered by Hosts.

        </p>

        <p>
            8.2 Any disputes between Hosts and Guests should be resolved directly between the parties. MaiXperience may, at its discretion, offer mediation support, but it is not obligated to do so.

        </p>

        <p>
            MaiXperience is not liable for any damages or injuries arising from the use of the Platform or participation in any activity booked through the Platform.

        </p>

        <h2>9. Intellectual Property</h2>
        <p>
            9.1 All content on the Platform, including but not limited to text, images, logos, and graphics, is the property of MaiXperience or its licensors and is protected by copyright and trademark laws.

        </p>

        <p>
            Users may not use, reproduce, or distribute any content from the Platform without prior written permission from MaiXperience.

        </p>

        <h2>10. Privacy</h2>

        <p>
            10.1 Our [Privacy Policy](link to Privacy Policy) describes how we collect, use, and disclose personal information. By using the Platform, you consent to our data practices as outlined in the Privacy Policy.

        </p>

        <h2>11. Modifications to Terms</h2>
        <p>
            11.1 MaiXperience reserves the right to modify these Terms at any time. If changes are made, we will provide notice through the Platform. Continued use of the Platform after any modification constitutes acceptance of the new terms.
        </p>

        <h2>12. Termination</h2>

        <p>
            12.1 MaiXperience may suspend or terminate your access to the Platform for any reason, including breaches of these Terms or misuse of the Platform.

        </p>

        <p>
            12.2 Users may terminate their accounts at any time by contacting MaiXperience support.

        </p>

        <h2>13. Governing Law</h2>
        <p> 
            13.1 These Terms are governed by the laws of South Africa. Any disputes arising from or related to these Terms or the use of the Platform will be subject to the exclusive jurisdiction of the courts in South Africa.

        </p>

        <h2>14. Contact Information</h2>
        <p>
            If you have any questions about these Terms, please contact us at:
            
        </p>
        <span class="text-center">
            
            support&#64;maixperience.co.za
        </span>
    </section>
    
   
  </div>