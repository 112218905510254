import { createAction, props } from "@ngrx/store";
import { GenericUser } from "src/app/types/models/genericuser.model";

    
export const LOAD_PROMOTERS = createAction('[GenericUser] Load Promoters');

export const LOAD_PROMOTERS_SUCCESS = createAction('[GenericUser] Load Promoters Success',
    props<{activepromoterusers: GenericUser[]}>());

export const LOAD_PROMOTERS_FAIL = createAction('[GenericUser] Load Promoters Fail',
    props<{error: string}>());