import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-subscription-tier',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './add-subscription-tier.component.html',
  styleUrl: './add-subscription-tier.component.scss'
})
export class AddSubscriptionTierComponent {
  tierName: string = '';

  subTier: SubscriptionTier | any
  subTierForm: FormGroup | any;

  constructor(private toastr: ToastrService,
    private dialog: DialogService,
    private formBuilder: FormBuilder,
    private loadingService: LoaderService,
    public dialogRef: MatDialogRef<AddSubscriptionTierComponent>,
    private authService: AuthService)
  {

    // public MaxActivityPromoters: number,
    //   public MaxEventPromoters: number,
    //   public Interval: string,
    //   public PSTPlanId: number,
    //   public PSTPlanCode: string,

    this.subTierForm = this.formBuilder.group({
      Id: [],
      TierName: ['', [Validators.required]],
      MaxActivitiesPerMonth: [0, [Validators.required]],
      MaxEventsPerMonth: [0, [Validators.required]],
      MaxActivityPromoters: [0, [Validators.required]],
      MaxEventPromoters: [0, [Validators.required]],
      Price: [1.0, [Validators.required]],
      planInterval: ['', [Validators.required]],
      AdditionalFeatures: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  
  }

  onSubscriptionChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('Selected subscription type:', selectedValue);

    // Perform further actions based on the selected value
    if (selectedValue === 'monthly') {
      console.log('You selected Monthly.');
    } else if (selectedValue === 'annually') {
      console.log('You selected Annually.');
    }
  }

  submitSubTierAdd()
  {
    let timezone = 'Africa/Johannesburg';
    
    const currentMoment = moment(new Date()).tz(timezone).toString()


    //

    //subscription tier mxpdb
    this.subTier = new SubscriptionTier(
      0,
      this.subTierForm.value.TierName,
      this.subTierForm.value.MaxActivitiesPerMonth, 
      this.subTierForm.value.MaxEventsPerMonth,
      this.subTierForm.value.MaxActivityPromoters, 
      this.subTierForm.value.MaxEventPromoters,
      0,
      '',
      this.subTierForm.value.planInterval, //planInterval
      this.subTierForm.value.Price,
      currentMoment,
      this.subTierForm.value.AdditionalFeatures);
      

    console.log(`Subscription tier to be added: ${JSON.stringify(this.subTier)}`)

    if(!this.subTier)
    {
      this.toastr.error('Invalid tier name!', 'Error')
    }
    else
    {

      //addSubTier Observer
      const addSubTierObserver = {
        next: async (data: any) => {
          this.toastr.success(`Subscription Tier: ${this.subTier.TierName} added !`, 'Success')
          
          console.log(`Subscription Tier add response: ${JSON.stringify(data)}`);
          
          this.loadingService.hide();

          this.dialogRef.close("Subscription added")
        },
        error: (err: HttpErrorResponse) => 
        {
          this.toastr.error(`Error when adding plan to maiexp`, 'Error')
          console.log(`Error: ${JSON.stringify(err.error)}`),

          this.loadingService.hide();
        }
      }

      // monthly addSubTier Observer
      const addPlanToPaystackObserver = {
        next: async (response: any) => {
          this.toastr.success(`Plan created with paystack !`, 'Success')
          console.log(`Plan created with paystack: ${JSON.stringify(response)}`);
          
          //extract missing fields (plan_code & id)
          this.subTier.PSTPlanCode = response.data.plan_code

          this.subTier.PSTPlanId = response.data.id
          
          //add to maixp db
          this.authService.AddSingleSubTierToMxp(this.subTier)
          .subscribe(addSubTierObserver)

        },
        error: (err: HttpErrorResponse) => 
        {
          this.toastr.error(`Error when adding plan to paystack`, 'Error')
          console.log(`Error: ${JSON.stringify(err.error)}`),

          this.loadingService.hide();
        }
      }


      this.loadingService.show();

      //monthly 
      this.authService.createPlanWithPaystack(
        this.subTierForm.value.TierName,
        this.subTierForm.value.planInterval,
        this.subTierForm.value.Price
      ).subscribe(addPlanToPaystackObserver)
    }
  }
}
