export const environment = {
    production: false, 
    qa: false,
    dev: true,
    myUrl: 'https://dev.maixperience.co.za/',
    APIUrl: 'https://dev.api.maixperience.co.za/api/',
    mxpApiClientId: 'mxpux',
    fileUploadBaseUrl: 'https://filesvc.maixperience.co.za/api/UploadFile/',
    fileRetriever: 'https://filesvc.maixperience.co.za/resources/Images/',
    MailerBaseUrl: 'http://localhost:3000/',
    DNSNameCheckForFeature: 'WeServe',
    AppBoundIdentifier: 4,
    AppNameForCustomAuth: "MaiX",
    IPExtractorUrl: "https://api.ipify.org?format=json", //we are using ipify to get device IP address
    CustomAuthBaseUrl: 'https://dev.customauth.maixperience.co.za/api/',
    CookieName: 'CustomAuthCookie',
    PaystackPublickKey: 'pk_test_4eb08235215e40ede52b90f96f1b95767b6fcf16',
    PaystackSecretKey: 'sk_test_d6c6e2220d190525d8ed84fce6bcc2fa38e8d836',
    PaystackPercentageCharge: 2.9,
    PaystackBaseCharge: 1,
    PaystackVATPercentage: 0.54,
    SACurrency: 'ZAR',
    maxImageSizeMB: 5,
    numberOfUploadableImages: 5,
    ActivityServiceFee: 22,
    EventServiceFee: 22,
    TransferRecipientType_SA: 'basa',
    paystackBankUrl: 'https://api.paystack.co/bank',
    paystackPlanUrl: 'https://api.paystack.co/plan',
    paystackTXInitUrl: 'https://api.paystack.co/transaction/initialize',
    paystackSubscriptionUrl: 'https://api.paystack.co',
    paystackRecipientBaseUrl: 'https://api.paystack.co/transferrecipient',
    paystackTransferUrl: 'https://api.paystack.co/transfer',
    mxpGoogleApiKey: 'AIzaSyDgtJd9Kk7N7LG51M-PxQer7XpBamENe1w'
};