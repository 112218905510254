import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-code-activate',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule, 
    MatButtonModule
],
  templateUrl: './code-activate.component.html',
  styleUrl: './code-activate.component.scss'
})
export class CodeActivateComponent {
  code: string = '';

  enteredCode: string = ''; // Two-way data binding for user input

  constructor(
    private toast: ToastrService,
    private dialogRef: MatDialogRef<CodeActivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,)
  {
    if(this.data)
    {
      this.code = this.data

      if(!environment.production)
      {
        console.log(`Valid code: ${this.code}`)
      }
    }
  }

  ngOnInit(){
    
  }

  submitCode() {
    console.log('Code submitted:', this.enteredCode);

    if(this.code == this.enteredCode)
    {
      //return true
       // Close the dialog and pass the result (true or false)
      this.dialogRef.close(true);
    }
    else{
      //close modal with dalse
      this.toast.error("The code entered is incorrect", "Invalid activation code")
    }
  }

}
