import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, combineLatestWith, distinctUntilChanged, filter, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { LOAD_ACTIVITIES_FOR_ONE_USER } from 'src/app/ngrx/actions/current-user-activities.actions';
import { GET_CURRENT_USER_SUBSCRIPTION_TIER } from 'src/app/ngrx/actions/current-user-subscription-tier.actions';
import { GET_CURRENT_USER_SUBSCRIPTIONS } from 'src/app/ngrx/actions/current-user-subscription.actions';
import { selectUserActivities } from 'src/app/ngrx/selectors/current-user-activities.selectors';
import { selectUserSubscriptionTier } from 'src/app/ngrx/selectors/current-user-subscription-tier.selectors';
import { selectLoggedInUserSubscriptions, selectLoggedInUserSubscriptionsLoaded, selectLoggedInUserSubscriptionsLoading } from 'src/app/ngrx/selectors/current-user-subscription.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { Activity } from 'src/app/types/models/activity.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { FreeTierActivation } from 'src/app/types/models/SubscriptionRelated/FreeTierActivation.model';
import { FreeTierCodeEmail } from 'src/app/types/models/SubscriptionRelated/freeTierCodeEmail.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscriptions',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule ],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent {
  useId: any
  // @Input() usrObjectInp: any;
  private usrObjectSubject = new BehaviorSubject<any>(null);
  usrObject$: Observable<any> = this.usrObjectSubject.asObservable();

  

  private pgSubscription: Subscription | undefined = new Subscription();
  currentUserSubscriptions$:  Observable<UserSubscription[] | null> 
  currentUserSubscriptionsLoading$: Observable<any>
  currentUserSubscriptionsLoaded$: Observable<any>

  SubscriptionTier$:  Observable<SubscriptionTier | null> 
  // allSubscriptionTiers$: Observable<SubscriptionTier[]> | undefined 

  // Declare the BehaviorSubject to hold the tiers
  private allSubscriptionTiersSubject = new BehaviorSubject<SubscriptionTier[]>([]);

  // Expose it as an observable
  allSubscriptionTiers$ = this.allSubscriptionTiersSubject.asObservable();

  // Declare the combinedSubscriptionTiers$ observable
  combinedSubscriptionTiers$: Observable<{ userSubscription: UserSubscription; tier: SubscriptionTier | undefined }[]> = of([]);


  @Input() set usrObjectInp(value: any) {
    console.log('Received usrObjectInp:', value);
    this.usrObjectSubject.next(value); // Push the new value to the BehaviorSubject
  }

  // canActivateFreeTier: boolean = false;
  private canActivateFreeTierSubject = new BehaviorSubject<boolean>(false);
  canActivateFreeTier$ = this.canActivateFreeTierSubject.asObservable();

  freeTierActivationData: FreeTierActivation | undefined

  constructor(private toastrService: ToastrService,
    private authService: AuthService,
    private loadingService: LoaderService,
    private dialogSvc: DialogService,
    private store: Store<AppState>)
  {
    //user subscription
    this.currentUserSubscriptions$ = this.store.pipe(select(selectLoggedInUserSubscriptions));
    this.currentUserSubscriptionsLoading$ =  this.store.pipe(select(selectLoggedInUserSubscriptionsLoading))
    this.currentUserSubscriptionsLoaded$ =  this.store.pipe(select(selectLoggedInUserSubscriptionsLoaded))
    
    //subscription tier
    this.SubscriptionTier$ = this.store.pipe(select(selectUserSubscriptionTier));
  
  }


  ngOnInit() 
  {

    this.getFreeTierActivation()
    
    
    // ================================================

    const subTiersObserver = {
      next: async (data: any) => {
        console.log(`All subscription tiers retrieved. Response: ${JSON.stringify(data)}`);
        
        // Populate the BehaviorSubject with the data
        this.allSubscriptionTiersSubject.next(data); // Update the value

         // Dispatch actions for fetching user subscriptions, active subsction and its tier
        this.pgSubscription = this.usrObject$
        .pipe(
          tap((user: GenericUser) => {
            console.log(`User found ${JSON.stringify(user)}. Fetching subscriptions...`);
            
            //active sub
            this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTIONS({ userId: user?.Id! }));
          }),
          switchMap(() => this.currentUserSubscriptions$), // Chain to subscription logic
          distinctUntilChanged(), // Optional: Avoid unnecessary duplicate emissions
          tap((usrSubs: UserSubscription[] | null) => {
            if (!usrSubs) {
              console.log(`No subscriptions found.`);
            } else {
              console.log(`Current user subscriptions: ${JSON.stringify(usrSubs)}`);
              
              // WARNING: HIGER ORDER SUBSCRIPTION (usrSubs) to get activities for one user
              const activeSub = usrSubs.find((x: UserSubscription) => x.IsActive);
              
              this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTION_TIER({ tierId: activeSub?.TierId ?? 0 }));
              
            }
          }),
          switchMap(() => this.SubscriptionTier$), // Chain to subscription tier logic
          distinctUntilChanged(), // Optional: Avoid duplicate emissions for tier

          tap(subTier => {
            if (!subTier) {
              console.log(`No subscription tier found.`);
            } else {
              console.log(`User subscription tier: ${JSON.stringify(subTier)}`);
              //If we found subscription but no tier then there is a big problem
            }
          })
        )
        .subscribe()
      },
      error: (err: HttpErrorResponse) => 
      {
        console.log(`Error fetching All subscription tiers: ${JSON.stringify(err.error)}`);

      }
    }

    //get all subscription tiers
    this.authService.GetAllSubscriptionsForMaiXp()
    .subscribe(subTiersObserver)


    // ================================================


    this.combinedSubscriptionTiers$ = this.currentUserSubscriptions$
    .pipe(
      combineLatestWith(this.allSubscriptionTiers$), // Use combineLatestWith instead of combineLatest
      map(([usrSubs, allTiers]) => {
        if (!usrSubs || !allTiers) {
          return [];
        }
  
        return usrSubs.map((sub) => {
          // Find the matching tier for the current subscription
          const matchedTier = allTiers.find((tier) => tier.Id === sub.TierId);
          return { userSubscription: sub, tier: matchedTier }; // Combine subscription with matched tier
        });
      }),
      distinctUntilChanged() // Optional: To avoid unnecessary re-emissions if the combined result doesn't change
    );


    this.currentUserSubscriptions$.subscribe((dtMb: any) => {
      console.log(`Usr Subs: ${JSON.stringify(dtMb)}`)
    })


    this.SubscriptionTier$.subscribe((dtMb: any) => {
      console.log(`Sub tiers: ${JSON.stringify(dtMb)}`)
    })
    //  ================================================
    
    // // Validate subscriptions and match tiers
    // this.combinedData$ = combineLatest([
    //   this.currentUserSubscriptions$,
    //   this.SubscriptionTier$,
    // ]).pipe(
    //   map(([usrSubscriptions, tiers]) => {
    //     if (!subscriptions || usrSubscriptions.length === 0) {
    //       console.log(`No subscriptions available.`);
    //       return [];
    //     }

    //     // Match each subscription with its tier
    //     return subscriptions.map((sub) => {
    //       const matchedTier = tiers.find((tier) => tier.id === sub.TierId);
    //       return {
    //         subscription: sub,
    //         tier: matchedTier || null, // Handle cases where the tier isn't found
    //       };
    //     });
    //   })
    // );

    // Subscribe to combined data
    // this.combinedData$.subscribe({
    //   next: (result) => {
    //     console.log(`Combined subscription and tier data:`, result);
    //     // Handle the combined result here (e.g., update UI)
    //   },
    //   error: (err) => console.error('Error combining subscriptions and tiers:', err),
    // });
      


    //=============================================
        // //#USER
        // this.usrObjectInp
        // .pipe(
        
        //   tap((user: GenericUser) => {
          
        //     console.log(`User found ${JSON.stringify(user)}. Fetching subscriptions...`);
        //     this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTIONS({ userId: user.Id! }));
            
        //   }),
        //   switchMap(() => this.currentUserSubscriptions$), // Chain to subscription logic
        //   distinctUntilChanged(), // Optional: Avoid unnecessary duplicate emissions
        //   tap((usrSubs: UserSubscription[]) => {
        //     if (!usrSubs) {
        //       console.log(`No subscriptions found.`);
        //     } else {
        //       console.log(`Current user subscriptions: ${JSON.stringify(usrSubs)}`);
              
        //       //for each subscription that the user has,
              
        //       //get the tier details and combine the result
        //       //
        //       this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTION_TIER({ tierId: usrSubs.TierId! }));
            
        //       // get all actvities the user created
        //       this.store.dispatch(LOAD_ACTIVITIES_FOR_ONE_USER({ userId: usrSubs.UserId }));
              
        //     }
        //   }),
        //   switchMap(() => this.SubscriptionTier$), // Chain to subscription tier logic
        //   distinctUntilChanged(), // Optional: Avoid duplicate emissions for tier

        //   tap(subTier => {
        //     if (!subTier) {
        //       console.log(`No subscription tier found.`);
        //     } else {
        //       console.log(`User subscription tier: ${JSON.stringify(subTier)}`);
        //       //If we found subscription but no tier then there is a big problem
        //     }
        //   })
        // )
        // .subscribe()
      
       
  }


  getFreeTierActivation(){
    const freeTierCheckerObserver = {
      next: async (data: any) => {
        if(!environment.production)
        {
          console.log(`Free tier for user: ${JSON.stringify(data)}`);
        }

        this.freeTierActivationData = data;
        
        if(data?.IsActivated)
        {
          // this.canActivateFreeTier = false;
          this.canActivateFreeTierSubject.next(false);
        }
        else{
          this.canActivateFreeTierSubject.next(true);
          // this.canActivateFreeTier = true;
        }
      },
      error: (err: HttpErrorResponse) => 
      {
        if(!environment.production)
        {
          console.log(`Error fetching Free tier: ${JSON.stringify(err.error)}`);
        }
      }
    }

    this.usrObject$.subscribe((user) => {

      this.authService.GetFreeTierActivationByUserId(user?.Id)
      .subscribe(freeTierCheckerObserver)
    })
  }

  // generateSixDigitCode(): string {
  //   return Math.floor(100000 + Math.random() * 900000).toString();
  // }

  activateFreeTier()
  {
    //generate 6 digits code
    const code = Math.floor(100000 + Math.random() * 900000).toString()
    
    console.log(`Free tier code activation: ${code}`)
    
    this.usrObject$
      .pipe(
        tap((user: GenericUser) => {
          console.log(`Logged in user ${JSON.stringify(user)}. Fetching subscriptions...`);
          
          //if we dont have any already created
          if(!this.freeTierActivationData)
          {
            this.freeTierActivationData = new FreeTierActivation(
              0,
              user.Id!,
              code,
              false
            )
          }
          else{
            this.freeTierActivationData.GeneratedCode = code;
            this.freeTierActivationData.IsActivated = false;

          }

          //subscribe user to basic tier Observer
          const addUsrSubToMxpObserver = {
            next: async (data: any) => {
              console.log(`Free tier activated. Response: ${JSON.stringify(data)}`);
              this.toastrService.success(`Free tier activated successfully !`, 'Success')
              
              //re evaluate
              this.getFreeTierActivation()

              //get latest user subs
              this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTIONS({ userId: user?.Id! }));

              this.loadingService.hide();
            },
            error: (err: HttpErrorResponse) => 
            {
              // this.toastr.error(`Error processing user subscription`, 'Error')
              console.log(`Error on free tier activation: ${JSON.stringify(err.error)}`),

              this.toastrService.error(`Error occurred. Please try again later!`, `Error`);

              this.loadingService.hide();
            }
          }



          //activate free tier observer
          const activateFreeTierObserver = {
            next: async (response: any) => {

              if(!environment.production)
              {
                console.log(`Activate result: ${JSON.stringify(response)}`)
              }

              //should hold all tiers []
              // Fetch the updated value
              const allSubTiers = this.allSubscriptionTiersSubject.getValue();
              console.log('All subscription tiers xxx:', allSubTiers);          

              const subTierOfInterest = allSubTiers.find(x => x.TierName
                .toLocaleLowerCase()
                .includes('basic'))
              // let tierOfInterest = this.freeTierActivationData!.


              // subscribe user to basic
              let timezone = 'Africa/Johannesburg';
            
              const currentMoment = moment(new Date())
              .tz(timezone)
              .toString()

              //deduced end moment
              const oneMonthFromNow = moment(new Date())
              .tz(timezone)
              .add(1, 'month')
              .toString();

              const bCycle = 'monthly'
              const amtPerSelection = subTierOfInterest!.Price
              const deducedSubEnd = oneMonthFromNow
              const initialSub = 1
              
              const usrSub = new UserSubscription(
                0,
                user.Id!,
                subTierOfInterest!.Id,
                bCycle,
                initialSub,
                currentMoment,
                deducedSubEnd,
                true
              )
              
              this.authService.AddUserSubscriptionToMxp(usrSub)
              .subscribe(addUsrSubToMxpObserver);
              
            },
            error: async (err: HttpErrorResponse) => {
              this.loadingService.hide();

              if(!environment.production)
              {
                console.log(`Error while activating free tier : ${JSON.stringify(err)}`);
              }

              this.toastrService.error("Error", `Error occurred. Please try again later!`);
            }
          }


          //send email activation code observer
          const emailActivationCodeObserver = {
            next: async (response: any) => {

              if(!environment.production)
              {
                console.log(`Activation email send response: ${JSON.stringify(response)}`)
              }

              this.loadingService.hide();  

              //open confirmation modal
              // Show the dialog
              const dialogRef = this.dialogSvc.ShowActivateCode(code);


              // Show Toastr message immediately
              this.toastrService
                .success(
                  "Enter the activation code sent to your email address", 
                  `Activation code sent to email.`);

              dialogRef.afterClosed()
              .subscribe((result) => {
                console.log('Dialog closed with result:', result);
                
                if(result == true)
                {
                  //show loading again 
                  this.loadingService.show()

                  //activate the free tier
                  this.freeTierActivationData!.IsActivated = true;
                  
                  //update the record
                  this.authService.UpdateFreeTierActivation(this.freeTierActivationData!)
                  .subscribe(activateFreeTierObserver)
                }
              })
              
              
            },
            error: async (err: HttpErrorResponse) => {
              this.loadingService.hide();

              if(!environment.production)
              {
                console.log(`Error on free trial activation email send : ${JSON.stringify(err)}`);
              }

              this.toastrService.error("Error", `Error occurred. Please try again later!`);
            }
          }


          // if(!this.freeTierActivationData)
          // {

          //   let freeTierModel = new FreeTierActivation(
          //     0,
          //     user.Id!,
          //     code,
          //     false
          //   )
          // }

          const freeTierActivationObserver = {
            next: async (response: any) => {
              if(!environment.production)
              {
                console.log(`Activation code added. Response: ${JSON.stringify(response)}`)
              }
              
              //try to get the ID if its a newly added Id
              if(response.split('|')[1])
              {
                this.freeTierActivationData!.Id = response.split('|')[1] ?? 0
              }

              let ftEmailModel = new FreeTierCodeEmail(
                user.Email,
                user.UserName,
                code,
                environment.myUrl,
                new Date().getFullYear().toString()
              )

              //send email with the code
              this.authService.SendActivationCodeToUser(ftEmailModel)
                .subscribe(emailActivationCodeObserver)

            },
            error: async (err: HttpErrorResponse) => {
              this.loadingService.hide();
      
              if(!environment.production)
              {
                console.log(`Error on  free trial activation processing: ${JSON.stringify(err)}`);
              }
      
              this.toastrService.error("Error", `Error occurred. Please try again later!`);
            }
          }

          this.loadingService.show();

          //create a FreeTierActivation record 
          this.authService.AddNewFreeTier(this.freeTierActivationData)
          .subscribe(freeTierActivationObserver)
          
        })
    ).subscribe()

    

   

  }

}
