<section class="wrapper">
    <div class="container">

        <!-- role based functionality for MaiX admin -->
        <div 
        class="row" 
        style="margin-bottom: 20px;">
           
            <div 
            class="col-12 actBtnsDv" 
            style="text-align: center;">
                <button 
                    (click)="RouteToAddNewActivity()"
                    style="border-radius: 20px;"
                    type="button" 
                    class="btn btn-outline-primary position-relative"
                    *ngIf="canAddActivity$ | async;">
                    <!-- how many activities can user add (based on subscription) -->
                    
                    <span 
                    *ngIf="(combinedSubCheck$ | async) as tierAndActivities"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {{ tierAndActivities.subTier?.MaxActivitiesPerMonth - tierAndActivities.usrActivities?.length }}
                        <!-- {{remainingActivities}} -->
                        <span class="visually-hidden">unread messages</span>
                    </span>
                   

                    <!-- warning if you are out of adding -->
                    <!-- <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(220, 220, 32);" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                            <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z"/>
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                    </span> -->
                    Add Activity
                </button>

                <!-- <ng-template #noPermission>
                    <p>You do not have permission to add an activity.</p>
                  </ng-template> -->
                
            </div>
        </div>

        <!-- filters -->
        <div>

        </div>

        <!-- when the active activities data is loading -->
        <div 
            *ngIf="activeActivitiesLoading$ | async;" 
            class="spinner-container">
            
            <div class="spinner"></div>
        </div>

        <!-- actual active activities data loaded -->
        <div *ngIf="activeActivitiesLoaded$ | async">

            <!-- when data has loaded -->
            <div 
            *ngIf="activeActivitiesPaginated$ | async  as activeActivities"
            class="row">
                
                <!-- <pagination-controls 
                [id]="paginatedEvents[0]?.Status ?? 0"
                [totalItems]="totalEvents"
                (pageChange)="p = $event"></pagination-controls>
                 -->
                <div 
                    *ngIf="activeActivities.length > 0"
                    class="custom-pagination-container flex-center">
                
                    <pagination-controls 
                        (pageChange)="p = $event"></pagination-controls>
                 </div>
                 
                <ng-container 
                    *ngFor="let activity of activeActivities | paginate: { itemsPerPage: itemsPerPage, currentPage: p }">
                    
                    <div 
                    (click)="ViewActivity(activity)"
                    *ngIf="activity.Status == activeSt" 
                    class="col-sm-12 col-md-6 col-lg-4 mb-4">
                        
                        <div 
                        class="card text-white card-has-bg click-col" 
                        style="background-image:url('{{rtImgPath}}{{activity?.DefaultImage}}')">
                        
                            <div class="card-img-overlay d-flex flex-column">
                                <div class="card-body">
                                    <small 
                                    style="align-items: center;"
                                    class="card-meta mb-2">
                                        {{ activity?.Name?.length! <= 12 ? activity?.Name : (activity?.Name | slice:0:12) + '...' }}

                                        <span
                                        class="EntityMessageSpan" 
                                        style="color: rgb(62, 158, 199);">
                                        
                                        <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" 
                                        height="16" 
                                        fill="currentColor" 
                                        class="bi bi-tag-fill" 
                                        viewBox="0 0 16 16">
                                            <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        </svg>  
                                        Activity
                                        </span>
                                    </small>

                                    <h4 class="card-title mt-0 ">
                                        <a class="text-white" herf="#">
                                            <!-- {{activity.Description}} -->

                                            {{ (activity?.Description?.length! > 21) ? 
                                                (activity?.Description | slice:0:21)+'...' : 
                                                (activity?.Description) }}
                                        </a>
                                    </h4>

                                </div>

                                <div class="card-footer">

                                    <div class="media">
                                        <!-- Admin image container -->

                                        <ng-container
                                            *ngIf="combinedData$ | async as combinedData">
                                            
                                            <div
                                                *ngFor="let item of combinedData">

                                                <span 
                                                
                                                    *ngIf="item.activeActivities?.Id == activity?.Id"
                                                    (click)="ViewImage($event, item.creator!)">
                                                
                                                    <img  
                                                        class="mr-3 rounded-circle"
                                                        [src]="item.creator?.ProfilePicture ? rtImgPath + item.creator?.ProfilePicture : rtImgPath + 'nImg.png'"
                                                        alt="Generic placeholder image" 
                                                        style="width: 50px; height: 52px; margin-top: 20px;">
                                                    
                                                    <span class="media-body">
                                                        <h6 class="my-0 text-white d-block">
                                                            <span 
                                                            class="text-center">
                                                                {{item.creator?.UserName ?? 'Admin'}}
                                                            </span>
                                                            
                                                        </h6>
                                                        
                                                    </span>
                                                </span>
                                            </div>
                                        </ng-container>

                                       
                                        <ng-container *ngIf="combinedData$ | async as combinedData">
                                            <span
                                            *ngIf="(useId$ | async) as useId">
                                                <div
                                                    *ngFor="let item of combinedData">
                                                        
                                                        <!-- role based functionality for MaiX admin -->
                                                        <!-- {{item | json}} -->
                                                        <span 
                                                            (click)="EditActivityByAdmin($event, activity)"
                                                            class="EditSpan" 
                                                            style="float: right; color: rgb(0, 179, 255);"
                                                            *ngIf="((item.activeActivities?.Id == activity?.Id) && (activity?.UserId == useId))">

                                                            <!-- *ngIf="((item.activeActivities?.Id == activity?.Id) && (item.activeActivities?.UserId == activity?.UserId))">
                                                            AA_UID: {{item.activeActivities?.UserId}}
                                                            AA_ID: {{item.activeActivities?.Id}}
                                                            A_ID: {{activity?.Id}}
                                                            A_ID: {{activity?.UserId}} -->
                                                                
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                                            </svg>
                                                        </span>
                                                    
                                                </div>
                                            </span>
                                        
                                        </ng-container>

                                        <span 
                                            (click)="ViewLocationOfActivity($event, activity?.AddressId!)"
                                            class="locationSpan" 
                                            style="float: right; color: red;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                                </svg>
                                        </span>

                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div 
                *ngIf="activeActivities.length > 0"
                class="custom-pagination-container flex-center">
            
                    <pagination-controls 
                        (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>

        </div>
    </div>
  </section>
  