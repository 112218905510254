import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { selectActivitiesIAmPromotingWithPagination, selectActivitiesIAmPromotingWithPaginationError, selectActivitiesIAmPromotingWithPaginationLoaded, selectActivitiesIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { CardComponent } from 'src/app/Templates/card/card.component';
import { Activity } from 'src/app/types/models/activity.model';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';

@Component({
  selector: 'app-activity-promotion',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent
  ],
  templateUrl: './activity-promotion.component.html',
  styleUrl: './activity-promotion.component.scss'
})
export class ActivityPromotionComponent {
  @Input() usrObjectInp: any;

  activitiesOpenForPromotion: Activity[] | undefined
  
  // showButtonHistoryAndEdit = false; // user does not get to view those actions here
  showActivityPromoButton = true
  // showEventPromoButton = false
  
   showBookingButtonFromActivityPromotion: boolean = true;
  activityPromoterUserId: string = '12345'
  
  activitiesPromoting$: Observable<ActivityPromotersLog[]> | undefined
  activitiesPromotingLoading$: Observable<boolean> | undefined
  activitiesPromotingLoaded$: Observable<boolean> | undefined
  activitiesPromotingError$: Observable<string | null> 
  
  pageNumber: number = 1;
  pageSize: number = 10;
  
  constructor(
    private router: Router,
    private dialogSvc: DialogService,
    private loaderService: LoaderService,
    private authSvc: AuthService,
    private store: Store<AppState>,
    private userSvc: UserSpecificService,
    private activitySvc: ActivityService,
    private toastr: ToastrService) 
  {

     // get the activities I am promoting from store
     this.activitiesPromoting$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPagination))
     this.activitiesPromotingLoading$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoading))
     this.activitiesPromotingLoaded$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoaded))
     this.activitiesPromotingError$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationError))
 
 
  }

  ngOnInit()
  {
    if(this.usrObjectInp)
    {
      //dispatch
      this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
        promoterUserId: this.usrObjectInp?.Id!,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }));
    }

    // get all activities that are open for promotion
    // this can not include the ones created by loggedIn user
    this.activitySvc.GetAllActivitiesThatAreOpenForPromotionWithPaginationWithFilter(
      1,
      10,
      'ZA, South Africa',
      this.usrObjectInp.Id
    ).subscribe((response: any) => {
      this.activitiesOpenForPromotion = response
    })
  }

  // backToDashboard()
  // {
  //   this.router.navigate(['activityhost']);
  // }
  
}
