import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserSubscriptionState } from "../state/current-user-subscription.state";

export const selectCurrentUserSubscriptionState = createFeatureSelector<UserSubscriptionState>('currentloggedinusersubscription');

export const selectLoggedInUserSubscriptions = createSelector(
    selectCurrentUserSubscriptionState,
  (state: UserSubscriptionState) => state.currentloggedinusersubscription
);

export const selectLoggedInUserSubscriptionsLoading = createSelector(
    selectCurrentUserSubscriptionState,
  (state: UserSubscriptionState) => state.loading
);

export const selectLoggedInUserSubscriptionsLoaded = createSelector(
    selectCurrentUserSubscriptionState,
    (state: UserSubscriptionState) => state.loaded
  );

export const selectLoggedInUserSubscriptionsError = createSelector(
    selectCurrentUserSubscriptionState,
  (state: UserSubscriptionState) => state.error
);