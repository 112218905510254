import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, first, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectActivitiesIAmPromotingWithPagination, selectActivitiesIAmPromotingWithPaginationError, selectActivitiesIAmPromotingWithPaginationLoaded, selectActivitiesIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivityPromotionsComponent } from 'src/app/Templates/activity-promotions/activity-promotions.component';
import { CardComponent } from 'src/app/Templates/card/card.component';
import { EventPromotionsComponent } from 'src/app/Templates/event-promotions/event-promotions.component';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promoting-activities-public',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ActivityPromotionsComponent,
    EventPromotionsComponent,
    MatTabsModule
  ],
  templateUrl: './promoting-activities-public.component.html',
  styleUrl: './promoting-activities-public.component.scss'
})
export class PromotingActivitiesPublicComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup | undefined;

  // usrRoles$: Observable<string[] | null>

  constructor(private store: Store<AppState>){
    // this.usrRoles$! = this.store.pipe(select(selectLoggedInUserRoles));
  }
 
  ngOnInit()
  {
    // this.usrRoles$.pipe(
    //   tap(userRoles => {
    //     if(!environment.production)
    //     {
    //       console.log(`Current logged in user roles: ${JSON.stringify(userRoles)}`)
    //     }
    //   })
    // ).subscribe()
  }

}
