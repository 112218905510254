import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivitySlotService } from 'src/app/shared/services/activity-slot.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { UIError } from 'src/app/types/models/uiError.model';
import { environment } from 'src/environments/environment';
import { EdtiActivityDialogComponent } from '../../activity/edti-activity-dialog/edti-activity-dialog.component';
import { AddressService } from 'src/app/shared/services/address.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { AppState } from 'src/app/ngrx/state/app.state';
import { Store } from '@ngrx/store';
import { LOAD_FUTURE_EVENTS } from 'src/app/ngrx/actions/maixevents-future.actions';
import { LoaderService } from 'src/app/shared/utils/loader.service';

@Component({
  selector: 'app-edit-event-dialog',
  templateUrl: './edit-event-dialog.component.html',
  styleUrls: ['./edit-event-dialog.component.scss']
})
export class EditEventDialogComponent {
    defaultImage: string = ''
  //===============================================
    //UI Error handlers
    ActivityNameErroHandler: UIError = new UIError();
  
    //is production environment
    isProdEnv: boolean = false;
  
    //
    selFiles: string[] = [];
  
    //Panels
    eventDetailsOpenState = true; //panel 1
    eventImagesOpenState = false; //panel 2
    eventLocationOpenState = false; //panel 3
    eventPreferencesOpenState = false; //panel 3
  
    //state tracking variables
    prevStateOfSelectedEventModel : any;
    selectedEventModel: any;
  
    // state tracking variables ends
  
    eventStatuses = Object.values(EventStatus).filter((key:any) => 
    !isNaN(Number(EventStatus[key]))) as string[];
  
    selectedStatus: EventStatus | any;
  
    eventForm: FormGroup | any;
  
    updateHappened: boolean = false;
  
    //Location related
    selectedActvtyAddr: any
    
    eventAddr: AddressModel = {
      Id: 0,
      Street_number: '',
      Street: '',
      Suburb: '',
      City: '',
      State: '',
      IsDefault: false,
      Building_name: '',
      Unit_number: '',
      Address_instruction: '',
      Country: '',
      Postal_code: '',
      Longitude: '',
      Latitude: '',
      CreatedAt: null,
      ModifiedAt: null
    }
  
    formattedAddrString: string = ''
  
    rtImgPath: string = environment.fileRetriever;
    imgSelectMaxSize = environment.maxImageSizeMB;
  
    constructor(
      private fileUploadSvc: FileUploadService,
      @Inject(MAT_DIALOG_DATA) public data:any,
      private router: Router,
      private eventService: MaiXEventService,
      private formBuilder: FormBuilder,
      private activitySlotService: ActivitySlotService,
      private toastr: ToastrService,
      private loadingService: LoaderService,
      private store: Store<AppState>,
      public dialogRef: MatDialogRef<EdtiActivityDialogComponent>,
      private lociService: AddressService,
      private dialogSvc: DialogService) 
    { 
      //deepClone object to be able to keep track of changes/delta
      this.prevStateOfSelectedEventModel = this.deepClone(data);
      this.selectedEventModel = this.deepClone(data);
      
      if(!environment.production)
      {
        //log incoming model for visibility
        console.log(`Edit selected event dialog: ${JSON.stringify(this.selectedEventModel)}`);
      }

      //split and assign images of the selected event into comma separated values
      this.selFiles = this.selectedEventModel?.Images.split(',')
      this.defaultImage = this.selectedEventModel?.DefaultImage;

      if(!environment.production)
      {
        //log incoming model for visibility
        console.log(`Selected Files: ${JSON.stringify(this.selFiles)}`);
      }


      //for the panel notifier
      if(environment.production)
      {
        this.isProdEnv = true;
      }

      this.eventForm = this.formBuilder.group({
        Id: [this.selectedEventModel.Id],
        
        Name: [
          this.selectedEventModel.Name, 
          Validators.required],
        
        Description: [
          this.selectedEventModel.Description, 
          Validators.required],
        
        PricePerPerson: [
          this.selectedEventModel.PricePerPerson, 
          [Validators.required, Validators.min(10)]],
        
        // SHOULD HAVE:
        // MaxNumberOfInviteesForEvent
        // Event start datetime
        // Event end datetime
        Status: [
          this.getKeyByValue(EventStatus, this.selectedEventModel.Status), 
          Validators.required]
      });
      
      //make sure it is prepped
      if(!environment.production)
      {
        console.log(this.eventForm.value)
      }
    }
  
    deepClone(obj: any): any {
      // Custom deep cloning logic
      return JSON.parse(JSON.stringify(obj));
    }
  
    getKeyByValue(enumObject: any, enumValue: number): string | undefined {
      const keys = Object.keys(enumObject).filter((key) => enumObject[key] === enumValue);
      return keys.length > 0 ? keys[0] : undefined;
    }


    getValueByKey(enumObject: any, key: string): number | undefined {
      if (key in enumObject) {
        return enumObject[key];
      }
      return undefined;
    }

  
    ngOnInit()
    {
      //get the selected activity address  
      this.getAddressByIdAnUpdatePageIfNecessary(this.selectedEventModel.AddressId ?? 0)
    }


    onStatusChange(event: any): void {
      const selectedStatus: string = event.value;
      // Handle the selected status, perform actions based on the selected value
      console.log('Selected Status:', selectedStatus);
    }

    BindChangeToActivityModel(incStatusUpdate: any)
    {
      //bind the selection 
      this.selectedEventModel.Status = this.getValueByKey(EventStatus, incStatusUpdate);

      if(!environment.production)
      {
        console.log('Selected Status:', incStatusUpdate)
        // console.log('Selected Status:', incUpdate);
        // console.log('Decrypted:', this.selectedEventModel.Status);
      }
      
      // this.selectedEventModel.Status = EventStatus[incUpdate as keyof typeof EventStatus];
      // update status 
      // this.selectedEventModel.Status = this.activityForm.Status
    }
  
    onAppendFilesUploaded(fileNames: string[])
    {
      console.log('Appended files:', fileNames);
  
      this.selFiles = [...this.selFiles, ...fileNames];

      //update the model tracker
      this.selectedEventModel.Images = this.selFiles.join(',');
  
      if(!environment.production)
      {
        console.log(this.selFiles); // Updated array after appending
      }
    }
  
    onFilesUploaded(fileNames: string[]): void {

      this.selFiles = fileNames;

      //update the model tracker
      this.selectedEventModel.Images = this.selFiles.join(',');

      if(!environment.production)
      {
        console.log('Uploaded files from edit activity:', fileNames);
      }
    }
  
  
    removeSelectedImage(element: any){
      this.selFiles = this.selFiles.filter(item => item !== element);

      this.selectedEventModel.Images = this.selFiles.join(',');

      if(!environment.production)
      {
        console.log(this.selFiles); // Updated array after removal
      }
    }
  
    onSearchChange(searchValue: any): void {  
      if(!environment.production)
      {
        console.log(searchValue.target.value);
      }
      //if the checks pass
      this.ActivityNameErroHandler.status = "valid-feedback";
      this.ActivityNameErroHandler.message = "Valid Name"
    }
  
    simpleAlert(msg: string)
    {  
      Swal.fire(msg);  
    }  
  

    validatePreferences(): boolean
    {
      // console.log(`Promotion status: ${this.activityModel.EnablePromotion} And ${this.activityModel.ActivityPromotionPercentage}.`)
      // console.log(`Promotion is a number: ${!isNaN(this.activityModel.ActivityPromotionPercentage)}.`)
      // return false;
  
      if(this.selectedEventModel.EnablePromotion)
      {
        if(this.selectedEventModel.EventPromotionPercentage > 4 &&
          !isNaN(this.selectedEventModel.EventPromotionPercentage)) 
        {
          return true
        }
        else {
          return false
        }
      } 
      else {
        return true;
      }
    }

    UpdateEventDetails()
    {
      if(this.validatePreferences())
      {
        if(!environment.production)
        {
          console.log(`Event details to update: ${JSON.stringify(this.selectedEventModel)}`);
        }

        //if the previous status was active(0) [AND] then was changed to Inactive(1) or Disabled(2)
        if(this.prevStateOfSelectedEventModel.Status == 0)
        {
          if(confirm("I accept that setting the event status to past state will make it impossible to book the event."))
          {
            this.loadingService.show()
            this.innerWorkingsOfUpdateEvent()
          }
        }
        else{
          this.innerWorkingsOfUpdateEvent();
        }
      }
      else{
        this.toastr.error("Enter a valid promotion percentage and try again")
      }
    }

    futureEventsStatus: number = 2
    pageNumber: number = 1;
    pageSize: number = 100;

    innerWorkingsOfUpdateEvent(){

      if(!environment.production)
      {
        console.log(`Default Image ??: ${this.selectedEventModel?.DefaultImage}`);
        console.log(`Event to be updated: ${JSON.stringify(this.selectedEventModel)}`);
      }

      this.updateHappened = true;

      //update activity observer
      const updateEventObserver = {
        next: async (resp: any) =>  {
          if(!environment.production)
          {
            console.log(`Event update result: ${JSON.stringify(resp)}`);
          }

          //reset change trackings by getting the updated model
          this.prevStateOfSelectedEventModel = this.deepClone(this.selectedEventModel);

          // UpdateActivityDetails
  
          if(resp.status == 200) //all went well
          {
  
          }

          this.store.dispatch(LOAD_FUTURE_EVENTS({statusCode: this.futureEventsStatus, pageNumber: this.pageNumber, pageSize: this.pageSize}))

          this.toastr.success("Event Updated Successfully !", "Update success")
          
        },
        error: (err: HttpErrorResponse) => {
          console.log(`Error: ${JSON.stringify(err)}`)
        },
        complete: () =>{
          this.loadingService.hide();

          if(!environment.production)
          {
            console.log("Update event COMPLETE");
          }

          this.simpleAlert("Update successful !");
          //alert user that the add was successful (check out SWAL)
          // this.router.navigate(['activitylist'])
        }

      }

      this.eventService.UpdateEvent(this.selectedEventModel)
      .subscribe(updateEventObserver);
    }


    toggleAppendPromotion(){
      console.log(`promotion toggle clicked`)
      this.selectedEventModel.EnablePromotion = !this.selectedEventModel.EnablePromotion
    }

    //get the nth occurrence in a string
    getPosition(str: string, subStr: string, occurrence: number) {
      return str?.toString().split(subStr, occurrence).join(subStr).length;
    }

    ValidateActivityDetailsAndProceedToImagesPanel()
    {
      if(!environment.production)
      {
        console.log(`Activity form values: ${JSON.stringify(this.eventForm.value)}`);
      }
      
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = false; //panel 1
        this.eventImagesOpenState = true; //panel 2
        this.eventLocationOpenState = false; //panel 3
        this.eventPreferencesOpenState = false; //panel 4
      }
    }

    ValidatImagesPanelAndRevertBackToActivityDetailsPanel()
    {
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = true; //panel 1
        this.eventImagesOpenState = false; //panel 2
        this.eventLocationOpenState = false; //panel 3
        this.eventPreferencesOpenState = false; //panel 4
        
      }
    }
  
    ValidatImagesPanelAndProceedToLocationPanel()
    {
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = false; //panel 1
        this.eventImagesOpenState = false; //panel 2
        this.eventLocationOpenState = true; //panel 3
        this.eventPreferencesOpenState = false; //panel 4
      }
    }
  
    ValidatLocationPanelAndRevertBackToImagesPanel()
    {
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = false; //panel 1
        this.eventImagesOpenState = true; //panel 2
        this.eventLocationOpenState = false; //panel 3
        this.eventPreferencesOpenState = false; //panel 4
      }
    }

    ValidatLocationAndProceedToPreferencePanel()
    {
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = false; //panel 1
        this.eventImagesOpenState = false; //panel 2
        this.eventLocationOpenState = false; //panel 3
        this.eventPreferencesOpenState = true; //panel 4
      }
    }

    ValidatPreferencePanelAndRevertBackToLocationPanel()
    {
      if (this.eventForm.valid)
      {
        //open the next panel
        this.eventDetailsOpenState = false; //panel 1
        this.eventImagesOpenState = true; //panel 2
        this.eventLocationOpenState = false; //panel 3
        this.eventPreferencesOpenState = false; //panel 4
      }
    }

    dummyClick()
    {
      alert("button cliecked")
    }

    confirmAndClose()
    {
    
      if
      (
        (this.prevStateOfSelectedEventModel.Images !== this.selectedEventModel.Images)
          &&
        (!this.updateHappened)
      )
      {
        if(confirm("Seems there is an update outstanding. Are you sure you want to close the modal ?"))
        {
          console.log("about to close");
          this.dialogRef.close("noaction");
        }
      }
      else if
      (
        (this.prevStateOfSelectedEventModel.Images !== this.selectedEventModel.Images)
        ||
        (this.updateHappened) //update to the image or any form of update
      )
      {
        this.dialogRef.close("refresh");
      }
      else{
        this.dialogRef.close("noactionDefault");
      }
    }

    formatteraddressAndSetString()
    {
      //
      this.eventAddr.Street_number = this.selectedActvtyAddr.Street_number ?? "";
      this.eventAddr.Street = this.selectedActvtyAddr.Street ?? "";
      this.eventAddr.Suburb = this.selectedActvtyAddr.Suburb ?? "";
      this.eventAddr.City = this.selectedActvtyAddr.City ?? "";
      this.eventAddr.State = this.selectedActvtyAddr.State ?? "";
      this.eventAddr.Building_name = this.selectedActvtyAddr.Building_name ?? "";
      this.eventAddr.Unit_number =  this.selectedActvtyAddr.Unit_number ?? "";
      this.eventAddr.Address_instruction = this.selectedActvtyAddr.Address_instruction ?? "";
      this.eventAddr.Country = this.selectedActvtyAddr.Country ?? "";
      this.eventAddr.Postal_code = this.selectedActvtyAddr.Postal_code ?? "";
      this.eventAddr.Longitude = this.selectedActvtyAddr.Longitude ?? "";
      this.eventAddr.Latitude = this.selectedActvtyAddr.Latitude ?? "";
      this.eventAddr.CreatedAt = this.selectedActvtyAddr.CreatedAt ?? null;
      this.eventAddr.ModifiedAt = this.selectedActvtyAddr.ModifiedAt ?? null;

      console.log(`Address formatted: ${JSON.stringify(this.eventAddr)}`);
      
      //set string
      if(this.selectedActvtyAddr != undefined)
      {
        this.formattedAddrString = this.selectedActvtyAddr?.Street_number + ' ' +
        this.selectedActvtyAddr?.Street  + ' ' +
        this.selectedActvtyAddr?.Suburb  + ' ' +
        this.selectedActvtyAddr?.City  + ' ' +
        this.selectedActvtyAddr?.State  + ' ' +
        this.selectedActvtyAddr?.Country  + ' ' +
        this.selectedActvtyAddr?.Postal_code;
      }
    }


    getAddressByIdAnUpdatePageIfNecessary(adrId: number)
    {
      //get the location of the activity (It must have a location if it is visible)
      this.lociService.GetAddressByAddressId(adrId)
      .subscribe((data: any) => {
        this.selectedActvtyAddr = data;

        this.formatteraddressAndSetString();

        if(!environment.production)
        {
          console.log(`Activity address retrieved: ${JSON.stringify(this.selectedActvtyAddr)}`);
        }
      })
    }
  
    editAddressInfo()
    {
      this.dialogRef.close('');

      //open dialog to update address and harvest the result
      this.dialogSvc.SetAddressDialog(this.selectedEventModel)
      .afterClosed()
      .subscribe(addressId => {
        if (addressId) {
          if(!environment.production)
          {
            console.log('Dialog result:', addressId);
          }

          this.selectedEventModel.AddressId = addressId;
          
          this.dialogSvc.EditSelectedEventDialog(this.selectedEventModel)
          
          this.innerWorkingsOfUpdateEvent();

          this.getAddressByIdAnUpdatePageIfNecessary(addressId);
          
        } 
        else {
          // Handle the case where the dialog is closed without any result
          console.log('Dialog closed without result');
        }
      });
    }
}
