<div class="container">
    
    <mat-dialog-content>
        
        <!-- close button to the right -->
        <mat-dialog-actions >
            <span style="align-items: right; z-index: 1; ">
                <button 
                style="position: fixed;"
                mat-raised-button 
                color="warn" 
                (click)="confirmAndClose()">
                    X
                </button>
            </span>
        </mat-dialog-actions>

        <br>
        <hr>

        <div style="margin-top: 25px;">
            <br>
            <!-- <div>
                {{this.selectedEventModel | json}}
            </div> -->

            <mat-accordion>
                <!-- Add event info panel -->
                <mat-expansion-panel 
                [expanded]="eventDetailsOpenState"
                (opened)="eventDetailsOpenState = true"
                (closed)="eventDetailsOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Event Info.
                        </mat-panel-title>
                        <mat-panel-description>
                            Edit detail(s): 
                            <span *ngIf="!isProdEnv">
                                {{eventDetailsOpenState ? 'open' : 'closed'}}
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <hr>


                    <!-- Angular Material Verison -->
                    <form [formGroup]="eventForm">
                        <!-- id -->
                        <mat-form-field 
                        style="display: flex;"
                        hidden>
                            <mat-label>Id:</mat-label>

                            <input 
                                matInput 
                                [(ngModel)]="selectedEventModel.Id"
                                formControlName="Id"
                                required>

                            <mat-error 
                                *ngIf="eventForm.get('Id').hasError('required')">
                                Event Id
                            </mat-error>
                        </mat-form-field>

                        <!-- name -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Name:</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="selectedEventModel.Name"
                                formControlName="Name"
                                (input)="onSearchChange($event)"
                                required>

                            <mat-error 
                                *ngIf="eventForm.get('Name').hasError('required')">
                                Activity name is required
                            </mat-error>
                        </mat-form-field>

                        <!-- Description -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Description:</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="selectedEventModel.Description"
                                formControlName="Description"
                                required>
                            <mat-error *ngIf="eventForm.get('Description').hasError('required')">Activity description is required</mat-error>
                        </mat-form-field>

                        <!-- PricePerPerson -->
                        <mat-form-field style="display: flex;">
                            <mat-label>Price Per Person:</mat-label>
                            <input 
                                type="number"
                                matInput 
                                [(ngModel)]="selectedEventModel.PricePerPerson"
                                formControlName="PricePerPerson"
                                required>
                            <mat-error *ngIf="eventForm.get('PricePerPerson').hasError('required')">Price Per Person is required</mat-error>
                        </mat-form-field>

                        <!-- 1. {{eventStatuses }} -->
                        <!-- 2. {{selectedEventModel.Status }} -->
                        <!-- 2. {{eventForm.Status }} -->

                         <!-- Status -->
                        <mat-form-field style="display: flex;">
                            <mat-label 
                                for="stus">Choose an option</mat-label>
                            <mat-select
                                id="stus" 
                                (selectionChange)="BindChangeToActivityModel($event.value)" 
                                formControlName="Status">
                                <mat-option 
                                    *ngFor="let status of eventStatuses" 
                                    [value]="status">
                                    {{status}}
                                </mat-option>
                              
                            </mat-select>

                            <mat-error *ngIf="eventForm.get('PricePerPerson').hasError('required')">Price Per Person is required</mat-error>
                        </mat-form-field>
                          
                        <mat-action-row>
                            <button 
                                *ngIf="(this.prevStateOfSelectedEventModel.Name !== this.selectedEventModel.Name) ||
                                        (this.prevStateOfSelectedEventModel.Description !== this.selectedEventModel.Description) ||
                                        (this.prevStateOfSelectedEventModel.PricePerPerson !== this.selectedEventModel.PricePerPerson) ||
                                        (this.prevStateOfSelectedEventModel.Status !== this.selectedEventModel.Status)"
                                (click)="UpdateEventDetails()"
                                mat-raised-button 
                                color="primary">
                                Update
                            </button>
    
                            <button 
                            mat-raised-button 
                            (click)="ValidateActivityDetailsAndProceedToImagesPanel()">
                                Next
                            </button>
                        </mat-action-row>

                       
                    </form>

                </mat-expansion-panel>
                
                <!-- image upload panel -->
                <mat-expansion-panel 
                    [expanded]="eventImagesOpenState"
                    (opened)="eventImagesOpenState = true"
                    (closed)="eventImagesOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Event Images
                        </mat-panel-title>
                        <mat-panel-description>
                            Edit event images: 
                            <span *ngIf="!isProdEnv">
                                {{eventImagesOpenState ? 'open' : 'closed'}}
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    
                    <!-- when there is less than 1 file selected -->
                    <ng-container 
                    *ngIf="selFiles.length < 1"
                    style="margin-top: 15px;">

                        <app-image-upload
                        [showProgress]="false"
                        [maxFiles]="5"
                        [maxSizeMB]="imgSelectMaxSize"
                        (filesUploaded)="onFilesUploaded($event)"> 
                            
                            <label 
                                style="width: 100%;"
                                class="imgLabel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
                                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                </svg>
                                <br><br><br>
                                Upload image(s) of activity
                            </label> 

                        </app-image-upload>
                    
                    </ng-container>
        

                    <!-- when there is more than 1 file -->
                    <ng-container *ngIf="selFiles.length > 0">
                        <span *ngFor="let x of selFiles">
                            <div 
                                class="col-md-12 col-md-6 col-lg-4 mb-4">

                                <div 
                                    class="card text-white card-has-bg click-col" 
                                    [style.backgroundImage]="'url(' + rtImgPath + x + ')'">
                                
                                    <div class="card-img-overlay d-flex flex-column">
                
                                        <div class="card-footer">
                
                                            <div class="media">
                                                
                                                <span 
                                                    (click)="removeSelectedImage(x)"
                                                    class="removImageSpan" 
                                                    style="float: right; color: red;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                                    </svg>
                                                </span>
                
                                            </div>
                
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <!-- button to append more image(s) -->
                        <div class="col-xs-12 col-sm-6 col-md-4">
                            <app-image-upload
                                [showProgress]="false"
                                [maxFiles]="3"
                                [maxSizeMB]="imgSelectMaxSize"
                                (filesUploaded)="onAppendFilesUploaded($event)"> 
                                
                                <button 
                                class="btn btn-outline-primary"
                                style="margin-top: auto; margin-bottom: auto; margin: 10px; padding: 16px; border-radius: 20px; color: white; background-color: rgb(72, 5, 139);">
            
                                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-node-plus" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8M6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5M1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                                </svg>
                            </button>
            
            
                            </app-image-upload>
        
                        </div>
                    </ng-container>

                    
                    <mat-action-row>
                        <button 
                            *ngIf="prevStateOfSelectedEventModel.Images !== selectedEventModel.Images"
                            (click)="UpdateEventDetails()"
                            mat-raised-button 
                            color="primary"
                            (click)="null">
                            Update
                        </button>

                        <button 
                            style="float: left;"
                            (click)="ValidatImagesPanelAndRevertBackToActivityDetailsPanel()"
                            mat-raised-button 
                            (click)="null">
                            Back
                        </button>

                        <button 
                            mat-raised-button 
                            (click)="ValidatImagesPanelAndProceedToLocationPanel()">
                            Next
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>

                <!-- location update -->
                <mat-expansion-panel 
                [expanded]="eventLocationOpenState"
                (opened)="eventLocationOpenState = true"
                (closed)="eventLocationOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Location
                    </mat-panel-title>
                    <mat-panel-description>
                        Edit Location: 
                        <span *ngIf="!isProdEnv">
                            {{eventLocationOpenState ? 'open' : 'closed'}}
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-container>
                   <!-- view edit location -->
                   <mat-form-field style="display: flex;">
                    <mat-label>Address</mat-label>
                    <input 
                    [disabled]="true"
                    [(ngModel)]="formattedAddrString"
                    matInput>
                  </mat-form-field>
                  <span 
                  (click)="editAddressInfo()"
                  style="float: right; color: blue;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
                  </span>
                </ng-container>

                <mat-action-row>

                    <button 
                        style="float: left;"
                        (click)="ValidatLocationPanelAndRevertBackToImagesPanel()"
                        mat-raised-button>
                        Back
                    </button>

                  
                </mat-action-row>
                </mat-expansion-panel>

                <!-- preference panel -->
                <mat-expansion-panel
                [expanded]="eventPreferencesOpenState"
                (opened)="eventPreferencesOpenState = true"
                (closed)="eventPreferencesOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Event Settings
                        </mat-panel-title>
                        <mat-panel-description>
                            Edit Settings: 
                            <span *ngIf="!isProdEnv">
                                {{eventPreferencesOpenState ? 'open' : 'closed'}}
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    
                    <hr>

                    <ng-container>

                        <!-- enable promoter -->
                        <div class="row mb-3">
                            <div>
                                <label 
                                    for="inputPassword6" 
                                    class="col-sm-2 col-form-label col-form-label-sm">
                                    Enable Promotion
                                    <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </span>
                                    :
                                </label>
                    
                                <!-- when promotion is not enabled -->
                                <span 
                                    class="col-sm-10"
                                    *ngIf="!selectedEventModel.EnablePromotion"
                                    (click)="toggleAppendPromotion()"
                                    style="text-align: center;">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(225, 42, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                                        <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                    </svg>
                                </span>
                    
                                <!-- when promotion is enabled -->
                                <span 
                                    class="col-sm-10"
                                    *ngIf="selectedEventModel.EnablePromotion"
                                    (click)="toggleAppendPromotion()"
                                    style="text-align: center;">
                                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(111, 241, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                                        <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    
                
                        <!-- promotion percentage setting -->
                        <div 
                            *ngIf="selectedEventModel.EnablePromotion"
                            class="row mb-3">
                
                            <label 
                                for="promoPercentage" 
                                class="col-sm-2 col-form-label col-form-label-sm">
                                Set Promo %
                                <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                                :
                            </label>
                                
                    
                            <!-- show percentage update when promotion is enabled -->
                            <span class="col-sm-10">
                                <input 
                                    type="number" 
                                    id="promoPercentage" 
                                    [(ngModel)]="this.selectedEventModel.EventPromotionPercentage"
                                    class="form-control form-control-sm"
                                    aria-describedby="passwordHelpInline"
                                    required
                                    style="width: 100%;">
                                <small>minimum percentage: 5%</small>
                            </span>
                    
                        </div>
                
                        <!-- PREVIOUS AND NEXT BTNS -->

                        <mat-action-row>
                            <button 
                                *ngIf="(this.prevStateOfSelectedEventModel.EnablePromotion !== this.selectedEventModel.EnablePromotion) ||
                                        (this.prevStateOfSelectedEventModel.EventPromotionPercentage !== this.selectedEventModel.EventPromotionPercentage)"
                                (click)="UpdateEventDetails()"
                                mat-raised-button 
                                color="primary">
                                Update
                            </button>
    
                            <!-- <button 
                            mat-raised-button 
                            (click)="ValidateActivityDetailsAndProceedToImagesPanel()">
                                Next
                            </button> -->
                        </mat-action-row>


                        <!-- <div>
                            <button 
                                mat-raised-button 
                                color="primary" 
                                matStepperPrevious
                                style="float: left;">
                                Back
                            </button>
                    
                            <button 
                                mat-raised-button 
                                color="primary" 
                                (click)="completeEventAdd()"
                                style="float: right;">
                                Complete
                            </button>
                        </div> -->
                    </ng-container>
                
                
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button 
            mat-raised-button 
            color="primary" 
            (click)="confirmAndClose()">
            Close
        </button>
    </mat-dialog-actions>
</div>

