<!-- {{roles$ | async }} -->
<div 
class="container roleContainer"
style="margin-top: 30px;">
  <!-- generic role -->
  <div class="card">
      
    <div class="card-body">
      <h3 class="card-title">
        Generic Role
      </h3>
    </div>

    <div class="card-footer">
      Status:

      <span style="float: right;">
        <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
        </svg>
      </span>

    </div>
  </div>

  <!-- activity host role -->
  <div class="card">
      
    <div class="card-body">
      <h3 class="card-title">
        Activity Host
      </h3>
    </div>

    <div class="card-footer">
      Status:

      <span 
        (click)="ToggleSelectedRole('mxp_ActivityHost','ADD')"
        *ngIf="!(isActivityHostUser$ | async)"
        style="float: right;">
        <!-- off toggle -->
        <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16">
          <path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/>
          <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/>
        </svg>

       
      </span>

      <span
      (click)="ToggleSelectedRole('mxp_ActivityHost','REMOVE')"
      *ngIf="isActivityHostUser$ | async"
      style="float: right;">
         <!-- on toggle -->
         <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16">
          <path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/>
          <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/>
        </svg>
      </span>
    </div>
  </div>


   <!-- activity promoter role -->
   <div class="card">
      
    <div class="card-body">
      <h3 class="card-title">
        Activity Promoter
      </h3>
    </div>

    <div class="card-footer">
      Status:

      <span 
        (click)="ToggleSelectedRole('mxp_ActivityPromoter','ADD')"
        *ngIf="!(isActivityPromoterUser$ | async)"
        style="float: right;">
        <!-- off toggle -->
        <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16">
          <path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/>
          <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/>
        </svg>

       
      </span>

      <span
      (click)="ToggleSelectedRole('mxp_ActivityPromoter','REMOVE')"
      *ngIf="isActivityPromoterUser$ | async"
      style="float: right;">
         <!-- on toggle -->
         <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16">
          <path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/>
          <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/>
        </svg>
      </span>
    </div>
  </div>

  <!-- Event host role -->
  <div class="card">
      
    <div class="card-body">
      <h3 class="card-title">
        Event Host
      </h3>
    </div>

    <div class="card-footer">
      Status:

      <span 
        (click)="ToggleSelectedRole('mxp_EventHost','ADD')"
        *ngIf="!(isEventHostUser$ | async)"
        style="float: right;">
        <!-- off toggle -->
        <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16">
          <path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/>
          <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/>
        </svg>

        
      </span>

      <span
        (click)="ToggleSelectedRole('mxp_EventHost','REMOVE')"
        *ngIf="isEventHostUser$ | async"
        style="float: right;">
        <!-- on toggle -->
        <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16">
          <path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/>
          <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/>
        </svg>
      </span>
    </div>
  </div>

   <!-- event promoter role -->
   <div class="card">
      
      <div class="card-body">
        <h3 class="card-title">
          Event Promoter
        </h3>
      </div>

      <div class="card-footer">
        Status:

        <span 
          (click)="ToggleSelectedRole('mxp_EventPromoter','ADD')"
          *ngIf="!(isEventPromoterUser$ | async)"
          style="float: right;">
          <!-- off toggle -->
          <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16">
            <path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/>
            <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/>
          </svg>

        
        </span>

        <span
        (click)="ToggleSelectedRole('mxp_EventPromoter','REMOVE')"
        *ngIf="isEventPromoterUser$ | async"
        style="float: right;">
          <!-- on toggle -->
          <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16">
            <path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/>
            <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/>
          </svg>
        </span>
      </div>
    </div>


   <!-- activity Manager role -->
   <div class="card">
      
    <div class="card-body">
      <h3 class="card-title">
        Activity Manager
      </h3>
    </div>

    <div class="card-footer">
      Status:

      <span 
        (click)="ToggleSelectedRole('mxp_ActivityManager','ADD')"
        *ngIf="!(isActivityManagerUser$ | async)"
        style="float: right;">
        <!-- off toggle -->
        <svg style="color: red;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-off" viewBox="0 0 16 16">
          <path d="M9 11c.628-.836 1-1.874 1-3a4.98 4.98 0 0 0-1-3h4a3 3 0 1 1 0 6z"/>
          <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10"/>
        </svg>

       
      </span>

      <span
      (click)="ToggleSelectedRole('mxp_ActivityManager','REMOVE')"
      *ngIf="isActivityManagerUser$ | async"
      style="float: right;">
         <!-- on toggle -->
         <svg style="color: green;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-toggle2-on" viewBox="0 0 16 16">
          <path d="M7 5H3a3 3 0 0 0 0 6h4a5 5 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416q.235-.537.584-1"/>
          <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0"/>
        </svg>
      </span>
    </div>
  </div>
</div>