import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, Subscription, switchMap, tap } from 'rxjs';
import { LOAD_EVENTS_FOR_ONE_USER } from 'src/app/ngrx/actions/current-user-events.actions';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { GET_CURRENT_USER_SUBSCRIPTION_TIER } from 'src/app/ngrx/actions/current-user-subscription-tier.actions';
import { GET_CURRENT_USER_SUBSCRIPTIONS } from 'src/app/ngrx/actions/current-user-subscription.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectUserEvents } from 'src/app/ngrx/selectors/current-user-events.selectors';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { selectUserSubscriptionTier } from 'src/app/ngrx/selectors/current-user-subscription-tier.selectors';
import { selectLoggedInUserSubscriptions } from 'src/app/ngrx/selectors/current-user-subscription.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-per-status',
  templateUrl: './event-per-status.component.html',
  styleUrls: ['./event-per-status.component.scss']
})
export class EventPerStatusComponent {
  pastEvents: EventStatus = EventStatus.Future;
  liveEvents: EventStatus = EventStatus.Future;
  futureEvents: EventStatus = EventStatus.Future;


  private canAddEventSubject = new BehaviorSubject<boolean>(false);
  canAddEvent$ = this.canAddEventSubject.asObservable();


  // useId:string | undefined;
  private useIdSubject = new BehaviorSubject<string | null>(null);
  useId$: Observable<string | null> = this.useIdSubject.asObservable();


  usrObject$: Observable<GenericUser | null>
  currentUserSubscriptions$:  Observable<UserSubscription[] | null>
  SubscriptionTier$:  Observable<SubscriptionTier | null>
  eventsCreatedByUser$:  Observable<MaiXEvent[] | null>

  combinedSubCheck$!: Observable<{ subTier: any; usrEvents: any }>;

  remainingEvents: number = 0;
  
    
  subscription: Subscription | undefined;
  
  constructor(private router: Router,
    private userSvc: UserSpecificService,
    private toastr: ToastrService,
    private authSvc: AuthService,
    private store: Store<AppState>
  ) {
     //DUPLICATE LOGIC: currently logged in user
     this.usrObject$! = this.store.pipe(select(selectLoggedInUser));

     //user subscription
     this.currentUserSubscriptions$ = this.store.pipe(select(selectLoggedInUserSubscriptions));
     
     //subscription tier
     this.SubscriptionTier$ = this.store.pipe(select(selectUserSubscriptionTier));
     
     
     this.eventsCreatedByUser$ = this.store.pipe(select(selectUserEvents));
   

  }

  ngOnInit()
  {
    //who can add event
    this.subscription = this.store.pipe(
      select(selectLoggedInUserRoles))
      .pipe(
        tap((userRoles) => {

          if (
            !userRoles || 
            userRoles.length === 0 || 
            !this.useIdSubject.getValue() || 
            this.useIdSubject.getValue()!?.length < 1) 
          {

            this.authSvc.GetCurrentlyLoggedInUserIdIfAny()?.subscribe({
              next: (response: string) => {
                this.useIdSubject.next(response);
                
                if (!environment.production) {
                  console.log(`About to dispatch current user roles`);
                }

                // Dispatch action to get user roles
                this.store.dispatch(GET_CURRENT_USER_ROLES());
              },
              error: (err: any) => {
                if(!environment.production)
                {
                  console.log("Unable to get logged in user: " + err);
                }
              },
              complete() {
                if(!environment.production)
                {
                  console.log("Complete");
                }
              }
            });
          }
        }),
        filter(userRoles => userRoles && userRoles.length > 0),
        tap(userRoles => {
          
          const usrRoleToExclude = 'mxp_General';
          
          const filteredUserRoles = userRoles.filter(role => role !== usrRoleToExclude);

          
          if(filteredUserRoles.includes('mxp_EventHost'))
          {
            this.canAddEventSubject.next(true);
          }
          else{
            this.canAddEventSubject.next(false);
          }
        })
      ).subscribe()
      
    //===== Subscription related starts =====
    this.combinedSubCheck$ = combineLatest([
      this.SubscriptionTier$,
      this.eventsCreatedByUser$
    ]).pipe(
      map(([subTier, usrEvents]) => ({ subTier, usrEvents })),
      tap(({ subTier, usrEvents }) => {
        if (subTier && usrEvents) {
          const difference = subTier.MaxEventsPerMonth - usrEvents.length;
  
          if(!environment.production)
          {
            // Log the values
            console.log('Max Events Per Month:', subTier.MaxEventsPerMonth);
            console.log('User Events Count:', usrEvents.length);
            console.log('Difference:', difference);
          }
          // Store the result in a variable
          this.remainingEvents = difference;
        } else {

          if(!environment.production)
          {
            console.log('Data is incomplete. Cannot calculate the difference.');
          }
        }
      })
    );


    //how many activities can user can
    this.subscription.add(
      //#USER
      this.usrObject$
      .pipe(
        // Ensure that only distinct changes in the user object are acted upon
        distinctUntilChanged((prev, curr) => prev?.Id === curr?.Id),


        // first(), // Take the first value emitted by the observable
        tap(user => {
          if (!user) {
            console.log(`No user logged in`);
            this.store.dispatch(GET_CURRENT_USER());
          } else {
            console.log(`User found ${JSON.stringify(user)}. Fetching subscriptions...`);
            this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTIONS({ userId: user.Id! }));
          }
        }),
        switchMap(() => this.currentUserSubscriptions$), // Chain to subscription logic
        distinctUntilChanged(), // Optional: Avoid unnecessary duplicate emissions
        tap(usrSubs => {
          if (!usrSubs) {
            console.log(`No subscriptions found.`);
          } else {
            console.log(`Current user subscriptions: ${JSON.stringify(usrSubs)}`);
            
            // WARNING: HIGER ORDER SUBSCRIPTION (usrSubs) to get activities for one user
            const activeSub = usrSubs.find((x: UserSubscription) => x.IsActive);

            this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTION_TIER({ tierId: activeSub?.TierId! ?? 0 }));
          
            // get all events the user created
            this.store.dispatch(LOAD_EVENTS_FOR_ONE_USER({ userId: activeSub?.UserId! ?? '' }));
            
          }
        }),
        switchMap(() => this.SubscriptionTier$), // Chain to subscription tier logic
        distinctUntilChanged(), // Optional: Avoid duplicate emissions for tier

        tap(subTier => {
          if (!subTier) {
            console.log(`No subscription tier found.`);
          } else {
            console.log(`User subscription tier: ${JSON.stringify(subTier)}`);
            //If we found subscription but no tier then there is a big problem
          }
        }),
        switchMap(() => this.eventsCreatedByUser$),
        distinctUntilChanged(), // Optional: Avoid duplicate emissions for tier
        tap(usrEvents => {
          if (!usrEvents) {
            console.log(`No events created by user.`);
          } else {
            console.log(`Events created by curr user: ${JSON.stringify(usrEvents)}`);
          }
        })
      )
      .subscribe()
    )

    //=====SUbscription related ENDS=====
    
  }

  RouteToAddNewEvent(){
    //validate subscription
    if(this.remainingEvents < 1)
    {
      // Show Toastr notification
      this.toastr.show("Subscription check failed", "Subscription");

      // Delay for 2 seconds, then prompt for confirmation
      setTimeout(() => {
        if(confirm("Navigate to subscription page"))
        {
          // User clicked 'Yes', navigate to subscription page
          this.router.navigate(['/pricing']);
        }
      },2000);
    }
    else
    {

      //observer to validate the cookie against the server
      const tknValidationObserver = {
        // if cookie is still valid
        next: async (response: any) => {
          if(!environment.production)
          {
            console.log(`Token validation from server response (event): ${JSON.stringify(response)}`);
          }
    
          // if token comes back as valid: route to addactivity
          this.router.navigate(['addevent']);
          
        },
        error: async (err: HttpErrorResponse) => {
          if(!environment.production)
          {
            console.log(`Error response from token validation (for event): ${JSON.stringify(err)}`);
          }
          
          if(err.url) //if err response is trying to navigate to another page, then it has expired
          {
            //remove the cookie (if any)
            this.userSvc.logout()
    
            //show the user some error
            this.toastr.error(
              "User not signed in...", "Error");
    
            //redirect to the login page
            this.router.navigate(['login'],
            {
              queryParams: {
                redirectUrl: 'addevent'
              }
            });
    

          }
        },
        complete: async () => {
          console.log(`user http request complete!`);
        }
      }
    
      //validate the token against the server
      this.authSvc.ValidateUserCookie().subscribe(tknValidationObserver)
    }
   }
}
