import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaiXEventService 
{
  private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }


  AddEvent(newEvent: any)
  {
    return this.http.post<any>(
      this.APIUrl + 'Event/AddEvent', 
      newEvent, 
      this.getHttpOptions());
  }

  GetAllEvents(): Observable<any[]> {
    let url = this.APIUrl + 'Event/GetAllEvents';

    return this.http.get<any>(
      url,
      this.getHttpOptions());

  }

  GetAllEventsWithPagination(pageNum: number, pageSz: number): Observable<MaiXEvent[]> {
    let url = this.APIUrl + `Event/GetAllEventsWithPagination?pageNumber=${pageNum}&pageSize=${pageSz}`;

    let eves = this.http.get<MaiXEvent[]>(
      url,
      this.getHttpOptions());

      
      if(!environment.production)
      {
        console.log(`Response from GetAllEventsWithPagination: ${JSON.stringify(eves)}`);
      }
  
      return eves;
    }

    

  GetAllEventsForAUserWithPagination(userId: string, pageNum: number, pageSz: number)
  {
    let url = this.APIUrl + `Event/GetAllEventsForAUserWithPagination?userId=${userId}&pageNumber=${pageNum}&pageSize=${pageSz}`;

    const actvs = this.http.get<MaiXEvent[]>(
      url
    );

    return actvs;
  }

  getFeaturedEvents(count: number): Observable<any[]> {
    let url = this.APIUrl + `Event/GetFeaturedEventsWithCount_Default2/${count}`;

    return this.http.post<any>(
      url,
      null,
      this.getHttpOptions());
  }

  // GetAllEventsByStatus

  // Get event
  GetAllEventsByStatus(statusCode: number): Observable<any[]>
  {
    let url = this.APIUrl + `Event/GetAllEventsByStatus/${statusCode}`;
      var result = this.http.get<any>(
        url);
        
        // this.activitiesSubject.next(result);
        
        return result;
  }

  GetAllEventsByStatusWithPagination(statusCode: number, pageNumber: number, pageSize: number) : Observable<any>
  {
    let url = this.APIUrl + `Event/GetAllEventsByStatusWithPagination?statusCode=${statusCode}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      
    var result = this.http.get<any>(url);
        
    return result;

    // return this.http.get<MaiXEvent[]>(`${this.apiUrl}/future`, {
    //   params: {
    //     page: pageNumber.toString(),
    //     size: pageSize.toString()
    //   }
    // });
  }

  //Note: this is country specific
  GetAllEventsThatAreOpenForPromotionWithPaginationWithFilter(
    pageNum: number, 
    pageSz: number,
    countryVariants: string = "ZA, South Africa",
    loggedInUserToExclude: string
  ) : Observable<MaiXEvent[]>
  {
    let url = this.APIUrl + `Event/GetAllEventsThatAreOpenForPromotionWithPaginationWithFilter?pageNumber=${pageNum}&pageSize=${pageSz}&countryVariants=${countryVariants}&loggedInUserToExclude=${loggedInUserToExclude}`;

    const evnts = this.http.get<MaiXEvent[]>(
      url
    );

    if(!environment.production)
    {
      console.log(`Response from Get All events ThatAreOpen For Promotion WithPagination WithFilter : ${JSON.stringify(evnts)}`);
    }

    return evnts;
  }
  

  GetEventByIds(eventIds: number[]) : Observable<MaiXEvent[]> {
    let url = this.APIUrl + `Event/GetEventByIds`;

    const eventsByIds = this.http.post<MaiXEvent[]>(
      url,
      eventIds
    );

    if(!environment.production)
    {
      console.log(`Response from GetEventsByIds: ${JSON.stringify(eventsByIds)}`);
    }

    return eventsByIds;
  }

  // GetEventById
  GetEventById(eventId: number)
  {
    return this.http.get<any>(
      this.APIUrl + `Event/GetEventById/${eventId}`, 
      this.getHttpOptions());
  }

  // UpdateEvent
  UpdateEvent(eventChanges: any)
  {
    return this.http.put<any>(
      this.APIUrl + 'Event/UpdateEvent', 
      eventChanges, 
      this.getHttpOptions());
  }

  // RemoveEvent
  RemoveEvent(eventId: number)
  {
    return this.http.post<any>(
      this.APIUrl + `Event/RemoveEvent/${eventId}`,
      null,
      this.getHttpOptions());
  }

  getHttpOptions()
  {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      })
      .set('Cache-Control', 'max-age=3600') // Set the cache control header,
      .delete('Pragma')
    };

    return httpOptions;
  }
}
