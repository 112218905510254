import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserSubscriptionTierState } from "../state/current-user-subscription-tier.state";

export const selectSubscriptionTierState = createFeatureSelector<UserSubscriptionTierState>('usersubscriptiontier');

export const selectUserSubscriptionTier = createSelector(
    selectSubscriptionTierState,
  (state: UserSubscriptionTierState) => state.usersubscriptiontier
);

export const selectLoggedInUserSubscriptionsLoading = createSelector(
    selectSubscriptionTierState,
  (state: UserSubscriptionTierState) => state.loading
);

export const selectLoggedInUserSubscriptionsLoaded = createSelector(
    selectSubscriptionTierState,
    (state: UserSubscriptionTierState) => state.loaded
  );

export const selectLoggedInUserSubscriptionsError = createSelector(
    selectSubscriptionTierState,
  (state: UserSubscriptionTierState) => state.error
);