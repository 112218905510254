import { createReducer, on } from "@ngrx/store";
import { PromoterUsersState } from "../state/promoter-users.state";
import * as ActivePromoterActions from "../actions/promoter-users.actions";


const initialState: PromoterUsersState = {
    activepromoterusers: [],
    loading: false,
    loaded: false,
    error: null,
};

const _activePromotersReducer = createReducer(
    initialState,
    on(ActivePromoterActions.LOAD_PROMOTERS, state => ({ ...state, loading: true })),
    on(ActivePromoterActions.LOAD_PROMOTERS_SUCCESS, (state, { activepromoterusers }) => ({
        ...state,
        activepromoterusers: [...activepromoterusers],
        loading: false,
        loaded: true,
        error: null,
    })),
    on(ActivePromoterActions.LOAD_PROMOTERS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function activePromotersReducer(state: any, action: any) {
    return _activePromotersReducer(state, action);
  }