import { Component, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { environment } from 'src/environments/environment';
// import { KnownApplication } from '../auth/login/login.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { KnownApplication } from 'src/app/types/models/knownapplication.model';
import { GenericRole } from 'src/app/types/models/genericrole.model';
import { LoaderService } from 'src/app/shared/utils/loader.service';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import moment from 'moment';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
  styleUrls: ['./utils.component.scss']
})
export class UtilsComponent {
  appRegistedOnCustomAuth: boolean = false;

  registeredUsers: any
  allRoles: any
  allRolesNames: any

  allSubTiers: any

  readonly panelOpenState = signal(false);

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private usrSvc: UserSpecificService,
    private authService: AuthService,
    private loadingService: LoaderService,
    private dialogSvc: DialogService) 
{ 
}

ngOnInit()
{
  //check if the application exist on known application
  // if it exists return false
  this.authService.IsAppRegistered(environment.AppBoundIdentifier, environment.AppNameForCustomAuth).subscribe((dt: any) => {
      
      if(dt)
      {
        console.log(`Yes App Is Registered: ${JSON.stringify(dt)}`);

        this.appRegistedOnCustomAuth = false;
      }
      else
      {
        console.log(`No app is not registered: ${JSON.stringify(dt)}`);

        // else return true
        this.appRegistedOnCustomAuth = true;
      }
    });

    this.getMaiXUsers();

    this.getMaixRoles();

    //get subscription tiers
    this.getMaixSubTiers();
  }

  getMaiXUsers()
  {

    const usrsObserver = {
      next: async (response: any) => {
        this.registeredUsers = response;
        console.log(`Users: ${JSON.stringify(this.registeredUsers)}`);
      },
      error: async (err: HttpErrorResponse) => {
        console.log(`Error while getting users of maiXp: ${JSON.stringify(err)}`);
        // this.toastr.error(err?.error?.Message, "Error");

        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.usrSvc.logout()

          //show the user some error
          this.toastr.error(
            "Session ended. Login", "Error");

          //redirect to the login page
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'utils'
            }
          });
          
        }
        // this.router.navigateByUrl('/activitydetails', { 
        //   state: this.activitySelect
        // })
      },
      complete: async () => {
        console.log(`user http request complete!`);
        // this.router.navigate(['login'])
      }
    }

    //get all the users and roles that MaiX has
    this.authService.GetAllUsersOfMaiXpByAppId(environment.AppBoundIdentifier)
    .subscribe(usrsObserver);
  }

  getMaixRoles()
  {

    const rlsObserver = {
      next: async (response: any) => {
        this.allRoles = response;
        console.log(`Roles: ${JSON.stringify(this.allRoles)}`);


        //map role names
        this.allRolesNames = this.allRoles.map((role: GenericRole) => role.Name);

        console.log(`Roles Names: ${JSON.stringify(this.allRolesNames)}`);

      },
      error: async (err: HttpErrorResponse) => {
        console.log(`Error while getting roles of maiXp: ${JSON.stringify(err)}`);
        // this.toastr.error(err?.error?.Message, "Error");

        //handle token expiry
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.usrSvc.logout()

          this.toastr.error(
            "Session ended. Login", "Error");

          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'utils'
            }
          });
        }
        // this.router.navigateByUrl('/activitydetails', { 
        //   state: this.activitySelect
        // })
      },
      complete: async () => {
        console.log(`user http request for roles complete!`);
        // this.router.navigate(['login'])
      }
    }

    //get all roles of MaiX
    this.authService.GetAllRolesForMaiXp(environment.AppBoundIdentifier)
    .subscribe(rlsObserver)
  }

  
  getMaixSubTiers()
  {

    const subTierObserver = {
      next: async (response: any) => {
        this.allSubTiers = response;
        console.log(`Defined subscription tiers: ${JSON.stringify(this.allSubTiers)}`);

      },
      error: async (err: HttpErrorResponse) => {
        console.log(`Error while getting defined subscriptions of maiXp: ${JSON.stringify(err)}`);
       
        //handle token expiry
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.usrSvc.logout()

          this.toastr.error(
            "Session ended. Login", "Error");

          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'utils'
            }
          });
        }
    
      },
      complete: async () => {
        console.log(`user http request for subscriptions complete!`);
        // this.router.navigate(['login'])
      }
    }

    //get all roles of MaiX
    this.authService.GetAllSubscriptionsForMaiXp()
    .subscribe(subTierObserver)
  }

  

  registerAppToCustomAuth()
  {
    //known App
    let knownApp = new KnownApplication(
      environment.AppNameForCustomAuth);

    this.authService.RegisterToCustomAuth(knownApp)
      .subscribe((response: any) => {
        if(!environment.production)
        {
          console.log(JSON.stringify(response));
        }

        if(response == "Application added successfully")
        {
          window.location.reload();
        }
      })
  }

  ShowUserAddTemplate()
  {
    //ShowRoleToUserAddDialog
    //AddRoleToUserComponent
    this.dialogSvc.ShowUserAddDialog().afterClosed().subscribe((result) => {
      console.log('Dialog closed with result:', result);
      // Handle the result data here, e.g., update your component properties
      if(result.includes("user added"))
      {
        this.getMaiXUsers();
      }
    });
  }

  showAddUserRoleDialog(selectedUser: any) {

    //get all mxp roles
    if(!environment.production)
    {
      console.log(`>>1-USER>> selected user to append role: ${JSON.stringify(selectedUser)}`)
      console.log(`>>2-ROLES>> mxp roles: ${JSON.stringify(this.allRoles)}`)
    }

    this.dialogSvc.ShowUserRoleAddDialog(selectedUser, this.allRolesNames)
    .afterClosed()
    .subscribe((result) => {
      if(!environment.production)
      {
        console.log('Dialog closed with result:', result);
      }

      // Handle the result data here, e.g., update your component properties
      if(result.includes("role added to user"))
      {
        // this.getMaiXUsers();

        //get the user roles
      }
    });
  }

  removeUser(usrId: any)
  {
    console.log(`user to remove: ${usrId.toString()}`);

     //
     const remUserObserver = {
      next: async (data: any) =>  {
        this.toastr.success(`User removed successfully !`, 'Success')
        console.log(`User remove response: ${JSON.stringify(data)}`);
        
        //refresh the page without interruption
        this.getMaiXUsers();
      },
      error: (err: HttpErrorResponse) => 
      {
        console.log(`Error: ${JSON.stringify(err)}`),
        this.toastr.error('Error occurred, please try again!', 'Error')
      },
      complete: () => { 
        console.log("User Removed !");
      }
    }

    this.authService.RemoveUserById(usrId)
    .subscribe(remUserObserver);
  }

  ShowRoleAddTemplate()
  {
    this.dialogSvc.ShowRoleAddDialog().afterClosed().subscribe((result) => {
      console.log('Dialog closed with result:', result);
      // Handle the result data here, e.g., update your component properties
      if(result.includes("role added"))
      {
        this.getMaixRoles();
      }
    });
  }

  ShowSubscriptionTierAddTemplate()
  {
    this.dialogSvc.ShowSubTierAddDialog().afterClosed().subscribe((result) => {
      console.log('Dialog closed with result:', result);
      // Handle the result data here, e.g., update your component properties
      if(result.includes("Subscription added"))
      {
        this.getMaixSubTiers();

        window.location.reload();
      }
    });
  }

  ManualUserSub(user: GenericUser, tier: SubscriptionTier)
  {
    

    const email = user.Email
    const amt = tier.Price * 100
    const plan = tier.PSTPlanCode

    //current moment
    let timezone = 'Africa/Johannesburg';
  
    const currentMoment = moment(new Date())
    .tz(timezone)
    .toString()

    //deduced end moment
    const oneMonthFromNow = moment(new Date())
    .tz(timezone)
    .add(1, 'month')
    .toString();

    const oneYearFromNow = moment(new Date())
    .tz(timezone)
    .add(1, 'year')
    .toString();

    const bCycle = tier.planInterval
    const amtPerSelection = tier.Price
    const deducedSubEnd = tier.planInterval == 'monthly' ? oneMonthFromNow : oneYearFromNow
    // const initialSub = 1 // we need to check if the user has a previous subscription for this


    if(!environment.production)
    {
      console.log(`Email: ${email} | Amount: ${amtPerSelection} | Plan: ${plan}`);
    }
    
    //checkPreviousUserSub Observer
    const checkPreviousUserSubObserver = {
      next: async (data: any) => {
        
        if(!environment.production)
        {
          console.log(`Previous user subscription: ${JSON.stringify(data)}`);
        }

        const filteredUsrSubByTier = data ? data?.find((x: UserSubscription) => x.TierId ==  tier.Id) : undefined
        let usrSub;
        
        //if we have previous one and the tier matches the one we want to add not
        // lets identify it and extract the cycle iteration
        if(filteredUsrSubByTier && filteredUsrSubByTier.TierId == tier.Id)
        {
          console.log(`Working on previous copy`)
          const iterationSub = filteredUsrSubByTier?.IterationCount + 1
          
          //
          usrSub = new UserSubscription(
            filteredUsrSubByTier.Id,
            filteredUsrSubByTier.UserId,
            filteredUsrSubByTier.TierId,
            filteredUsrSubByTier.BillingCycle,
            iterationSub,
            currentMoment,
            deducedSubEnd,
            true
          )

          console.log(`User sub to update: ${JSON.stringify(usrSub)}`)

          //updateUsrSubToMxp Observer
          const updateUsrSubToMxpObserver = {
            next: async (data: any) => {
              this.toastr.success(`subscription update processed successfully !`, 'Success')
              console.log(`User subscription update Successfully. Response: ${JSON.stringify(data)}`);
              
              this.loadingService.hide();
            },
            error: (err: HttpErrorResponse) => 
            {
              this.toastr.error(`Error updating user subscription`, 'Error')
              console.log(`Error: ${JSON.stringify(err.error)}`),
      
              this.loadingService.hide();
            }
          }

          this.authService.UpdateUserSubscription(usrSub)
          .subscribe(updateUsrSubToMxpObserver)
        }
        else
        {
          console.log(`Working on NEW subscription`)
          const initialSub = 1
          
          //
          usrSub = new UserSubscription(
            0,
            user.Id!,
            tier.Id,
            bCycle,
            initialSub, //iteration count
            currentMoment,
            deducedSubEnd,
            true
          )


          //addUsrSubToMxp Observer
          const addUsrSubToMxpObserver = {
            next: async (data: any) => {
              this.toastr.success(`User subscription processed successfully !`, 'Success')
              console.log(`User subscription Processed Successfully. Response: ${JSON.stringify(data)}`);
              
              this.loadingService.hide();
            },
            error: (err: HttpErrorResponse) => 
            {
              this.toastr.error(`Error processing user subscription`, 'Error')
              console.log(`Error: ${JSON.stringify(err.error)}`),
      
              this.loadingService.hide();
            }
          }
      
          
          // activate subscription (bypass paystack)
          this.authService.AddUserSubscriptionToMxp(usrSub)
              .subscribe(addUsrSubToMxpObserver); 
        }
    
      
     
      },
      error: (err: HttpErrorResponse) => 
      {
        this.toastr.error(`Error getting previous user subscription`, 'Error')
        
        if(!environment.production)
        {
          console.log(`Error: ${JSON.stringify(err.error)}`)
        }

        this.loadingService.hide();
      }
    }

    this.loadingService.show();

    //check if user has a previous Subscription
    this.authService.GetUserSubscriptionByUserId(user.Id!)
    .subscribe(checkPreviousUserSubObserver)
  }

  removeRole(roleId: string){
    console.log(`Remove role: ${roleId}`);

    //
    const remRoleObserver = {
      next: async (data: any) =>  {
        this.toastr.success(`Role removed successfully !`, 'Success')
        console.log(`Role remove response: ${JSON.stringify(data)}`);
        
        //
        this.getMaixRoles();
      },
      error: (err: HttpErrorResponse) => 
      {
        console.log(`Error: ${JSON.stringify(err)}`),
        this.toastr.error('Error occurred, please try again!', 'Error')
      },
      complete: () => { 
        console.log("Role Removed !");
      }
    }

    
    this.authService.RemoveRoleById(roleId)
    .subscribe(remRoleObserver)
  }

  removeSubsciptionTier(subTierId: string){
    console.log(`Remove Subsciption Tier: ${subTierId}`);

    
    //
    const remSubTierObserver = {
      next: async (data: any) =>  {
        this.toastr.success(`Tier removed successfully !`, 'Success')
        console.log(`Subscription tier remove response: ${JSON.stringify(data)}`);
        
        //
        this.getMaixSubTiers();

        this.loadingService.hide();

        window.location.reload();
      },
      error: (err: HttpErrorResponse) => 
      {
        console.log(`Error: ${JSON.stringify(err)}`),
        this.toastr.error('Error occurred, please try again!', 'Error')
        
        this.loadingService.hide();
      },
      complete: () => { 
        console.log("Sub. Tier Removed !");
      }
    }

    this.loadingService.show();

    this.authService.RemoveSubTierById(subTierId)
    .subscribe(remSubTierObserver)
  }


  resendConfirmationEmail()
  {
    //get token for the user

    //request the appropriate template

    //send the email
  }
}
