import { createReducer, on } from "@ngrx/store";
import { EventsIAmPromotingState } from "../state/events-I-am-Promoting.state";
import * as EventPromotingActions  from "../actions/events-i-am-promoting.actions";


const initialState: EventsIAmPromotingState = {
    eventsiampromoting: [],
    loading: false,
    loaded: false,
    error: null,
};

const _eventsIAmPromotingReducer = createReducer(
    initialState,
    on(EventPromotingActions.LOAD_EVENTS_I_AM_PROMOTING, state => ({ ...state, loading: true })),
    on(EventPromotingActions.LOAD_EVENTS_I_AM_PROMOTING_SUCCESS, (state, { eventsiampromoting }) => ({
        ...state,
        eventsiampromoting,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(EventPromotingActions.LOAD_EVENTS_I_AM_PROMOTING_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function eventsIAmPromotingReducer(state: any, action: any) {
    return _eventsIAmPromotingReducer(state, action);
}