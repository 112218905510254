<h2 mat-dialog-title>
    Activate 30 days Free-Tier
</h2>
<br><br>

    <mat-dialog-content class="mat-typography">
        <!-- length of code space generation -->

        <mat-form-field 
            appearance="outline" 
            style="width: 100%;">
            <mat-label>Enter your code</mat-label>
            <!-- [maxLength]="code.length" -->
            <input
              matInput
              [(ngModel)]="enteredCode"
              class="code-input"
              placeholder="Enter your code"
            />
          </mat-form-field>

    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <button 
        mat-button 
        mat-dialog-close>Close</button>
        
        <button 
            mat-button 
            [disabled]="enteredCode.length < 2"
            (click)="submitCode()">
            Submit
        </button>
    </mat-dialog-actions>
