import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressModel } from 'src/app/types/models/addressModel.model';
import { environment } from 'src/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private APIUrl: string | undefined;
  // APIUrl = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
  }

  // GetAddressOfAnActivity(activityId: number)
  // {
  //   return this.http.get<any>(
  //     this.APIUrl + 
  //       `Address/GetAddressOfAnActivityByActivityId/${activityId}`,
  //     this.getHttpOptions());
  // }

  GetAddressByAddressId(addressId: number)
  {
    return this.http.get<any>(
      this.APIUrl + 
        `Address/GetAddressByAddressId/${addressId}`,
      this.getHttpOptions());
  }

  AddAddressToAnActivity(val: any)
  {
    return this.http.post<any>(
      this.APIUrl + 'Address/AddAddressToAnActivity/', 
      val);
  }

  validateSelectedAddress(address: AddressModel) : boolean
  {
    // address.Street_number &&
    // address.Postal_code
    if(
      address.Street &&
      address.City &&
      address.State &&
      address.Country
    ) {
      return true;
    }
    else{
      return false;
    }
  }
  
  getHttpOptions(){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };

    return httpOptions;
  }
}
