<!-- <div class="image-container" >
  <img src="../../../../assets/images/workplan.jpg" 
  class="responsive-image" />
  
</div> -->

<div class="container">
  
  <h1 style="margin-top: 8px;">
    # Maixperience Documentation
  </h1>
  <hr>

  
  <p>
    Welcome to maixperience, where you can discover, participate, host variety of exciting activities and events. To help you get the most out of our website, we've put together this quick guide on how to use our features effectively.
  </p>

  <h2>
    # Quick Start
  </h2>
  <hr>
  <div class="container">

    <!-- Card deck -->
    <div class="card-deck row">
    
      <!-- Activity Card -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card">
      
          <!--Card image-->
          <div class="view overlay">
            <img 
            class="card-img-top" 
            [src]="rtImagePath + 'ack2.jpg'" 
            alt="Activities image">
            <a href="#!">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
      
          <!--Card content-->
          <div class="card-body">
      
            <!--Title-->
            <h4 
            class="card-title"
            style="font-weight: bold;">
              Activities
            </h4>

            <!--Text-->
            <p class="card-text container">
              <span style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
                </svg>
              </span>
              We have gone the extra mile to collaborate with multiple clients to provide our users with activities they can engage in for leisure, recreation or adventure in a fun and exciting manner.
            </p>
            
          </div>

          <div class="card-footer">
            <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
            <button 
              class="btn btn-outline-primary"
              routerLink="/howactivity">
              Learn more
            </button>
      
          </div>
      
        </div>
      </div>
    
      <!-- Event Card -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card mb-4">
      
          <!--Card image-->
          <div class="view overlay">
            <img 
            class="card-img-top" 
            [src]="rtImagePath + 'upEv2.jpg'" 
            alt="Event(s)">
            <a href="#!">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
      
          <!--Card content-->
          <div class="card-body">
      
            <!--Title-->
            <h4 
              class="card-title"
              style="font-weight: bold;">
              Events
            </h4>

            

            <!--Text-->
            <p class="card-text container">
              <span style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-dice-2" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z"/>
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                </svg>
              </span>
              Organized gatherings planned with a specific purpose and often include various elements such as entertainment, activities, food, and social interactions
              
            </p>
          </div>

          <div class="card-footer">
            <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
            <button 
              class="btn btn-outline-primary"
              routerLink="/howevent">
              Learn more
            </button>
          </div>
      
        </div>
      </div>
    

       <!-- Promotion Card -->
       <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card mb-4">
      
          <!--Card image-->
          <div class="view overlay">
            <img 
            class="card-img-top" 
            [src]="rtImagePath + 'promo.jpg'" 
            alt="Promoter(s) Image">
            <a href="#!">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
      
          <!--Card content-->
          <div class="card-body">
      
            <!--Title-->
            <h4 
              class="card-title"
              style="font-weight: bold;">
              Promoters
            </h4>

            

            <!--Text-->
            <p class="card-text container">
              <span style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-bezier2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01q.269.27.484.605C8.246 5.097 8.5 6.459 8.5 8c0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a3 3 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                </svg>
              </span>
              Promoter(s) are of 2 types: Activities and Events Promoters. This is where individual(s) apply to companies and/or host(s) and get returns on successful promotion(s).
            </p>
          </div>

          <div class="card-footer">
            <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
            <button 
              class="btn btn-outline-primary"
              routerLink="/howpromotion">
              Learn more
            </button>
          </div>
      
        </div>
      </div>
      

      <!-- Competition Card -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card mb-4">
      
          <!--Card image-->
          <div class="view overlay">
            <img 
            class="card-img-top" 
            [src]="rtImagePath + 'cmp.avif'" 
            alt="competition(s)">
            <a href="#!">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
      
          <!--Card content-->
          <div class="card-body">
      
            <!--Title-->
            <h4 
              class="card-title"
              style="font-weight: bold;">
              Competitions
            </h4>

            

            <!--Text-->
            <p class="card-text container">
              <span style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-bezier2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01q.269.27.484.605C8.246 5.097 8.5 6.459 8.5 8c0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a3 3 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                </svg>
              </span>
              A competition is an event where two individuals or groups compete against each other to win a title. These events can span various activities, including sports, academics, arts, gaming, culinary arts, fitness, and more.
            </p>
          </div>

          <div class="card-footer">
            <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
            <button 
              class="btn btn-outline-primary"
              routerLink="/howcompetition">
              Learn more
            </button>
          </div>
      
        </div>
      </div>
      
      <!-- Clubs Card -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card mb-4">
      
          <!--Card image-->
          <div class="view overlay">
            <img 
            class="card-img-top" 
            [src]="rtImagePath + 'clb.png'" 
            alt="Club(s)">
            <a href="#!">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
      
          <!--Card content-->
          <div class="card-body">
      
            <!--Title-->
            <h4 
              class="card-title"
              style="font-weight: bold;">
              Clubs
            </h4>

            

            <!--Text-->
            <p class="card-text container">
              <span style="color: red;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-suit-club" viewBox="0 0 16 16">
                  <path d="M8 1a3.25 3.25 0 0 0-3.25 3.25c0 .186 0 .29.016.41.014.12.045.27.12.527l.19.665-.692-.028a3.25 3.25 0 1 0 2.357 5.334.5.5 0 0 1 .844.518l-.003.005-.006.015-.024.055a22 22 0 0 1-.438.92 22 22 0 0 1-1.266 2.197c-.013.018-.02.05.001.09q.016.029.03.036A.04.04 0 0 0 5.9 15h4.2q.014 0 .022-.006a.1.1 0 0 0 .029-.035c.02-.04.014-.073.001-.091a23 23 0 0 1-1.704-3.117l-.024-.054-.006-.015-.002-.004a.5.5 0 0 1 .838-.524c.601.7 1.516 1.168 2.496 1.168a3.25 3.25 0 1 0-.139-6.498l-.699.03.199-.671c.14-.47.14-.745.139-.927V4.25A3.25 3.25 0 0 0 8 1m2.207 12.024c.225.405.487.848.78 1.294C11.437 15 10.975 16 10.1 16H5.9c-.876 0-1.338-1-.887-1.683.291-.442.552-.88.776-1.283a4.25 4.25 0 1 1-2.007-8.187l-.009-.064c-.023-.187-.023-.348-.023-.52V4.25a4.25 4.25 0 0 1 8.5 0c0 .14 0 .333-.04.596a4.25 4.25 0 0 1-.46 8.476 4.2 4.2 0 0 1-1.543-.298"/>
                </svg>
              </span>
              An association dedicated to a particular interest, activity or event.
            </p>
          </div>

          <div class="card-footer">
            <!-- Provides extra visual weight and identifies the primary action in a set of buttons -->
            <button 
              class="btn btn-outline-primary"
              routerLink="/howclub">
              Learn more
            </button>
          </div>
      
        </div>
      </div>
    
      
    </div>
    <!-- Card deck -->
      
  </div>



  <h2>
    # Application roles
  </h2>
  <hr>

  <p>
    When a user registers, you get a default <i style="font-weight: bold;">general user</i> role. In order to perform more actions (examples: bececome an activity host or event host), you have to apply on the platform by navigating to profile > roles. Refer to details below on application role(s):
  </p>


  <div class="accordion" id="accordionExample">
    
    <!-- general users -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          General User
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">

          <div class="container">
            <p>+ General user(s) can: </p>
            <ol class="my-list">
              <li tabindex="1">can view activities</li>
              <li tabindex="1">can book any activity</li>
              <li tabindex="1">can apply to be an activity host</li>
              <li tabindex="1">can apply to be an activity promoter</li>
              <li tabindex="1">can view events</li>
              <li tabindex="1">can book events</li>
              <li tabindex="1">can apply to be an event host at an activity spot</li>
              <li tabindex="1">can apply to be an event promoter</li>
              <li tabindex="1">can view competitions</li>
              <li tabindex="1">can enter a competition (T&C apply)</li>
              <li tabindex="1">can join a club (T&C apply)</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- activity hosts -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Activity Host
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="container">
            <p>+ Activity hosts can: </p>
            <ol class="my-list">
              <li tabindex="1">can create NEW activities</li>
              <li tabindex="2">can edit or update their activities</li>
              <li tabindex="3">can view all activities</li>
              <li tabindex="4">can book any activity</li>
              <li tabindex="5">can view booking history of their activities</li>
              <li tabindex="6">can claim revenue generated on their activities (T&C apply)</li>
              <li tabindex="7">can view performance analytics for their activities.</li>
              <li tabindex="8">can apply to be an activity promoter</li>
              <li tabindex="9">can view events</li>
              <li tabindex="10">can book events</li>
              <li tabindex="10">can apply to be an event host at an activity spot</li>
              <li tabindex="11">can apply to be an event promoter</li>
              <li tabindex="12">can view competitions</li>
              <li tabindex="13">can enter a competition (T&C apply)</li>
              <li tabindex="14">can join a club (T&C apply)</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!-- activity promoters -->
    
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Activity Promoters
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="container">
            <p>+ Activity Promoters can: </p>
            <ol class="my-list">
              <li tabindex="1">can view activities</li>
              <li tabindex="2">can view activities that are open for promotions (these are incentivized)</li>
              <li tabindex="3">can book any activity</li>
              <li tabindex="4">can view their successful promotions </li>
              <li tabindex="5">can claim revenue generated on their promotions (T&C apply)</li>
              <li tabindex="5">can apply to be an activity host</li>
              <li tabindex="7">can view events</li>
              <li tabindex="8">can book events</li>
              <li tabindex="9">can apply to be an event host at an activity spot</li>
              <li tabindex="10">can apply to be an event promoter</li>
              <li tabindex="11">can view competitions</li>
              <li tabindex="12">can enter a competition (T&C apply)</li>
              <li tabindex="13">can join a club (T&C apply)</li>
            </ol>
          </div>

        </div>
      </div>
    </div>

    <!-- event hosts -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFour">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Event Host
        </button>
      </h2>
      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <div class="container">
            <p>+ Event host(s) can: </p>
            <ol class="my-list">
              <li tabindex="1">can view activities</li>
              <li tabindex="1">can book any activity</li>
              <li tabindex="1">can apply to be an activity host</li>
              <li tabindex="1">can apply to be an activity promoter</li>
              <li tabindex="1">can create NEW events</li>
              <li tabindex="1">can edit or update their events</li>
              <li tabindex="1">can view all events</li>
              <li tabindex="1">can book events</li>
              <li tabindex="1">can view booking history of their events</li>
              <li tabindex="1">can claim revenue generated on their events (T&C apply)</li>
              <li tabindex="1">can view performance analytics for their events</li>
              <li tabindex="1">can apply to be an event promoter</li>
              <li tabindex="1">can view competitions</li>
              <li tabindex="1">can enter a competition (T&C apply)</li>
              <li tabindex="1">can join a club (T&C apply)</li>
            </ol>
          </div>

        </div>
      </div>
    </div>

    <!-- event promoters -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingFive">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Event Promoters
        </button>
      </h2>
      <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          
          <div class="container">
            <p>+ Event promoters can: </p>
            <ol class="my-list">
              <li tabindex="1">can view activities</li>
              <li tabindex="1">can book any activity</li>
              <li tabindex="1">can apply to be an activity host</li>
              <li tabindex="1">can apply to be an activity promoter</li>
              <li tabindex="1">can view events</li>
              <li tabindex="1">can view events that are open for promotions (these are incentivized)</li>
              <li tabindex="1">can book any event</li>
              <li tabindex="1">can apply to be an event host at an activity spot</li>
              <li tabindex="1">can view their successful event promotions</li>
              <li tabindex="1">can claim revenue generated on their event promotions (T&C apply)</li>
              <li tabindex="1">can view competitions</li>
              <li tabindex="1">can enter a competition (T&C apply)</li>
              <li tabindex="1">can join a club (T&C apply)</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- 
<div class="panel-container">
    <div class="panel" [ngClass]="{'active': activePanel === 1}" (click)="togglePanel(1)">
      <div class="panel-content"  id="intro">
        <h2></h2>
        <div class="container">
          <div class="content">
            <h3>Who are they</h3>
            <p>General users are those who interact with the system on a regular basis without special administrative privileges.</p>
            <h3>How do you become one?</h3>
            <p>To become a general user, you typically need to register on the website or platform and complete the necessary onboarding process.</p>
            <h3>What can they do?</h3>
            <p>General users can access the main functionalities of the website, such as viewing content, making purchases, and interacting with other users.</p>
            <h3>Benefits</h3>
            <p>General users benefit from a tailored experience, access to exclusive content, and the ability to participate in community activities.</p>
          </div>
          <div class="images-container">
            <img src="../../../../assets/images/postponed-concept.png" alt="Description of the image">
          </div>
        </div>
      </div>
    </div>
    <div class="panel" [ngClass]="{'active': activePanel === 2}" (click)="togglePanel(2)">
      <div class="panel-content"  id="feature1">
        <h2>Activity Host User</h2>
        <div class="content">
          <h3>Who are they</h3>
          <p>Activity Host Users are those who organize and host activities on the platform.</p>
          <h3>How do you become one?</h3>
          <p>To become an Activity Host User, you need to apply and get approved by the platform administrators.</p>
          <h3>What can they do?</h3>
          <p>Activity Host Users can create and manage activities, invite participants, and track engagement.</p>
          <h3>Benefits</h3>
          <p>They gain access to advanced tools for event management and can reach a larger audience.</p>
        </div>
      </div>
    </div>
    <div class="panel"  [ngClass]="{'active': activePanel === 3}" (click)="togglePanel(3)">
      <div class="panel-content" id="feature2">
        <h2>Activity Host Admin</h2>
        <div class="content">
          <h3>Who are they</h3>
          <p>Activity Host Admins oversee the activities hosted by others and ensure compliance with platform rules.</p>
          <h3>How do you become one?</h3>
          <p>Admins are typically appointed by the platform management based on their experience and reliability.</p>
          <h3>What can they do?</h3>
          <p>They have administrative privileges to monitor and manage all activities on the platform.</p>
          <h3>Benefits</h3>
          <p>Admins play a crucial role in maintaining the quality and safety of activities on the platform.</p>
        </div>
      </div>
    </div>
    <div class="panel" [ngClass]="{'active': activePanel === 4}" (click)="togglePanel(4)">
      <div class="panel-content"  id="sinuup" >
        <h2>Event Host User</h2>
        <div class="content">
          <h3>Who are they</h3>
          <p>Event Host Users are responsible for organizing and hosting larger events on the platform.</p>
          <h3>How do you become one?</h3>
          <p>To become an Event Host User, you need to demonstrate experience in event management and get approval from the platform.</p>
          <h3>What can they do?</h3>
          <p>They can create, promote, and manage large-scale events, and access additional tools for event coordination.</p>
          <h3>Benefits</h3>
          <p>They benefit from higher visibility, advanced promotional tools, and potential sponsorship opportunities.</p>
        </div>
      </div>
    </div>
    <div class="panel" [ngClass]="{'active': activePanel === 5}" (click)="togglePanel(5)">
      <div class="panel-content"  id="contact" >
        <h2>Event Host Admin</h2>
        <div class="content">
          <h3>Who are they</h3>
          <p>Event Host Admins have the highest level of control over events, ensuring they run smoothly and meet platform standards.</p>
          <h3>How do you become one?</h3>
          <p>Admins are selected based on their expertise and ability to manage complex events and coordinate with multiple stakeholders.</p>
          <h3>What can they do?</h3>
          <p>They have full administrative access to manage, monitor, and support all events on the platform.</p>
          <h3>Benefits</h3>
          <p>Admins play a pivotal role in the success of events, with access to the most comprehensive set of management tools and resources.</p>
        </div>
      </div>
    </div>
</div> -->
