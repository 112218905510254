import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/ngrx/state/app.state';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserSpecificService } from '../../services/dataService/user-specific.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatestWith, distinctUntilChanged, map, Observable, of, switchMap, tap } from 'rxjs';
import { selectLoggedInUserRoles } from 'src/app/ngrx/selectors/current-user-roles.selectors';
import { AuthService } from '../../services/CustomAuthenticator/auth.service';
import { GET_CURRENT_USER_ROLES } from 'src/app/ngrx/actions/current-user-roles.actions';
import { CommonModule } from '@angular/common';
import { GET_CURRENT_USER, RESET_LOGGED_IN_USER } from 'src/app/ngrx/actions/current-user.actions';
import { UserSubscription } from 'src/app/types/models/SubscriptionRelated/UserSubscription.model';
import { SubscriptionTier } from 'src/app/types/models/SubscriptionRelated/SubscriptionTier.model';
import { selectLoggedInUserSubscriptions, selectLoggedInUserSubscriptionsLoaded, selectLoggedInUserSubscriptionsLoading } from 'src/app/ngrx/selectors/current-user-subscription.selectors';
import { selectUserSubscriptionTier } from 'src/app/ngrx/selectors/current-user-subscription-tier.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { GET_CURRENT_USER_SUBSCRIPTION_TIER } from 'src/app/ngrx/actions/current-user-subscription-tier.actions';
import { GET_CURRENT_USER_SUBSCRIPTIONS } from 'src/app/ngrx/actions/current-user-subscription.actions';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';

@Component({
  selector: 'app-head-naviagation',
  standalone: true,
  imports: [
    MatToolbarModule, 
    MatButtonModule, 
    CommonModule,
    MatMenuModule,
    MatIconModule],
  templateUrl: './head-naviagation.component.html',
  styleUrl: './head-naviagation.component.scss'
})
export class HeadNaviagationComponent {
  onActivitiesPage: boolean = false

  canViewDashboard$: Observable<boolean> | undefined;
  canViewEventDashboard$: Observable<boolean> | undefined;
  canViewActivityPromotionDashboard$: Observable<boolean> | undefined;
  isUserLoggedIn: boolean = false;
  isActivityOrEventHost$: Observable<boolean> | undefined;

  activitiesFilterToggled:boolean = false;

  currentLogedInUsrRole$!: Observable<string[]>;
  
  // showSubModeToolbar = true;
  showSubModeToolbar$ = new BehaviorSubject<boolean>(true);


  // Declare the combinedSubscriptionTiers$ observable
  combinedSubscriptionTiers$: Observable<{ userSubscription: UserSubscription; tier: SubscriptionTier | undefined }[]> = of([]);

  // Declare the BehaviorSubject to hold the tiers
  private allSubscriptionTiersSubject = new BehaviorSubject<SubscriptionTier[]>([]);

  // Expose it as an observable
  allSubscriptionTiers$ = this.allSubscriptionTiersSubject.asObservable();

  currentUserSubscriptions$:  Observable<UserSubscription[] | null> 
  currentUserSubscriptionsLoading$: Observable<any>
  currentUserSubscriptionsLoaded$: Observable<any>

  usrObject$: Observable<GenericUser | null>

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public userSvc: UserSpecificService,
    private cookieService: CookieService,
    private store: Store<AppState>) 
  {
    
    this.currentLogedInUsrRole$ = this.store.pipe(select(selectLoggedInUserRoles));

    // Reactive function to check if roles include `mxp_ActivityHost` or `mxp_EventHost`
    this.isActivityOrEventHost$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => 
        roles.includes('mxp_ActivityHost') || roles.includes('mxp_EventHost')
      )
    );

    // Map roles to a boolean indicating if the dashboard can be viewed
    this.canViewDashboard$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => roles.includes('mxp_ActivityHost'))
    );
    this.canViewEventDashboard$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => roles.includes('mxp_EventHost'))
    );


    this.canViewActivityPromotionDashboard$ = this.currentLogedInUsrRole$.pipe(
      map((roles: string[]) => roles.includes('mxp_ActivityPromoter'))
    );

    this.router.events.subscribe((event) => 
    {
      if (event instanceof NavigationStart) 
      {
        console.log('Route changed to:', event.url);

        localStorage.setItem('currentRoute', event.url);

        this.onActivitiesPageChecker(event.url);

      }

    
    });


    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));

    //user subscription
    this.currentUserSubscriptions$ = this.store.pipe(select(selectLoggedInUserSubscriptions));
    this.currentUserSubscriptionsLoading$ =  this.store.pipe(select(selectLoggedInUserSubscriptionsLoading))
    this.currentUserSubscriptionsLoaded$ =  this.store.pipe(select(selectLoggedInUserSubscriptionsLoaded))
    
    //subscription tier
    // this.SubscriptionTier$ = this.store.pipe(select(selectUserSubscriptionTier));

  
   
  }

  ngOnInit()
  {
    const storedRoute = localStorage.getItem('currentRoute');
    if (storedRoute) {
      this.onActivitiesPageChecker(storedRoute);
      console.log('Current Route (on reload):', storedRoute);
    }

    //trigger a check to see if the user is logged in
    this.userSvc.isUserLoggedIn  

    this.userSvc.loggedIn$.subscribe((loggedIn) => {
      if(!loggedIn)
      {
        this.store.dispatch(GET_CURRENT_USER_ROLES());
      }
    });
    

    //subscription related
    //1. populate subject
    const subTiersObserver = {
      next: async (data: any) => {
        console.log(`All subscription tiers retrieved. Response: ${JSON.stringify(data)}`);
        
        // Populate the BehaviorSubject with the data
        this.allSubscriptionTiersSubject.next(data); // Update the value

         // Dispatch actions for fetching user subscriptions, active subsction and its tier
        this.usrObject$
        .pipe(
          tap((user: GenericUser | null) => {
            if(!user)
            {
              this.store.dispatch(GET_CURRENT_USER());
            }
            else{
              console.log(`User found ${JSON.stringify(user)}. Fetching subscriptions...`);
              
              //active sub
              this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTIONS({ userId: user?.Id! }));
            }
          }),
          switchMap(() => this.currentUserSubscriptions$), // Chain to subscription logic
          distinctUntilChanged(), // Optional: Avoid unnecessary duplicate emissions
          tap((usrSubs: UserSubscription[] | null) => {
            if (!usrSubs) {
              console.log(`No subscriptions found.`);
            } else {
              console.log(`Current user subscriptions: ${JSON.stringify(usrSubs)}`);
              
              // WARNING: HIGER ORDER SUBSCRIPTION (usrSubs) to get activities for one user
              const activeSub = usrSubs.find((x: UserSubscription) => x.IsActive);
              
              this.store.dispatch(GET_CURRENT_USER_SUBSCRIPTION_TIER({ tierId: activeSub?.TierId ?? 0 }));
              
            }
          }),
          distinctUntilChanged(), // Optional: Avoid duplicate emissions for tier

          tap(subTier => {
            if (!subTier) {
              console.log(`No subscription tier found.`);
            } else {
              console.log(`User subscription tier: ${JSON.stringify(subTier)}`);
              //If we found subscription but no tier then there is a big problem
            }
          })
        )
        .subscribe()
      },
      error: (err: HttpErrorResponse) => 
      {
        console.log(`Error fetching All subscription tiers: ${JSON.stringify(err.error)}`);

      }
    }

    //get all subscription tiers
    this.authService.GetAllSubscriptionsForMaiXp()
    .subscribe(subTiersObserver)


    // 2.

    this.combinedSubscriptionTiers$ = this.currentUserSubscriptions$
    .pipe(
      combineLatestWith(this.allSubscriptionTiers$), // Use combineLatestWith instead of combineLatest
      map(([usrSubs, allTiers]) => {
        if (!usrSubs || !allTiers) {
          return [];
        }
  
        return usrSubs.map((sub) => {
          // Find the matching tier for the current subscription
          const matchedTier = allTiers.find((tier) => tier.Id === sub.TierId);
          return { userSubscription: sub, tier: matchedTier }; // Combine subscription with matched tier
        });
      }),
      distinctUntilChanged() // Optional: To avoid unnecessary re-emissions if the combined result doesn't change
    );


    this.currentUserSubscriptions$.subscribe((dtMb: any) => {
      console.log(`Usr Subs: ${JSON.stringify(dtMb)}`)
    })


    // this.SubscriptionTier$.subscribe((dtMb: any) => {
    //   console.log(`Sub tiers: ${JSON.stringify(dtMb)}`)
    // })
  }

  closeSubmode(){
    this.showSubModeToolbar$.next(false);
  }


  toggleActivityFilter()
  {
    this.activitiesFilterToggled = !this.activitiesFilterToggled;
  }
  
  onActivitiesPageChecker(pageUrl: string)
  {
    this.onActivitiesPage = (pageUrl.includes('activitylist')) ?
            true : false;

    if(!environment.production)
    {
      console.log(`==> On activities page: ${this.onActivitiesPage}`);
    }
          
  }

  goToDashboard(){
    this.router.navigate(['']);
  }

  goToHostDashboard(){
    this.router.navigate(['activityhost']);
    
  }

  goToEventHostDashboard() {
    this.router.navigate(['eventhost']);

  }

  goToActivityPromotersDashboard() 
  {
    this.router.navigate(['APDashboard']);
  }
  

  goToProfile()
  {
    this.router.navigate(['profile']);
  }

  goToLogin(){
    this.router.navigate(['login']);
  }

  goToRegister(){
    this.router.navigate(['signup']);
  }

  logOff()
  {
    console.log('Logging out user and resetting state...');
    
    console.log('Dispatching RESET_LOGGED_IN_USER:', RESET_LOGGED_IN_USER);
    // RESET_LOGGED_IN_USER()
    this.store.dispatch(RESET_LOGGED_IN_USER());
    
    this.userSvc.logout().subscribe((response: any) => {
      
      if(
          !environment.production && 
          !environment.qa &&
          !environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'localhost')
        .then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        })
        .then(() => {
          this.toastr.success("You have been logged out from local.", "logout mesage")
        });
      }
      else if(environment.dev)
      {
        this.deleteCookie('CustomAuthCookie', '/', '.maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        }).then(() => {
          this.toastr.success("You have been logged out from DEV.", "logout mesage")
        });
      }
      else if(environment.production)
      {
        this.deleteCookie('CustomAuthCookie', '/', 'maixperience.co.za').then(() => {
          this.router.navigate(['/login']); // Redirect to the login page
        })
        .then(() => {
          this.toastr.success("You have been logged out.", "logout mesage")
        });
      }
      
      // Dispatch an action to clear the store
      
      // this.cookieService.delete('CustomAuthCookie', '/', '.maixperience.co.za'); // Specify the domain when removing the cookie
      if(!environment.production)
      {
        console.log(`Logout response: ${JSON.stringify(response)}`)
      }

      
    });
  }

  deleteCookie(name: string, path: string, domain: string): Promise<void> {
    return new Promise((resolve) => {
      this.cookieService.delete(name, path, domain);
      resolve(); // Resolve the promise immediately after deleting the cookie
    });
  }
}
