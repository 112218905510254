import { ActionReducer, MetaReducer, Action } from '@ngrx/store';
import { RESET_LOGGED_IN_USER } from '../actions/current-user.actions';
// import { RESET_LOGGED_IN_USER } from '../actions/user.actions'; // Path to your actions file

// Meta-reducer to reset the entire store state
export function resetStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: Action) => {
    if (action.type === RESET_LOGGED_IN_USER.type) {
      console.log('Meta-reducer: Resetting store state...');
      return {}; // Reset the store to an empty object
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [resetStateMetaReducer];
