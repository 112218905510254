<div class="container">

    <ng-container>
        <div class="text-center">
    
          <button 
            style="border-radius: 40px; margin: 10px 0px 0px 0px;"
            class="btn btn-outline-primary">
            promotable events
          </button>
        </div>
    </ng-container>

    <hr>

    <section *ngIf="eventsOpenForPromotion && (eventsPromoting$ | async) as eventsPromoting">
        <!-- {{activitiesPromoting | json}} -->
        <ng-container *ngIf="eventsOpenForPromotion!.length > 0">


            <a *ngFor="let evnt of eventsOpenForPromotion">
                <app-card 
                    [entity]="evnt"
                    [isEvent]="true" 
                    [showEventPromoButton]="showEventPromoButton"
                    [showBookingButtonFromEventPromotion]="showBookingButtonFromEventPromotion"
                    [EventPromoterUserId]="eventPromoterUserId"
                    [loggedInUser]="usrObjectInp">
                </app-card>

                
                <br>        
            </a>

        </ng-container>

        <ng-container *ngIf="eventsOpenForPromotion!.length == 0">
            <div 
            style="text-align: center; color: red;">
                Region has 0 promotable events
            </div>
        </ng-container>
    </section>
</div>