import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { ActivityService } from "src/app/shared/services/activity.service";
// import * as CurrentUserActivitiesActions from "../actions/current-user-activities.actions";
import { Activity } from "src/app/types/models/activity.model";
import { environment } from "src/environments/environment";
import * as ActivePromoterActions from "../actions/promoter-users.actions";
import { UserSpecificService } from "src/app/shared/services/dataService/user-specific.service";
import { GenericUser } from "src/app/types/models/genericuser.model";

@Injectable()
export class CurretActivePromoterEffects {

  pageNumber: number = 1;
  pageSize: number = 50;

  loadActivePromoters$ = createEffect(() => this.actions$.pipe(
      ofType(ActivePromoterActions.LOAD_PROMOTERS),
      mergeMap(() => this.userService.GetMxpPromotersPaginated(this.pageNumber, this.pageSize, environment.AppBoundIdentifier)
      .pipe(
          tap((activepromoterusers: GenericUser[]) => {

            if(!environment.production)
            {
              console.log(`Promoters retrieved. Page Number - ${this.pageNumber} Page Size - ${this.pageSize}. Result ${JSON.stringify(activepromoterusers)}`);
            }
          }),
          map(activepromoterusers => ActivePromoterActions.LOAD_PROMOTERS_SUCCESS({ activepromoterusers })),
          catchError(error => of(ActivePromoterActions.LOAD_PROMOTERS_FAIL({ error })))
      ))
      )
  );


  constructor(
    private actions$: Actions,
    private userService: UserSpecificService
  ) {}
}