import { createReducer, on } from "@ngrx/store";
import * as SubscriptionTierActions from "../actions/current-user-subscription-tier.actions";
import { UserSubscriptionTierState } from "../state/current-user-subscription-tier.state";

const initialState: UserSubscriptionTierState = {
    usersubscriptiontier: null,
    loading: false,
    loaded: false,
    error: null,
};

const _userSubscripitonTierReducer = createReducer(
    initialState,
    on(SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER, state => ({ ...state, loading: true })),
    on(SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER_SUCCESS, (state, { usersubscriptiontier }) => ({
        ...state,
        usersubscriptiontier,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(SubscriptionTierActions.GET_CURRENT_USER_SUBSCRIPTION_TIER_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function userSubscripitonTierReducer(state: any, action: any) {
    return _userSubscripitonTierReducer(state, action);
}