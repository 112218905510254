import { createAction, props } from "@ngrx/store";
import { SubscriptionTier } from "src/app/types/models/SubscriptionRelated/SubscriptionTier.model";


export const GET_CURRENT_USER_SUBSCRIPTION_TIER = createAction('[Generic User Subscription Tier] Get Current Logged In User Subsctiption Tier',
    props<{ tierId: number }>()
);

export const GET_CURRENT_USER_SUBSCRIPTION_TIER_SUCCESS = createAction('[Generic User Subscription Tier] Get Current Logged In User Subsctiption Tier Success',
    props<{usersubscriptiontier: SubscriptionTier }>());

export const GET_CURRENT_USER_SUBSCRIPTION_TIER_FAIL = createAction('[Generic User Subscription Tier] Get Current Logged In User Subsctiption Tier Fail',
    props<{error: string}>());