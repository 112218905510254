import { createAction, props } from "@ngrx/store";
import { UserSubscription } from "src/app/types/models/SubscriptionRelated/UserSubscription.model";


export const GET_CURRENT_USER_SUBSCRIPTIONS = createAction('[Generic User Subscription] Get Current Logged In User Subsctiption',
    props<{ userId: string }>()
);

export const GET_CURRENT_USER_SUBSCRIPTIONS_SUCCESS = createAction('[Generic User Subscription] Get Current Logged In User Subsctiption Success',
    props<{currentloggedinusersubscription: UserSubscription[] }>());

export const GET_CURRENT_USER_SUBSCRIPTIONS_FAIL = createAction('[Generic User Subscription] Get Current Logged In User Subsctiption Fail',
    props<{error: string}>());