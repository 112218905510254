<div class="container">

    <!-- main definition -->
    <h2 style="margin-top: 8px;">
        # Promoters
    </h2>

    <hr>
      
    <p>
        A promoter is an individual or entity responsible for marketing and 
        independently promoting specific activities or events available on 
        the platform to attract customers.

    </p>

    <!-- who can create a new activity -->
    <h2>
        # Who can become a promoter
    </h2>
    <hr>

    <div>
        An "Activity Promoter" or "Event Promoter" role is open to individuals,
        influencers, content creators, or businesses passionate about event promotion.
    </div>

    <br><br><br>
    <!-- how to apply -->
    <h2>
        # How to apply as promoter
    </h2>
    <hr>
  
    <div style="margin-top: 30px;"> 
        <ol class="my-list">
            <li tabindex="1">
                Sign up on the platform.
            </li>
            <li tabindex="2">
                Navigate to profile > roles
            </li>
            <li tabindex="3">
                Enable the promoter role.
            </li>
            <li tabindex="4">
                Navigate to profile > promote activities to view/apply to activities open for promotions
            </li>
            <li tabindex="5">
                Navigate to profile > promote events to view/apply to events open for promotions
            </li>
            <li tabindex="6">
                Use the unique QR Code in the home tab of profile page to promote (NB: This page will only show activities and events that approved promotion application(s)) 
            </li>
        </ol>
    </div>



     <!-- activity promoters benefit -->
     <h2>
        # Promoter's note
    </h2>
    
    <hr>

    <div>
        Start earning while promoting exciting activities/events on Maixperience. Apply to be a promoter today!
    </div>
</div>