import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild,  AfterViewInit, HostListener,Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatestWith, filter, map, pairwise, shareReplay, switchMap, take, tap, merge, of, catchError, takeUntil, Subject, first } from 'rxjs';
import { GET_CREATORS_OF_ACTIVE_ACTIVITIES } from 'src/app/ngrx/actions/active-activity-creators.actions';
import { GET_ACTIVE_ACTIVITIES } from 'src/app/ngrx/actions/active-activity.actions';
import { selectActiveActivityCreators, selectActiveActivityCreatorsLoaded } from 'src/app/ngrx/selectors/active-activity-creators.selectors';
import { selectActiveActivitiesWithPagination, selectActiveActivityError, selectActiveActivityLoaded, selectActiveActivityLoading } from 'src/app/ngrx/selectors/active-activity.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivitySlotService } from 'src/app/shared/services/activity-slot.service';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ImageServiceService } from 'src/app/shared/services/image-service.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { PerformerclassService } from 'src/app/shared/services/performerclass.service';
import { Activity } from 'src/app/types/models/activity.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent {
  selectedActivitySlot: any;
  activityPCs: any;
  actvtyAddr: any;
  // rtImgPath: string = "http://localhost:808/";
  rtImgPath: string = environment.fileRetriever;
  
  imgs$!: Observable<string[]>; 


  selectedImageUrls: string[] = [];
  
  locationResponse: any;
  
  previousRoute: any

  currentIndex: number = 0;
    
  activeActivityStatus: number = 0;

  startX: number = 0;
  startY: number = 0;
  
  pageNumber: number = 1;
  pageSize: number = 100;
  
  activeActivitiesPaginated$:  Observable<Activity[]>
  activeActivitiesCreators$: Observable<GenericUser[]>
  loading$: any
  loaded$: any
  error$: any

  
  // selectedActivity!: Observable<Activity | undefined>  
  private readonly destroy$ = new Subject<void>();

  activitySelect: Activity | undefined;
  activitySelectUsr: GenericUser | undefined
  
  activitySelect$!: Observable<Activity | undefined>;
  activitySelectUsr$!: Observable<GenericUser | undefined>;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private obfuscationService: ObfuscationServiceService,
    private dialogSvc: DialogService,
    private addrService: AddressService,
    private pcService: PerformerclassService,
    private activitySlotService: ActivitySlotService,
    private lociService: AddressService,
    private userSvc: UserSpecificService,
    private authSvc: AuthService,
    private activityService: ActivityService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private location: Location)
  {
    // get selected activity from store
    this.activeActivitiesPaginated$ = this.store.pipe(select(selectActiveActivitiesWithPagination));
    this.activeActivitiesCreators$ = this.store.pipe(select(selectActiveActivityCreators))
    this.loading$ = this.store.pipe(select(selectActiveActivityLoading));
    this.loaded$ = this.store.pipe(select(selectActiveActivityLoaded));
    this.error$ = this.store.pipe(select(selectActiveActivityError));
  
  }

  ngOnInit()
  {
    // ??? 
    this.store.pipe(
      select(selectActiveActivityLoaded),
      first() // Complete the observable after the first emission
    ).subscribe((loaded) => {
      if (!loaded) {
        this.store.dispatch(GET_ACTIVE_ACTIVITIES({
          statusCode: this.activeActivityStatus,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }));
      }
    });

    //get activity ID from route param
    const activityId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('activityId')),
      filter(activityId => !!activityId)
    );

    // get active activities
    const activeActivities$ = this.activeActivitiesPaginated$.pipe(
      filter(activeActivities => activeActivities.length > 0),
      first() // Complete the observable after the first emission
    );

    //get selected activityId + active activities
    this.activitySelect$ = activityId$.pipe(
      combineLatestWith(activeActivities$),
      switchMap(([activityId, activeActivities]) => {
        // decrypt the encrypted activity id
        const decryptedId = this.obfuscationService.decryptEventId(activityId!);

        //find active activity
        let selectedActivity = activeActivities.find(activeActivity =>
          activeActivity.Id?.toString() === decryptedId
        );

        // when we find the selected activity from active activities....
        if(!selectedActivity && decryptedId){
          // Fetch the specific activity by ID if not found in the current page
          return this.activityService.GetActivityById(parseInt(decryptedId!)).pipe(
            tap(activity => {
              this.activitySelect = activity;
              
              // Dispatch action to get creators of the active activities
              const userIds = activeActivities.map(a => a.UserId).concat(activity.UserId);
              this.store.dispatch(GET_CREATORS_OF_ACTIVE_ACTIVITIES({ arrayOfUserId: userIds }));

              this.extractLocation();
            }),
            map(activity => activity)
          );
        }
        else {
          this.activitySelect = selectedActivity;
          const userIds = activeActivities.map(activity => activity.UserId);
          this.store.dispatch(GET_CREATORS_OF_ACTIVE_ACTIVITIES({ arrayOfUserId: userIds }));
          
          this.extractLocation();

          return of(selectedActivity);
        }
      })
    );

    //split the images
    this.imgs$ = this.activitySelect$.pipe(
      map(selectedActivity => selectedActivity?.Images?.split(',') || [])
    );

    this.activitySelectUsr$ = this.activitySelect$.pipe(
      switchMap(selectedActivity => this.store.pipe(
        select(selectActiveActivityCreators),
        filter(users => users.length > 0), // Adjust the condition based on your state shape
        map(users => users.find(user => user.Id === selectedActivity?.UserId))
      ))
    );

  }



  FireActivityDetailsProcessAfterInit(activitySelect: Activity)
  {
    //get the location of the activity (It must have a location if it is visible)
    this.lociService.GetAddressByAddressId(activitySelect?.AddressId!)
    .subscribe((data: any) => {
      this.actvtyAddr = data;


      if(!environment.production)
      {
        console.log(`Activity address retrieved: ${JSON.stringify(this.actvtyAddr)}`);
      }
    })
    
  }


  backToActivityList()
  {
    this.router.navigate(['activitylist'])
  }


  extractLocation()
  {
    const addrRetrieverObserver = {
      next: async (response: any) =>  {
        this.locationResponse = response;

        if(!environment.production)
        {
          console.log("location retrieved successfully:" + JSON.stringify(this.locationResponse));
        }
          
           
      },
      error: (err: HttpErrorResponse) => console.log(err),
      complete: () =>{
        if(!environment.production)
        {
          console.log("Add activity COMPLETE");
        }
      }
    }

    //get the location of the activity
    this.addrService.GetAddressByAddressId(this.activitySelect?.AddressId!)
      .subscribe(addrRetrieverObserver);
        
  }

  locationCheck(e: any)
  {
    if(!environment.production)
    {
      console.log("location event :" + this.activitySelect?.AddressId);
    }

    this.dialogSvc.ShowLocationDialog(this.locationResponse);    
        
    // alert("View location of an activity by anyone")
    //this prevents the parent routing click action to kickoff
    e.stopPropagation();
  }

  BookActivity()
  {
    //compose object of activity to be booked
    let act2beBooked = {
      Details: this.activitySelect,
      Slot: this.selectedActivitySlot,
      PerformerClasses: this.activityPCs,
      ActivityLoci: this.locationResponse
    }

    if(!environment.production)
    {
      console.log(`Activity being booked: ${JSON.stringify(act2beBooked)}`)
    }

    //observer for token validation against server
    const tknValidationObserver = {
      next: async (response: any) => {
        if(!environment.production)
        {
          console.log(`Token validation from server response: ${JSON.stringify(response)}`);
        }

        // when token comes back as valid, then open the booking dialog
        this.dialogSvc.ActivityBookingDialog(act2beBooked)
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Error response from token validation: ${JSON.stringify(err)}`);
        }
        
        if(err.url) //if err response is trying to navigate to another page, then it has expired
        {
          //remove the cookie 
          this.userSvc.logout()

          //show the user some error
          this.toastr.error(
            "Session ended. Login", "Error");
        }
      },
      complete: async () => {
        if(!environment.production)
        {
          console.log(`user http request complete!`);
        }
      }
    }

    
    const actvyBookingObserver = {
      next: async (response: any) => {

        //ensure that we get a response: true (this means locally, the token is intact)
        if(!environment.production)
        {
          console.log(`$$$ Response from activity booking prep.: ${JSON.stringify(response)}`)
        }

        //validate the token against the server (if any)
        if(response)
        {
          this.authSvc.ValidateUserCookie().subscribe(tknValidationObserver)
        }
        else{
          //no cookie to validate
          this.toastr.error("Login to book", "Authentication Error")

          //redirect to the login page
          // THE ACTIVIT DETAIL IS NOW SELF AWARE (based on activity id) No need to pass the entire object
          this.router.navigate(['login'],
          {
            queryParams: {
              redirectUrl: 'activitydetails',
              data: JSON.stringify(this.activitySelect), // Serialize the object to a JSON string
              act2be: JSON.stringify(act2beBooked)
            }
          });
        }
      },
      error: async (err: HttpErrorResponse) => {
        if(!environment.production)
        {
          console.log(`Activity Booking error response: ${JSON.stringify(err)}`);
        }
      },
      complete: async () => {
        if(!environment.production)
        {
          console.log(`http request for activity booking prep complete!`);
        }
      }
    }

    //determine if user is logged in (Local cookie check)
    this.userSvc.isUserLoggedIn.pipe(take(1))
    .subscribe(actvyBookingObserver);

    // this.userSvc.loggedIn$
    // .subscribe(actvyBookingObserver);
  }

  InDevMsg()
  {
    this.toastr.warning("Feature In development", "status");
  }


  title: any;

  paymentDone(ref: any) {
    this.title = 'Payment successfull';
    if(!environment.production)
    {
      console.log(this.title, ref);
    }
  }

  paymentCancel() {
    if(!environment.production)
    {
      console.log('payment failed');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

    
  ViewAdminImage(e: any, creatorImg: string)
  {
    //make sure creator object is not empty
    //if profile isnt empty send image if not use a default
      
    let AdminImageUrl: string[] = [];

    if(creatorImg)
    {
      if(!environment.production){
        console.log(`Extracted Image Url of Admin : ${creatorImg}`);
      }
      
      AdminImageUrl.push(creatorImg) 

      this.dialogSvc.ShowImgDialog(AdminImageUrl);
    } 

    AdminImageUrl = [] ;
    
    e.stopPropagation();
  }

  ViewImage(e: any, imageUrls: string[], index:number)
  {
    if(!environment.production){
      console.log(`Extracted Image Url of Activity: ${imageUrls}`);
      
    }
    this.selectedImageUrls = imageUrls;

    this.dialogSvc.ShowImgDialog(this.selectedImageUrls,index);

    e.stopPropagation();
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
    if(!environment.production)
    {
      console.log("this curr index " + this.currentIndex)
    }
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.selectedImageUrls.length;
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.selectedImageUrls.length) % this.selectedImageUrls.length;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;
    const deltaX = endX - this.startX;
    const deltaY = endY - this.startY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        this.prev();
      } else {
        this.next();
      }
    } else if (Math.abs(deltaY) > Math.abs(deltaX) && Math.abs(deltaY) > 50) {
      if (deltaY > 0) {
        this.closeDialog();
      }
    }
  }
  closeDialog() {
    this.dialogSvc.closeDialog();
  }

  share(currUrl: any){
    currUrl = this.router.url;
    this.dialogSvc.ShowShareDialog(currUrl)
  }
}
