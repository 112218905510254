import { createReducer, on } from "@ngrx/store";
import * as UserSubscriptionActions from "../actions/current-user-subscription.actions";
import { UserSubscriptionState } from "../state/current-user-subscription.state";

const initialState: UserSubscriptionState = {
    currentloggedinusersubscription: null,
    loading: false,
    loaded: false,
    error: null,
};

const _currentLoggedinUserSubscripitonReducer = createReducer(
    initialState,
    on(UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS, state => ({ ...state, loading: true })),
    on(UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS_SUCCESS, (state, { currentloggedinusersubscription }) => ({
        ...state,
        currentloggedinusersubscription,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
);

export function currentLoggedinUserSubscripitonReducer(state: any, action: any) {
    return _currentLoggedinUserSubscripitonReducer(state, action);
}