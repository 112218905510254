import { EventPromotionAcceptanceStatus } from "../enums/eventPromotionAcceptanceStatus.enum";

export class EventPromotersLog
{

  constructor(
    public Id: number,
    public EventId: number,
    public PromoterId: string,
    public AcceptanceStatus: EventPromotionAcceptanceStatus,
    public IsEventLogRemoved: boolean,
    public EventCreationDateTime: string,
    public EventStatusLastUpdatedDateTime: string,
    public UserUpdating: string //optional
  ) {}
  
}