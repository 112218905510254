<div class="container">
    <div 
    class="row text-center"
    style="margin-top: 10px;">
        <div class="col-12">
            <span (click)="goToEvents()" style="float: left;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
            </span>
    

          <button 
            style="border-radius: 40px;"
            class="btn btn-outline-primary">
            creating event ...
          </button>

        </div>
    
    </div>

</div>

<div style="margin-top: 25px;" class="container">

  <input hidden id="duration" value="1500" type="number" min="0" step="100" #duration>
      
  <mat-vertical-stepper 
    [linear]="true" 
    #stepper 
    [animationDuration]="duration.value">

    <!-- event details  -->
    <mat-step [stepControl]="eventFormGroup">
      <ng-template matStepLabel>Event details</ng-template>
      <br>

      <form 
        [formGroup]="eventFormGroup"
        style="text-align: center;">
        
        <!-- user ID -->
        <mat-form-field 
        [hidden]="true" 
        style="width: 100%;">
          <input 
            matInput 
            placeholder="User ID" 
            formControlName="UserId" 
            required>
        </mat-form-field>

        <!-- name -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput 
            placeholder="event name" 
            formControlName="Name" 
            required>
        </mat-form-field>

        <!-- description -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput 
            placeholder="event description" 
            formControlName="Description" 
            required>
        </mat-form-field>

        <!-- price per person -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput 
            placeholder="price per person (ZAR)" 
            formControlName="PricePerPerson" 
            required>
        </mat-form-field>

        <!-- MaxNumberOfInviteesForEvent (max capacity) -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput 
            placeholder="max capacity of venue (eg: 10)" 
            formControlName="MaxNumberOfInviteesForEvent" 
            required>
        </mat-form-field>

        <!-- EventStartDate -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput
            [matDatepicker]="esdPicker"
            [min]="getCurrentDate()"
            placeholder="event start date" 
            (dateChange)="onStartDateChange()"
            formControlName="EventStartDate" 
            required>
            <mat-datepicker-toggle matIconSuffix [for]="esdPicker"></mat-datepicker-toggle>
            <mat-datepicker #esdPicker ></mat-datepicker>
        </mat-form-field>
            
        <!-- Custom time picker startTime-->
        <mat-form-field style="width: 100%;">
          <input
            placeholder="event start time"
            matInput 
            [ngxTimepicker]="picker"
            formControlName="EventStartTime"
            (ngModelChange)="onTimeChange($event)">
          <ngx-material-timepicker #picker></ngx-material-timepicker>
          <mat-icon matSuffix (click)="picker.open()">schedule</mat-icon>
        </mat-form-field>

        <!-- EventEndDate -->
        <mat-form-field style="width: 100%;">
          <input 
            matInput 
            [matDatepicker]="eedPicker"
            placeholder="event end date" 
            [min]="startDateVar"
            formControlName="EventEndDate" 
            (dateChange)="onEndDateChange()"
            required>
            <mat-datepicker-toggle matIconSuffix [for]="eedPicker"></mat-datepicker-toggle>
            <mat-datepicker #eedPicker></mat-datepicker>
        </mat-form-field>

        <!-- Custom time picker endTime (NB: the endtime can not be less than the starttime)-->
        <mat-form-field style="width: 100%;">
          <input
            placeholder="event end time"
            matInput 
            [ngxTimepicker]="endPicker"
            [min]="startTimeConvert"
            formControlName="EventEndTime"
            (ngModelChange)="onEndTimeChange($event)">
          <ngx-material-timepicker #endPicker [format]="24"></ngx-material-timepicker>
          <mat-icon matSuffix (click)="endPicker.open()">schedule</mat-icon>
        </mat-form-field>

        <div>
          <button 
            mat-raised-button 
            color="primary"
            matStepperNext
            (click)="activityDetailsCaptured()"
            *ngIf="this.eventFormGroup.valid"
            style="float: right;">
            Next
          </button>
        </div>

      </form>
    </mat-step>

    <!-- Images -->
    <mat-step>
      <ng-template matStepLabel>Images</ng-template>
      
      <form>
    
        <!-- before files gets selected -->
        <ng-container 
          *ngIf="selFiles.length < 1"
          style="margin-top: 15px;">

          <app-image-upload
            [showProgress]="false"
            [maxFiles]="numberOfUploadableImages"
            [maxSizeMB]="imgSelectMaxSize"
            (filesUploaded)="onFilesUploaded($event)"> 
              
              <label 
                  style="width: 100%;"
                  class="imgLabel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
                      <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  <br><br><br>
                  Upload image(s) of activity
              </label> 

          </app-image-upload>
      
        </ng-container>

        <!-- when files gets selected -->
        <div *ngIf="selFiles.length > 0">
          <!-- Card deck -->
          <div class="card-deck row">

              <div 
              class="col-xs-12 col-sm-6 col-md-4" 
              *ngFor="let x of selFiles">
                  <!-- Card -->
                  <div class="card">

                    <!--Card image-->
                    <div class="view overlay">
                      <img 
                        class="card-img-top" 
                        src={{rtImgPath}}{{x}}
                        alt="x">
                      <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                      </a>

                      <span 
                        (click)="removeSelection(x)"
                        class="removeSpan" 
                        style="float: right; color: rgb(255, 0, 0);">
                      
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </span>
                    </div>

                  </div>
                  <!-- Card -->
              </div>

              <!-- button to append more image(s) -->
              <div class="col-xs-12 col-sm-6 col-md-4">
                <app-image-upload
                  [showProgress]="false"
                  [maxFiles]="3"
                  [maxSizeMB]="imgSelectMaxSize"
                  (filesUploaded)="onAppendFilesUploaded($event)"> 
                  
                  <button 
                  class="btn btn-outline-primary"
                  style="margin-top: auto; margin-bottom: auto; margin: 10px; padding: 16px; border-radius: 20px; color: white; background-color: rgb(72, 5, 139);">

                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-node-plus" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8M6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5M1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                    </svg>
                </button>


                </app-image-upload>

                <!-- <div 
                class="card border-0 d-flex flex-column justify-content-center align-items-center"
                style="height: 100%; ">
                  <button 
                    class="btn btn-outline-primary"
                    (click)="appendFileSelection()"
                    style="margin-top: auto; margin-bottom: auto; margin: 10px; padding: 16px; border-radius: 20px; color: white; background-color: rgb(72, 5, 139);">

                      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-node-plus" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8M6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5M1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                      </svg>
                  </button>
                </div> -->

              </div>
          </div>
          <!-- Card deck -->
    
        </div>

        <br>

        <!-- PREVIOUS AND NEXT BTNS -->
        <div>
            <button 
                mat-raised-button 
                color="primary" 
                matStepperPrevious
                style="float: left;">
                Back
            </button>
            <button 
                mat-raised-button 
                color="primary" 
                *ngIf="this.selFiles.length > 0"
                matStepperNext
                style="float: right;">
                Next
            </button>
        </div>
      </form>
    
    </mat-step>  

    <!-- location -->
    <mat-step [stepControl]="thirdFormGroup">
      <ng-template matStepLabel>Location</ng-template>

      <div>
        <app-map (addressSelected)="onAddressSelected($event)"></app-map>
      </div>
 
      <br>
      <!-- PREVIOUS AND NEXT BTNS -->
      <div>
        <button 
            mat-raised-button 
            color="primary" 
            matStepperPrevious
            style="float: left;">
            Back
        </button>

        <button 
          mat-raised-button 
          color="primary" 
          *ngIf="anAddressIsSelected"
          matStepperNext
          style="float: right;">
          Next
        </button>

        <!-- <button 
            mat-raised-button 
            color="primary" 
            *ngIf="anAddressIsSelected"
            (click)="completeEventAdd()"
            style="float: right;">
            Complete
        </button> -->
      </div>
    </mat-step> 
    
    
  <!-- settings (event promotion...) -->
  <mat-step>
    <ng-template matStepLabel>Event Preferences</ng-template>
    <br>

    <ng-container>

      <!-- enable promoter -->
      <div class="row mb-3">
          <div>
              <label 
                  for="inputPassword6" 
                  class="col-sm-2 col-form-label col-form-label-sm">
                  Enable Promotion
                  <span type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                      <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                      </svg>
                  </span>
                  :
              </label>
  
              <!-- when promotion is not enabled -->
              <span 
                  class="col-sm-10"
                  *ngIf="!eventModel.EnablePromotion"
                  (click)="toggleAppendPromotion()"
                  style="text-align: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(225, 42, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                      <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                  </svg>
              </span>
  
              <!-- when promotion is enabled -->
              <span 
                  class="col-sm-10"
                  *ngIf="eventModel.EnablePromotion"
                  (click)="toggleAppendPromotion()"
                  style="text-align: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(111, 241, 42);" width="36" height="26" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                      <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                  </svg>
              </span>
          </div>
      </div>
     

      <!-- promotion percentage setting -->
      <div 
        *ngIf="eventModel.EnablePromotion"
        class="row mb-3">

        <label 
          for="promoPercentage" 
          class="col-sm-2 col-form-label col-form-label-sm">
          Set Promo %
          <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(56, 56, 211);" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
          </svg>
          :
        </label>
          

        <!-- show percentage update when promotion is enabled -->
        <span class="col-sm-10">
            <input 
                type="number" 
                id="promoPercentage" 
                [(ngModel)]="this.eventModel.EventPromotionPercentage"
                class="form-control form-control-sm"
                aria-describedby="passwordHelpInline"
                required
                style="width: 100%;">
                <small>minimum percentage: 5%</small>
              
        </span>

      </div>

      <!-- PREVIOUS AND NEXT BTNS -->
      <div>
        <button 
            mat-raised-button 
            color="primary" 
            matStepperPrevious
            style="float: left;">
            Back
        </button>

        <button 
            mat-raised-button 
            color="primary" 
            (click)="completeEventAdd()"
            style="float: right;">
            Complete
        </button>
    </div>
    </ng-container>

  </mat-step>

  </mat-vertical-stepper>  
</div>













