<div 
    class="container">

    <h1 mat-dialog-title>
        Add Subscription Tier
    </h1>
    <hr>
    <div mat-dialog-content>
        <form 
        [formGroup]="subTierForm"
        (ngSubmit)="submitSubTierAdd()">

        <!-- Subscription Tier Name -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">TierName
            </label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="TierName"
                id="TierName"
                required=""
                placeholder="enter tier name">
        </div>


        <!-- MaxActivitiesPerMonth -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Max. Activities Per Month
            </label>
            <input 
                type="number" 
                class="form-control" 
                formControlName="MaxActivitiesPerMonth"
                id="MaxActivitiesPerMonth" 
                required="">
        </div>

        <!-- MaxEventsPerMonth -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Max. Events Per Month
            </label>
            <input 
                type="number" 
                class="form-control" 
                formControlName="MaxEventsPerMonth"
                required=""
                id="MaxEventsPerMonth">
        </div>

         <!-- MaxEventPromoters -->
         <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Max. Activity Promoter
            </label>
            <input 
                type="number" 
                class="form-control" 
                formControlName="MaxActivityPromoters"
                id="MaxActivityPromoters" 
                required="">
        </div>

        <!-- MaxEventPromoters -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Max. Event Promoters
            </label>
            <input 
                type="number" 
                class="form-control" 
                formControlName="MaxEventPromoters"
                required=""
                id="MaxEventPromoters">
        </div>

        <!-- Price -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">Price
            </label>
            
            <input 
                type="number" 
                class="form-control"
                formControlName="Price" 
                id="Price" 
                required>
        </div>

        <!-- plan interval -->
        <div class="mb-3">
            <label 
                for="subscription" 
                class="form-label">
                Subscription Type
            </label>

            <select 
                class="form-select" 
                id="subscription" 
                name="subscription"
                formControlName="planInterval">

                <option value="" disabled selected>-- Select Interval --</option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
            </select>
        </div>

        <!-- AdditionalFeaturesJson -->
        <div class="mb-3">
            <label 
            for="exampleFormControlInput1" 
            class="form-label">AdditionalFeatures
            </label>
            <input 
                type="text" 
                class="form-control" 
                formControlName="AdditionalFeatures"
                id="AdditionalFeatures">
            </div>


            <button 
                mat-raised-button
                color="primary"
                type="submit" 
                [disabled]="subTierForm.invalid">
                Submit
            </button>
        </form>
    </div>

    
    

    
    <hr>
    <div mat-dialog-actions align="end">
        <!-- <button 
            mat-raised-button
            (click)="addUser()"
            color="primary">
            Submit
        </button> -->
        <button 
            mat-raised-button
            color="accent" 
            mat-dialog-close>Close</button>
    </div>


</div>