import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../CustomAuthenticator/auth.service';
import { CustomRoleChange } from 'src/app/types/models/customrolechange.model';
import { CustomProfilePictureChange } from 'src/app/types/models/customprofilepicturechange.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
// import { CustomRoleChange } from 'src/app/modules/user/profile/profile.component';
// import { CustomProfilePictureChange, GenericUser } from 'src/app/modules/auth/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class UserSpecificService {
  
  private authBaseURL = environment.CustomAuthBaseUrl
  private cookieName = environment.CookieName
  private emailBaseUrl = environment.MailerBaseUrl
  
  // public loggedInSubject  = new BehaviorSubject<boolean>(false);
  public loggedInSubject  = new BehaviorSubject<boolean>(this.isLoggedIn());
  public loggedIn$ = this.loggedInSubject.asObservable();

  public tokenIsValidAgainstServer = new BehaviorSubject<boolean>(false);
  
  constructor(
    private client: HttpClient,
    private authSvc: AuthService,
    private cookieService: CookieService) 
  { 

  }

  // Check if the authentication cookie exists
  private isLoggedIn(): boolean {
    console.log(`Logged in check: ${document.cookie
      .split(';')
      .some(cookie => cookie.trim().startsWith('CustomAuthCookie='))}`);
      
    return document.cookie
    .split(';')
    .some(cookie => cookie.trim().startsWith('CustomAuthCookie='));
  }

  GetAllUsersOfAnAppByUserIds(appBound: number, usrIds : string[]) : Observable<GenericUser[]>
  {
    return this.client.post<GenericUser[]>(
      `${this.authBaseURL}UserManager/GetAllUsersOfAnAppByUserIds/${appBound}`,
      usrIds,
      { withCredentials: true });
  }

  GetTheFirst50MxpPromoters(appBound: number)
  {
    return this.client.get(
      `${this.authBaseURL}UserManager/GetTheFirst50MxpPromoters/${appBound}`,
      { withCredentials: true });
  }

  GetMxpPromotersPaginated(pageNumber: number, pageSize: number, appBound: number): Observable<any>
  {
    return this.client.get(
      `${this.authBaseURL}UserManager/GetMxpPromotersPaginated?pageNumber=${pageNumber}&pageSize=${pageSize}&appBound=${appBound}`,
      { withCredentials: true });
  }

  UserRoleManagement(customRoleChange: CustomRoleChange)
  {
    return this.client.post(
      `${this.authBaseURL}UserManager/UserRoleManagement`,
      customRoleChange,
      { withCredentials: true });
  }

  GetCurrentlyLoggedInUserIdIfExist(): Observable<any> {
    // Retrieve the cookie from document.cookie
    const cookies = document.cookie.split(';');

    if(!environment.production)
    {
      console.log(`Document Cookie: ${document.cookie} and Result of COOKIES SPLIT ${cookies}`);
    }

    let cookieValue = null;

    for (const cookie of cookies) {
      const [name, value] = cookie.split('=').map(c => c.trim());
      if (name === 'CustomAuthCookie') {
        cookieValue = value;
        break;
      }
    }

    if(!environment.production)
    {
      console.log(`cookie retrieved on authSvc -> getAllRolesAUserHas ${cookieValue}`);
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${cookieValue}`);
        
    var userReturned = this.client.post(
      `${this.authBaseURL}UserManager/DecryptCookieAndReturnUser?appBound=${environment.AppBoundIdentifier}`,
      null,
      { headers, withCredentials: true }
    )

    if(!environment.production)
    {
      console.log(`User returned ${JSON.stringify(userReturned)}`);
    }

    return userReturned;
  }

  GetUserByIdForAppByAppId(usrId : string, appBound: number) : Observable<GenericUser>
  {
    return this.client.get<GenericUser>(
      `${this.authBaseURL}UserManager/GetUserByIdForAppByAppId/${usrId}/${appBound}`,
      { withCredentials: true });
  }

  GetUserByEmailOrUsernameForAppByAppId(usernameOrEmail : string, appBound: number)
  {
    console.log(`Running URl: ${this.authBaseURL}`);
    
    return this.client.get(
      `${this.authBaseURL}UserManager/GetUserByEmailOrUsernameForAppByAppId/${usernameOrEmail}/${appBound}`,
      { withCredentials: true });
  }


  UpdateUserProfilePicture(customPPChange: CustomProfilePictureChange)
  {
    return this.client.put(
      `${this.authBaseURL}UserManager/UpdateUserProfilePicture`,
      customPPChange,
      { withCredentials: true });
  }

  UpdateUser(genericUser: GenericUser)
  {
    return this.client.put(
      `${this.authBaseURL}UserManager/UpdateUser`,
      genericUser,
      { withCredentials: true });
  }

  GetUserByIdForAppByAppName(usrId: string, appName: string)
  {
    return this.client.get(
      `${this.authBaseURL}UserManager/GetUserByIdForAppByAppName/${usrId}/${appName}`,
      { withCredentials: true });
  }

  //The isUserLoggedIn method checks if a token exists in a cookie and emits a boolean value accordingly.
  // get isUserLoggedIn(): Observable<boolean> {
  //   if(!environment.production)
  //   {
  //     console.log('checking if user is logged in')
  //   }

  //   //check if a token exists
  //   if(this.cookieService.get(this.cookieName))
  //   {
  //     if(!environment.production)
  //     {
  //       console.log(`Checking cookie with name: ${this.cookieName}`);
  //       console.log(`Checking value: ${JSON.stringify(this.cookieService.get(environment.CookieName))}`);
  //       console.log(`Cookie found}`);
  //     }
      
  //     this.loggedIn$.next(true);

  //     return this.loggedIn$.asObservable();
  //   }
  //   else
  //   {
  //     if(!environment.production)
  //     {
  //       console.log(`Cookie NOT found`);
  //     }
       
  //     return this.loggedIn$.asObservable();
  //   }
  // }


  get isUserLoggedIn(): Observable<boolean> {
    if(!environment.production)
    {
      console.log('checking if user is logged in')
    }

    
    // Subscribe to check if the user is logged in
    this.authSvc.GetCurrentlyLoggedInUserIdIfAny()
    .subscribe({
      next: (response: any) => {
        //this method can return undefined so we are checking for that
        if(response)
        {
          this.loggedInSubject.next(true);
          if (!environment.production) {
            console.log(`User logged in!`);
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        this.loggedInSubject.next(false);
        if (!environment.production) {
          console.error('Error checking login status:', err);
        }
      }
    });

     // Always return the loggedIn$ observable for subscribers
    return this.loggedInSubject.asObservable();
  }

  // Method to update the login status
  setLoginStatus(status: boolean) 
  {
    this.loggedInSubject.next(status);
  }

  getLoggedInStatus(): Observable<boolean>
  {
    return this.loggedInSubject.asObservable();
  }

  logout(): Observable<any>
  {
    this.loggedInSubject.next(false); // Set loggedIn$ to false
    // this.cookieService.deleteAll(); // Delete all cookies

    //sign out on the server
    return this.client.post(
      `${this.authBaseURL}UserManager/Logout`,
      null,
      { withCredentials: true });


  }

  contactUsMail(formData: any): Observable<any>{
    return this.client.post(
      `${this.emailBaseUrl}api/appauth/sendcustomemail`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }


  // getUserDetails(userId: string): Observable<any> {
  //   return this.http.get<User>(`${this.apiUrl}/${userId}`);
  //   return this.client.get(
  //     `${this.authBaseURL}UserManager/GetUserByIdForAppByAppName/${usrId}/${appName}`,
  //     { withCredentials: true });
  // }


}
