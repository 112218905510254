import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { selectEventsIAmPromotingWithPagination, selectEventsIAmPromotingWithPaginationError, selectEventsIAmPromotingWithPaginationLoaded, selectEventsIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/events-i-am-promoting.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { MaiXEventService } from 'src/app/shared/services/maiXEventRelated/mai-xevent.service';
import { EventPromotersLog } from 'src/app/types/models/eventPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';
import { CardComponent } from '../card/card.component';
import { LOAD_EVENTS_I_AM_PROMOTING } from 'src/app/ngrx/actions/events-i-am-promoting.actions';
import { EventStatus } from 'src/app/types/enums/eventStatus.enum';

@Component({
  selector: 'app-event-promotions',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
  ],
  templateUrl: './event-promotions.component.html',
  styleUrl: './event-promotions.component.scss'
})
export class EventPromotionsComponent {
  usrObject$: Observable<GenericUser | null>
  usrObjectGlobal: GenericUser | undefined

  eventsPromoting$: Observable<EventPromotersLog[]> | undefined
  eventsPromotingLoading$: Observable<boolean> | undefined
  eventsPromotingLoaded$: Observable<boolean> | undefined
  eventsPromotingError$: Observable<string | null> | undefined

  eventdeduction$!: Observable<any>;

  pageNumber: number = 1;
  pageSize: number = 10;


  showButtonHistoryAndEdit = false; // user does not get to view those actions here
  showEventPromoButton = false;
  showBookingButtonFromEventPromotion: boolean = true;
  eventPromoterUserId: string = '12345'
  eventPromoterUserId$!: Observable<string | null>

  eventPromoterUserIdAndEventId$: Observable<{ 
    promoterUserId: string | null, 
    eventId: string | null }> | undefined;
  

    promoterUserId: string | undefined
  eventId: string | undefined 

  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authSvc: AuthService,
    private eventService: MaiXEventService, 
    private store: Store<AppState>) 
  {
    //currently logged in user
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));


    // get the activities I am promoting from store
    this.eventsPromoting$ = this.store.pipe(select(selectEventsIAmPromotingWithPagination))
    this.eventsPromotingLoading$ = this.store.pipe(select(selectEventsIAmPromotingWithPaginationLoading))
    this.eventsPromotingLoaded$ = this.store.pipe(select(selectEventsIAmPromotingWithPaginationLoaded))
    this.eventsPromotingError$ = this.store.pipe(select(selectEventsIAmPromotingWithPaginationError))
  }

  ngOnInit()
  {
    this.eventsPromotingLoaded$?.subscribe(_ => {
      console.log(`Loaded: ${_}`)
    })

    //#region loggedInuserLogic
    this.usrObject$.pipe(
      first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          // dispatch
          this.store.dispatch(GET_CURRENT_USER());
        }
        else {
          //assign global user object
          this.usrObjectGlobal = user

          //dispatch
          // this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
          //   promoterUserId: user?.Id!,
          //   pageNumber: this.pageNumber,
          //   pageSize: this.pageSize
          // }));
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()
    //#endregion


    //QR code was used to access this page


    //if the promoterUser is in query parameter
    this.eventPromoterUserIdAndEventId$ = this.activatedRoute.queryParams.pipe(
      map(params => ({
        promoterUserId: params['promoterUserId'] || null,
        eventId: params['eventId'] || null
      }))
    );

    // //if the promoterUserId is in query parameter
    // this.activityPromoterUserId$ = this.activatedRoute.queryParams.pipe(
    //   map(params => params['promoterUserId'] || null
    //   )
    // );

    // confirm parameter retrieval
    this.activatedRoute.queryParams.subscribe(params => {
      this.promoterUserId = params['promoterUserId'] || null;
      this.eventId = params['eventId'] || null;

      console.log('Query Param 1:', this.promoterUserId);
      console.log('Query Param 2:', this.eventId);
    });

  
    // this.activityPromoterUserId$.subscribe(promoterUserId => {
    this.eventPromoterUserIdAndEventId$.subscribe(({promoterUserId, eventId}) => {

      // if promoter user Id was supplied,
      if(promoterUserId && !eventId)
      {
        //use the promoter id to get events
        this.eventPromoterUserId = promoterUserId;

        if(!environment.production)
        {
          console.log(`Promoter User Id retrieved: ${promoterUserId}`)
        }

        //load promoting events for the retrieved user
        this.store.dispatch(LOAD_EVENTS_I_AM_PROMOTING({
          promoterUserId: this.eventPromoterUserId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }));
      
      }
      //promoter user ID and activity Id = Booking request
      else if (promoterUserId && eventId)
      {
        // if(this.usrObjectGlobal)
        // {
          //use the promoter id to get activities
          this.eventPromoterUserId = promoterUserId;

          if(!environment.production)
          {
            console.log(`Promoter User Id retrieved: ${promoterUserId}`)
          }

          //load promoting activities for the retrieved user
          this.store.dispatch(LOAD_EVENTS_I_AM_PROMOTING({
            promoterUserId: this.eventPromoterUserId,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));

          //at this point, open the activity booking dialog
          if(!environment.production)
          {
            console.log(`Promoter User retrieved: ${promoterUserId} AND`)
            console.log(`Event Id retrieved ?: ${eventId}`)
          }

          alert(`Open booking dialog for user: ${JSON.stringify(this.usrObjectGlobal)}`)
        // }
        // else{
        //   alert(`No user: ${JSON.stringify(this.usrObjectGlobal)}`)
        // }
      }
    });



    
    // Subscribe to the activities observable and log the data when it updates
    // Log the observable itself to check if it's emitting
    this.eventdeduction$ = this.eventsPromoting$!.pipe(
      distinctUntilChanged(),
      // take(1), // Ensure it only emits once
      switchMap(eventsPromoting => {

        if(!environment.production)
        {
          console.log('Observable emitted');
        }

        if (eventsPromoting.length > 0) 
        {
          if(!environment.production)
          {
            console.log('Promotion data:', eventsPromoting);
          }

         
          // Extract eventIds that are not in the past from each object in the array
          const eventIds = eventsPromoting.map(item => item.EventId);

          if(!environment.production)
          {
            console.log('Extracted ActivityIds:', eventIds);
          }

          // Return the observable from GetEventsByIds and filter out past events
          return this.eventService.GetEventByIds(eventIds).pipe(
            map(events => 
              events.filter(event => event.Status !== EventStatus.Past) // Exclude past events
            )
          );
        } 
        else {

          //maybe an invalid promoterUserId was supplied

          //but we couldn't get events promoting
          if(!environment.production)
          {
            console.log('No Promotion available');
          }

          // If no promotion data, return an empty observable or a fallback value
          return of(null);
        }
      })
    );

    
  }

  backToActivityPromotionDashboard()
  {
    this.router.navigate(['APDashboard']);
  }

  navigateToActivities(): void {
    this.router.navigate(['/activitylist']); // Navigate to '/activities' page
  }


  navigateToEvents(): void {
    this.router.navigate(['/eventsperstatus']); // Navigate to '/activities' page
  }
}
