import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventPromotersLog } from 'src/app/types/models/eventPromotersLog.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventPromoterLogsService {

  private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

  constructor(private http: HttpClient) 
  { 
    this.APIUrl = environment.APIUrl;
    if(!environment.production)
    {
      console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
    }
  }

    //add new event promoter log
    CreateEventPromotersLog(newEventPromoterLogs: EventPromotersLog)
    {
      return this.http.post<any>(
        this.APIUrl + 'EventPromotersLog/CreateEventPromotersLog', 
        newEventPromoterLogs, 
        this.getHttpOptions());
    }
  

    ViewEventIamPromotingWithPagination(
      promoterUserId: string, 
      pageNumber: number, 
      pageSize: number): Observable<any[]> {
      let url = this.APIUrl + `EventPromotersLog/ViewEventsIamPromotingWithPagination?promoterUserId=${promoterUserId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  
      return this.http.get<any>(
        url,
        this.getHttpOptions());
    }

    //get all promoters for an event
    ViewAllPromotersForEventWithPagination(
      activityId: number, 
      pageNumber: number, 
      pageSize: number
    ): Observable<any[]> {
      let url = this.APIUrl + `EventPromotersLog/ViewEventPromotersLogThatAreNotRemovedForAnActivityWithPagination?promoterUserId=${activityId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  
      return this.http.get<any>(
        url,
        this.getHttpOptions());
    }

    
    UpdateEventPromotersLog(eventPromotersLogChanges: EventPromotersLog): Observable<any>
    {
      return this.http.put<any>(
        this.APIUrl + `EventPromotersLog/UpdateEventPromotersLog`, 
        eventPromotersLogChanges, 
        this.getHttpOptions());
    }

    RemoveEventPromotersLog(eventPromotersLog: EventPromotersLog) : Observable<any>
    {
      return this.http.post<any>(
        this.APIUrl + `EventPromotersLog/RemoveEventPromotersLog`, 
        eventPromotersLog, 
        this.getHttpOptions());
    }
 

    getHttpOptions(){
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      };
  
      return httpOptions;
    }
}
