import { createAction, props } from "@ngrx/store";
import { GenericUser } from "src/app/types/models/genericuser.model";


// export const GET_CURRENT_USER = createAction('[Generic User] Get Current Logged In User',
//     props<{ userId: string}>());    
export const GET_CURRENT_USER = createAction('[Generic User] Get Current Logged In User');    

export const GET_CURRENT_USER_SUCCESS = createAction('[Generic User] Get Current Logged In User Success',
    props<{currentloggedinuser: GenericUser}>());

export const GET_CURRENT_USER_FAIL = createAction('[Generic User] Get Current Logged In User Fail',
    props<{error: string}>());

export const RESET_LOGGED_IN_USER = createAction('[Generic User] Reset Logged In User');


// creators of events by status
export const GET_CREATORS_OF_EVENTS_BY_STATUS = createAction('[Generic Users] Get Creators of Events By Status',
    props<{ eventStatus: number, arrayOfUserId: string[] }>());

export const GET_CREATORS_OF_EVENTS_BY_STATUS_SUCCESS = createAction('[Generic Users] Get Creators of Events By Status Success',
    props<{ eventscreatorsbystatus: GenericUser[]}>());

export const GET_CREATORS_OF_EVENTS_BY_STATUS_FAIL = createAction('[Generic Users] Get Creators of Events By StatusFail',
    props<{error: string}>());


