import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { LOAD_ACTIVITIES_I_AM_PROMOTING } from 'src/app/ngrx/actions/activity-i-am-promoting.actions';
import { GET_CURRENT_USER } from 'src/app/ngrx/actions/current-user.actions';
import { selectActivitiesIAmPromotingWithPagination, selectActivitiesIAmPromotingWithPaginationError, selectActivitiesIAmPromotingWithPaginationLoaded, selectActivitiesIAmPromotingWithPaginationLoading } from 'src/app/ngrx/selectors/activity-i-am-promoting.selectors';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
import { AppState } from 'src/app/ngrx/state/app.state';
import { ActivityService } from 'src/app/shared/services/activity.service';
import { AuthService } from 'src/app/shared/services/CustomAuthenticator/auth.service';
import { ActivityPromotersLog } from 'src/app/types/models/activityPromotersLog.model';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { environment } from 'src/environments/environment';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'app-activity-promotions',
  standalone: true,
  // ActivityPromotionsComponent
  imports: [
    CommonModule,
    CardComponent],
  templateUrl: './activity-promotions.component.html',
  styleUrl: './activity-promotions.component.scss'
})
export class ActivityPromotionsComponent {
  usrObject$: Observable<GenericUser | null>
  usrObjectGlobal: GenericUser | undefined

  activitiesPromoting$: Observable<ActivityPromotersLog[]> | undefined
  activitiesPromotingLoading$: Observable<boolean> | undefined
  activitiesPromotingLoaded$: Observable<boolean> | undefined
  activitiesPromotingError$: Observable<string | null> 

  activitydeduction$!: Observable<any>;

  pageNumber: number = 1;
  pageSize: number = 10;


  // activitiesOpenForPromotion: Activity[] | undefined
  
  showButtonHistoryAndEdit = false; // user does not get to view those actions here
  showActivityPromoButton = false;
  showBookingButtonFromActivityPromotion: boolean = true;
  activityPromoterUserId: string = '12345'
  activityPromoterUserId$!: Observable<string | null>

  activityPromoterUserIdAndActivityId$: Observable<{ 
    promoterUserId: string | null, 
    activityId: string | null }> | undefined;
  

  promoterUserId: string | undefined
  activityId: string | undefined 


  
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authSvc: AuthService,
    private activityService: ActivityService, 
    private store: Store<AppState>) 
  {
    //currently logged in user
    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));


    // get the activities I am promoting from store
    this.activitiesPromoting$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPagination))
    this.activitiesPromotingLoading$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoading))
    this.activitiesPromotingLoaded$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationLoaded))
    this.activitiesPromotingError$ = this.store.pipe(select(selectActivitiesIAmPromotingWithPaginationError))
  }

  ngOnInit()
  {
    this.activitiesPromotingLoaded$?.subscribe(_ => {
      console.log(`Loaded: ${_}`)
    })

    //#region loggedInuserLogic
    this.usrObject$.pipe(
      first(), // Take the first value emitted by the observable
      tap(user => {
        if (!user) {
          // dispatch
          this.store.dispatch(GET_CURRENT_USER());
        }
        else {
          //assign global user object
          this.usrObjectGlobal = user

          //dispatch
          // this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
          //   promoterUserId: user?.Id!,
          //   pageNumber: this.pageNumber,
          //   pageSize: this.pageSize
          // }));
        }
      }),
      switchMap(() => this.usrObject$) // Listen for new updates from the store
    ).subscribe()
    //#endregion


    //QR code was used to access this page


    //if the promoterUser is in query parameter
    this.activityPromoterUserIdAndActivityId$ = this.activatedRoute.queryParams.pipe(
      map(params => ({
        promoterUserId: params['promoterUserId'] || null,
        activityId: params['activityId'] || null
      }))
    );

    // //if the promoterUserId is in query parameter
    // this.activityPromoterUserId$ = this.activatedRoute.queryParams.pipe(
    //   map(params => params['promoterUserId'] || null
    //   )
    // );

    // confirm parameter retrieval
    this.activatedRoute.queryParams.subscribe(params => {
      this.promoterUserId = params['promoterUserId'] || null;
      this.activityId = params['activityId'] || null;

      console.log('Query Param 1:', this.promoterUserId);
      console.log('Query Param 2:', this.activityId);
    });

  
    // this.activityPromoterUserId$.subscribe(promoterUserId => {
    this.activityPromoterUserIdAndActivityId$.subscribe(({promoterUserId, activityId}) => {

      // if promoter user Id was supplied,
      if(promoterUserId && !activityId)
      {
        //use the promoter id to get activities
        this.activityPromoterUserId = promoterUserId;

        if(!environment.production)
        {
          console.log(`Promoter User Id retrieved: ${promoterUserId}`)
        }

        //load promoting activities for the retrieved user
        this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
          promoterUserId: this.activityPromoterUserId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }));
      
      }
      //promoter user ID and activity Id = Booking request
      else if (promoterUserId && activityId)
      {
        // if(this.usrObjectGlobal)
        // {
          //use the promoter id to get activities
          this.activityPromoterUserId = promoterUserId;

          if(!environment.production)
          {
            console.log(`Promoter User Id retrieved: ${promoterUserId}`)
          }

          //load promoting activities for the retrieved user
          this.store.dispatch(LOAD_ACTIVITIES_I_AM_PROMOTING({
            promoterUserId: this.activityPromoterUserId,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          }));

          //at this point, open the activity booking dialog
          if(!environment.production)
          {
            console.log(`Promoter User retrieved: ${promoterUserId} AND`)
            console.log(`Activity Id retrieved ?: ${activityId}`)
          }

          alert(`Open booking dialog for user: ${JSON.stringify(this.usrObjectGlobal)}`)
        // }
        // else{
        //   alert(`No user: ${JSON.stringify(this.usrObjectGlobal)}`)
        // }
      }
    });



    
    // Subscribe to the activities observable and log the data when it updates
    // Log the observable itself to check if it's emitting
    this.activitydeduction$ = this.activitiesPromoting$!.pipe(
      distinctUntilChanged(),
      // take(1), // Ensure it only emits once
      switchMap(activitiesPromoting => {

        if(!environment.production)
        {
          console.log('Observable emitted');
        }

        if (activitiesPromoting.length > 0) 
        {
          if(!environment.production)
          {
            console.log('Promotion data:', activitiesPromoting);
          }

         
          // Extract ActivityId from each object in the array
          const activityIds = activitiesPromoting.map(item => item.ActivityId);

          if(!environment.production)
          {
            console.log('Extracted ActivityIds:', activityIds);
          }

          // Return the observable from GetActivitiesByIds
          return this.activityService.GetActivitiesByIds(activityIds);
        } 
        else {

          //maybe an invalid promoterUserId was supplied

          //but we couldn't get activities promoting
          if(!environment.production)
          {
            console.log('No Promotion available');
          }

          // If no promotion data, return an empty observable or a fallback value
          return of(null);
        }
      })
    );

    
  }

  backToActivityPromotionDashboard()
  {
    this.router.navigate(['APDashboard']);
  }

  navigateToActivities(): void {
    this.router.navigate(['/activitylist']); // Navigate to '/activities' page
  }


  navigateToEvents(): void {
    this.router.navigate(['/eventsperstatus']); // Navigate to '/activities' page
  }
}
