import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PromoterUsersState } from "../state/promoter-users.state";

export const activePromotersState = createFeatureSelector<PromoterUsersState>('activepromoterusers');

export const selectActivePromotersPaginated = createSelector(
    activePromotersState, 
  (state: PromoterUsersState) => state.activepromoterusers
);

export const selectActivePromotersLoading = createSelector(
    activePromotersState,
  (state: PromoterUsersState) => state.loading
);

export const selectActivePromotersLoaded = createSelector(
    activePromotersState,
    (state: PromoterUsersState) => state.loaded
  );

export const CurrentActivePromotersError = createSelector(
    activePromotersState,
  (state: PromoterUsersState) => state.error
);