import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
// import { ActivityPromoterToHostNotification } from 'src/app/types/models/NotificationRelated/activityPromoterToHostNotification.model';
import { EventPromoterRequestNotification } from 'src/app/types/models/NotificationRelated/eventPromoterRequestNotification.model';
import { EventPromoterToHostNotification } from 'src/app/types/models/NotificationRelated/eventPromoterToHostNotification.model';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class EventPromoterNotificationTempGenService {

    private APIUrl: string | undefined; // = 'https://localhost:7032/api/'

    constructor(private http: HttpClient) 
    { 
        this.APIUrl = environment.APIUrl;

        if(!environment.production)
        {
        console.log(`Env: ${environment} || URL: ${environment.APIUrl}`);
        }
        
    }

    //get Promotion Request To event host
    GetPromotionRequestToEventHostTemplateAndSendEmail(
        recipientEmail: string,
        eventPromoterToHostNotification: EventPromoterToHostNotification): Observable<any> {
        
        let url = this.APIUrl + `EventPromoterNotificationTempGen/GetPromotionRequestToEventHostTemplateAndSendEmail/${recipientEmail}`;
        
        const promotionReqTemplateToActivityHost = this.http.post<EventPromoterToHostNotification>(
        url,
        eventPromoterToHostNotification);

        return promotionReqTemplateToActivityHost;
    }

    
    //get Request Notification To event promoter
    GetRequestNotificationToEventPromoterTemplateAndSendEmail(
        recipientEmail: string,
        eventPromoterRequestNotification: EventPromoterRequestNotification): Observable<any> {
        
        let url = this.APIUrl + `EventPromoterNotificationTempGen/GetRequestNotificationToEventPromoterTemplateAndSendEmail/${recipientEmail}`;
        
        const promoterReqNotificationTemplate = this.http.post<EventPromoterRequestNotification>(
            url,
            eventPromoterRequestNotification);

        return promoterReqNotificationTemplate;
    }


    EventPromotionResponseWithQueryParams(payload: any) : Observable<any> 
    {
        const url = `${this.APIUrl }EventPromoterNotificationTempGen/EventPromotionResponse`;
    
        // Construct HttpParams for query parameters
        let params = new HttpParams();
        for (const key in payload) {
          if (payload.hasOwnProperty(key) && payload[key] !== undefined) {
            params = params.set(key, payload[key]);
          }
        }
    
        // Send the POST request with query parameters
        return this.http.post(url, null, { params });
    }



    getHttpOptions(){

        const httpOptions = {
        
        };

        return httpOptions;
    }

}
