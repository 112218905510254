<!-- User:
{{usrObject$ | async | json }}

<hr>
Subscriptions:
{{currentUserSubscriptions$ | async | json}}

<hr>

Subscription Tier: 
{{SubscriptionTier$ | async | json }}

<hr>
Combination of subscription and tier
{{this.combinedSubscriptionTiers$ | async | json}} -->

<div style="height: 100vh; width: 100%; ">
    <ng-container *ngIf="combinedSubscriptionTiers$ | async as combinedSubscriptionTiers">

        
        <ng-container *ngIf="combinedSubscriptionTiers.length === 0">
            <!-- <a 
                style="margin-top: 25px; display: flex; justify-content: center; align-items: center"
                [routerLink]="['/pricing']">
                activate free tier
            </a> -->

            <ng-container *ngIf="(canActivateFreeTier$ | async) as canActivateFreeTier">
                
                <!-- Can activate: {{canActivateFreeTier}} -->
                
                <div class="text-center">
            
                  <button 
                    style="border-radius: 40px; margin: 25px 0px 0px 0px;"
                    class="btn btn-outline-primary"
                    (click)="activateFreeTier()">
                    activate free tier
                  </button>
                </div>
            </ng-container>

            <!-- Message when there are no subscriptions -->
            <!-- <div>No subscriptions found.</div> -->
            <div class="no-subscriptions-card">
                <div class="card-content">
                    <svg xmlns="http://www.w3.org/2000/svg" style="color: red;" width="40" height="40" fill="currentColor" class="bi bi-ban" viewBox="0 0 16 16">
                        <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"/>
                    </svg>
                    <br><br>
                    <p>No subscription(s) found.</p>
                    <a 
                    [routerLink]="['/pricing']"
                    class="subscribe-link">Subscribe Now</a>
                </div>
            </div>
        
        </ng-container>
    
        <ng-container *ngIf="combinedSubscriptionTiers.length > 0">
            <br><br>
            <!-- Display data -->
            <div 
                *ngFor="let item of combinedSubscriptionTiers">

                <div class="card">
                    <div class="card-body d-flex justify-content-between align-items-center">
                    <span class="card-title">
                        {{ item.tier?.TierName }}
                    </span>

                    <!-- inactive subscription -->
                    <span *ngIf="!item.userSubscription.IsActive">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(237, 36, 36);" width="30" height="30" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                        </svg>
                     
                    </span>

                    <!-- active subscription -->
                    <span *ngIf="item.userSubscription.IsActive">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(98, 232, 98);" width="30" height="30" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                            <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                        </svg>
                    </span>
                   
                    </div>
                </div>
                <br>
            </div>

            <br>
            <a 
            style="float: inline-end;"
            [routerLink]="['/pricing']"
            class="subscribe-link">Upgrade</a>
        </ng-container>
    </ng-container>
  
    <ng-container *ngIf="!(combinedSubscriptionTiers$ | async)">
        <!-- Loading state -->
        <div>Loading...</div>
    </ng-container>
</div>