<div class="pricing-container">
  <div class="billing-toggle">
    <label>
      <span>Billed Monthly</span>
      <!-- <input type="button" id="billingToggle" /> -->
      <span 
        *ngIf="isMonthly"
        (click)="toggleBilling()">
          <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(35, 187, 35);" width="30" height="30" fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
          </svg>
      </span>

      <span 
        *ngIf="isAnnually"
        (click)="toggleBilling()">
          <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(240, 200, 39);" width="30" height="30" fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
            <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
          </svg>
      </span>

      <span>Billed Annually</span>
    </label>
  </div>
  <hr>

  <!-- loaded -->
  <div 
  class="pricing-cards" 
  *ngIf="allSubTiers$ | async as allSubTiers">
    
    <ng-container *ngFor="let tier of allSubTiers">
      
      <!-- {{tier | json}} -->

      <!-- monthly -->
      <div 
      class="pricing-card">
        <span *ngIf="isMonthly">

          <h2>{{tier.TierName}} - R{{tier.Price}}/mo</h2>
        </span>

        <span *ngIf="isAnnually">

          <h2>{{tier.TierName}} - R{{tier.Price}}/yr</h2>
        </span>

        <p *ngIf="tier.TierName?.includes('Basic')">
          Perfect plan if you're just starting out.
        </p>

        <hr>

        <ul>
          <li><span>&#10004;</span> {{tier.MaxActivitiesPerMonth}} Activity listing(s)</li>
          <li><span>&#10004;</span> {{tier.MaxEventsPerMonth}} Event listing(s)</li>
          <li><span>&#10004;</span> {{tier.MaxActivityPromoters}} Activity promoter(s)</li>
          <li><span>&#10004;</span> {{tier.MaxEventPromoters }} Event promoter(s)</li>
          <li><span>&#10004;</span> Payment Processing</li>
          <li><span>&#10004;</span> Booking/RSVP Management</li>
          
          <span *ngIf="tier.TierName != 'Basic'">
            <li><span>&#10004;</span> Real-time Analytics</li>
            <li><span>&#10004;</span> Historical Data</li>
            <li><span>&#10004;</span> Support</li>
          </span>
        </ul>
        
        <ng-container *ngIf="(usrObject$ | async) as usrObject">
          <button (click)="subscibeUserIfLoggedIn(usrObject, tier)">
            Subscribe Now
          </button>
        </ng-container>

        <ng-container *ngIf="!(usrObject$ | async)">
          <button (click)="login2Subscribe()">
            Login/Register to subscribe
          </button>
        </ng-container>
        
       
      </div>
    </ng-container>

    <!-- <div class="pricing-card">
      <h2>$12/mo</h2>
      <p>Perfect plan if you're a heavy user of social media.</p>
      <ul>
        <li><span>&#10004;</span> LinkedIn Integration</li>
        <li><span>&#10004;</span> Twitter Integration</li>
        <li><span>&#10004;</span> Instagram Integration</li>
        <li><span>&#10004;</span> Real-time Analytics</li>
      </ul>
      <button>Subscribe Now</button>
    </div>

    <div class="pricing-card">
      <h2>$7/mo</h2>
      <p>Perfect plan if you're just starting out.</p>
      <ul>
        <li><span>&#10004;</span> LinkedIn Integration</li>
        <li><span>&#10004;</span> Twitter Integration</li>
      </ul>
      <button>Subscribe Now</button>
    </div> -->
  </div>

  <!-- loading -->
  <div *ngIf="!(allSubTiers$ | async)">
    <section 
    class="spinner-container">

      <div class="spinner"></div>
    </section>
  </div>
</div>
