<!-- show a public page for a specific user (logged in or userId supplied as a query parameter) -->

<!-- show all the ACCEPTED activities for the user (logged in or userId supplied as a query parameter) -->

<div class="container"> 

    <!-- show the header when we get the activityPromoterUserId  -->
    <!-- no user is logged in -->
    
    <ng-container *ngIf="eventPromoterUserId">

        <ng-container>
            <div class="text-center">
    
                <button 
                    style="border-radius: 40px; margin: 10px 0px 0px 0px;"
                    class="btn btn-outline-primary">
                    Event promotion(s)
                </button>
            </div>
        </ng-container>
    </ng-container>
  
    <hr>
  
    
    <section>
        <!-- Loading Spinner or Placeholder -->
        <ng-container 
        *ngIf="eventsPromotingLoading$ | async; else loadedState">
        
          
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
        </ng-container>
  

        <!-- loaded state -->
        <ng-template #loadedState>
         
            <!-- if we  get any event promotion(s) -->
            <ng-container 
                class="con" 
                *ngIf="eventdeduction$ | async as eventdeduction else noEvents">
            
                <!-- Promotions count: {{activitydeduction.length}} -->

                <!-- show the activity promotions found -->
                <ng-container *ngIf="eventdeduction.length > 0; else noEvents">

                
                    <a *ngFor="let evnt of eventdeduction">
                    

                        <!-- {{activity | json}} -->
                        <app-card 
                            [entity]="evnt"
                            [isEvent]="true"  
                            [showButton]="showButtonHistoryAndEdit"
                            [showActivityPromoButton]="showEventPromoButton"
                            [showBookingButtonFromEventPromotion]="showBookingButtonFromEventPromotion"
                            [EventPromoterUserId]="eventPromoterUserId">
                        </app-card>
                        <br>    
                     
                    </a>
                </ng-container>

            </ng-container>
        

            <!-- if we NOT get any activity promotion(s) -->
            <!-- <ng-container
                *ngIf="!(eventdeduction$ | async) as eventdeduction">
                
                
            </ng-container> -->
            
            <ng-template #noEvents>
                <div style="margin-top: 20px;">
                    <div class="error-message">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                        
                        Error retrieving event promotion(s). 
                    </div>
                    <br> <br> <br>
    
                    <div class="other-message">
                        <button 
                            (click)="navigateToActivities()"
                            class="btn btn-outline-primary">
                            View other activities
                        </button>
    
                        <br> <br>
    
                        <button
                            (click)="navigateToEvents()"
                            class="btn btn-outline-primary">
                        
                            Check out available events
                        </button>
    
                    </div>
    
                </div>
            </ng-template>
          
        </ng-template>
    
       
    </section>
  
</div>