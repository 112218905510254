import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as UserSubscriptionActions from "../actions/current-user-subscription.actions";

import { catchError, map, mergeMap, of, tap } from "rxjs";
import { AuthService } from "src/app/shared/services/CustomAuthenticator/auth.service";
import { environment } from "src/environments/environment";
import { UserSubscription } from "src/app/types/models/SubscriptionRelated/UserSubscription.model";

@Injectable()
export class UserSubscriptionsEffects {

    subscriptionsOfLoggedInUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS),
        // mergeMap(action => this.authService.GetActiveUserSubscriptionByUserId(action.userId)
        mergeMap(action => this.authService.GetUserSubscriptionByUserId(action.userId)
        .pipe(
            tap((currentloggedinusersubscription: UserSubscription[]) => {
                if(!environment.production)
                {
                    console.log(`Subscriptions of currently logged in user: ${ JSON.stringify(currentloggedinusersubscription) }`);
                }
            }),
            map(currentloggedinusersubscription => UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS_SUCCESS({ currentloggedinusersubscription })),
            catchError(error => of(UserSubscriptionActions.GET_CURRENT_USER_SUBSCRIPTIONS_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}