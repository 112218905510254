


  
  
  
  
<!-- *ngIf="usrRoles$ | async as usrRoles" -->
<div 
class="container">
    <mat-tab-group #tabGroup>
        <mat-tab label="Activities">
            <app-activity-promotions></app-activity-promotions>

        </mat-tab>

        <mat-tab label="Events">
            <app-event-promotions></app-event-promotions>

        </mat-tab>
    </mat-tab-group>
</div>