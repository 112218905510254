import { Component } from '@angular/core';

@Component({
  selector: 'app-promoting-events-private',
  standalone: true,
  imports: [],
  templateUrl: './promoting-events-private.component.html',
  styleUrl: './promoting-events-private.component.scss'
})
export class PromotingEventsPrivateComponent {

}
