import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventPromoterNotificationTempGenService } from 'src/app/shared/services/NotificationsRelated/EventPromoterNotificationTempGen.service';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';

@Component({
  selector: 'app-event-promotion-response-handler',
  standalone: true,
  imports: [],
  templateUrl: './event-promotion-response-handler.component.html',
  styleUrl: './event-promotion-response-handler.component.scss'
})
export class EventPromotionResponseHandlerComponent {
  decryptedParams: any = {};

  constructor(
    private route: ActivatedRoute,
    private rt: Router,
    private obfuscationService: ObfuscationServiceService,
    private apnServer: EventPromoterNotificationTempGenService,
    private toastrSvc: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // Decrypt each query parameter
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          this.decryptedParams[key] = this.obfuscationService.decrypt(params[key]);
        }
      }
      
      console.log('Decrypted Params:', this.decryptedParams);

      // Make the POST HTTP call
      this.sendEventPromotionResponse(this.decryptedParams);
    });
  }

  /**
   * Sends the decrypted parameters to the backend via POST request.
   * @param params Decrypted parameters
   */
  sendEventPromotionResponse(params: any): void {
    this.apnServer.EventPromotionResponseWithQueryParams(params).subscribe({
      next: (response) => {
        console.log('API Response:', response);

         // Navigate to the activities page
         this.rt.navigateByUrl('/eventsperstatus').then(() => {
          // Show alert after navigation
          this.toastrSvc.success('Event promotion response processed', 'Promotion Response');
        });
      },
      error: (error) => {
        // console.error('Error while calling API:', error);

        this.toastrSvc.error('Event promotion response failed processing. Please try again', 'Promotion Response');
        
        // Navigate to the activities page
        this.rt.navigateByUrl('/eventsperstatus').then(() => {
          // Show alert after navigation
          this.toastrSvc.error('Event promotion response failed processing. Please try again', 'Promotion Response');
        
        });
      },
    });
  }
}
