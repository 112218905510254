import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ActivityIAmPromotingState } from "../state/activities-I-Am-Promoting.state";
import { EventsIAmPromotingState } from "../state/events-I-am-Promoting.state";

export const selectEventsIAmPromotingState = createFeatureSelector<EventsIAmPromotingState>('eventsiampromoting');

export const selectEventsIAmPromotingWithPagination = createSelector(
    selectEventsIAmPromotingState,
  (state: EventsIAmPromotingState) => state.eventsiampromoting
);

export const selectEventsIAmPromotingWithPaginationLoading = createSelector(
    selectEventsIAmPromotingState,
  (state: EventsIAmPromotingState) => state.loading
);

export const selectEventsIAmPromotingWithPaginationLoaded = createSelector(
    selectEventsIAmPromotingState,
    (state: EventsIAmPromotingState) => state.loaded
  );

export const selectEventsIAmPromotingWithPaginationError = createSelector(
    selectEventsIAmPromotingState,
  (state: EventsIAmPromotingState) => state.error
);