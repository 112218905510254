<div class="">


    <!-- role based functionality for MaiX eventHost and Admin -->
    <div 
    class="row">
    
        <div 
        class="col-12 actBtnsDv" 
        style="text-align: center; margin-top: 30px;">
            
            <button
                (click)="RouteToAddNewEvent()"
                style="border-radius: 20px; margin: 10px 0px 20px 5px;"
                type="button" 
                class="btn btn-outline-primary position-relative"
                *ngIf="canAddEvent$ | async">
                <!-- how many event(s) can user add (based on subscription) -->
                <span 
                *ngIf="(combinedSubCheck$ | async) as tierAndEvents"
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {{ tierAndEvents.subTier?.MaxEventsPerMonth - tierAndEvents.usrEvents?.length }}
                    
                    <span class="visually-hidden">unread messages</span>
                </span>
                
                <!-- warning if you are out of adding -->
                <!-- <span>
                    <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(220, 220, 32);" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                        <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z"/>
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                    </svg>
                </span> -->
                Create Event
            </button>

        
        </div>

    
    </div>

    <mat-tab-group>
        <mat-tab label="Future"> 
            <app-selected-events [eventStatus]="2"></app-selected-events>
        </mat-tab>
        <mat-tab label="Live"> 
            <app-selected-events [eventStatus]="1"></app-selected-events>
        </mat-tab>
        <mat-tab label="Past"> 
            <app-selected-events [eventStatus]="0"></app-selected-events>
        </mat-tab>
    </mat-tab-group>
  
</div>