<!-- {{entity | json}} -->


<article class="card">
    <figure class="card-img">
      <!-- this img tag below can be replace with carousel -->
      
      <img 
        [src]="rtImgPath + entity.DefaultImage"/>
    </figure>
    
    <div class="card-body">
      <h2 class="card-title">{{ entity.Name }}</h2>
      <p class="card-text">
        {{ displayedDescription }}
        <a 
        *ngIf="entity?.Description?.length! > descriptionCutter" 
        (click)="toggleDescription($event)">
          {{ isExpanded ? 'show less' : 'show more' }}
        </a>
      </p>

      <!-- entity default price (default card usage) -->
      <p 
        *ngIf="!showActivityPromoButton && !showEventPromoButton"
        style="font-size: 16px; font-weight: bold;">
        {{entity.PricePerPerson | currency: 'ZAR': 'symbol' }}
      </p>

      <!-- activity price OR promotion earning -->
      <ng-container *ngIf="ActivityPromoterUserId || EventPromoterUserId">
        <!-- price earnings for ACTIVITY promoter  -->
        <ng-container>
          <p 
            *ngIf="
              showActivityPromoButton && 
              isActivityTypeGuarded(entity)"
            style="font-size: 16px; font-weight: bold;">
            Earn: {{(
              (entity.PricePerPerson!) * 
              (entity.ActivityPromotionPercentage! / 100)) | currency: 'ZAR': 'symbol' }}
              per promotion
          </p>
        </ng-container>

        <!-- price earnings for EVENT promoter  -->
        <ng-container>
          <p 
            *ngIf="
              showEventPromoButton && 
              isEventTypeGuarded(entity)"
            style="font-size: 16px; font-weight: bold;">
            Earn: {{(
              (entity.PricePerPerson!) * 
              (entity.EventPromotionPercentage! / 100)) | currency: 'ZAR': 'symbol' }}
              per promotion
          </p>
        </ng-container>

        <!-- anyone besides the ACTIVITY promoter will see -->
        <ng-container>
          <p 
            *ngIf="
              showActivityPromoButton && 
              isActivityTypeGuarded(entity)"
            style="font-size: 16px; font-weight: bold;">
            Cost: {{
              (entity.PricePerPerson) | currency: 'ZAR': 'symbol' 
            }}
            per user
          </p>
        </ng-container>

        <!-- anyone besides the EVENT promoter will see -->
        <ng-container>
          <p 
            *ngIf="
              showEventPromoButton && 
              isEventTypeGuarded(entity)"
            style="font-size: 16px; font-weight: bold;">
            Cost: {{
              (entity.PricePerPerson) | currency: 'ZAR': 'symbol' 
            }}
            per user
          </p>
        </ng-container>
      </ng-container>

      
    

      <!-- activity related action(s) -->
      <ng-container 
        *ngIf="isActivity">

        <div 
        class="button-container">
          
          <button 
            *ngIf="showButton"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="displayHistory(entity.Id!)">View History</button>

          <button 
            *ngIf="showButton"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="EditActivityByAdmin($event, entity)">Edit</button>

          <!-- promotion related (when user is logged in)-->
            
          <!-- promote and promotion status (FOR ACTIVITIES) -->
          <ng-container *ngIf="(activitiesPromoting$ | async) as activitiesPromoting">
            <!-- promotion is yet to be requested for the user -->
            <button 
            *ngIf="(showActivityPromoButton) && 
                  !EntityIsInPromotionList(activitiesPromoting, entity)"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="PromoteActivity($event, entity, loggedInUser)">
              Promote
            </button>


            <!-- status of promotion action on this entity (promotion already requested)-->

            <!-- 1. Pending (the owner has not accepted) -->
            <button 
              *ngIf="(showActivityPromoButton) && 
                EntityIsInPromotionList(activitiesPromoting, entity) &&
                findAcceptanceStatusForEntity(activitiesPromoting, entity) == 0"
              style="border-radius: 40px;"
              class="btn btn-outline-warning activityBtn"
              (click)="PromotionPendingMessage()">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(220, 220, 36);" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
              </svg>
              Promotion Pending 
            </button> 

            <!-- 2. Accepted (the owner has accepted promotion request for this user) -->
            <button 
              *ngIf="(showActivityPromoButton) && 
                EntityIsInPromotionList(activitiesPromoting, entity) &&
                findAcceptanceStatusForEntity(activitiesPromoting, entity) == 1"
              style="border-radius: 40px;"
              class="btn btn-outline-success activityBtn"
              (click)="PromotingMessage()">
                <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(19, 183, 19);" width="16" height="16" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                  <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                </svg>
                Promoting 
            </button> 

            <!-- 3. Rejected (the owner has rejected promotion request for this user) -->
            <button 
              *ngIf="(showActivityPromoButton) && 
                EntityIsInPromotionList(activitiesPromoting, entity) &&
                findAcceptanceStatusForEntity(activitiesPromoting, entity) == 2"
              style="border-radius: 40px;"
              class="btn btn-outline-danger activityBtn"
              (click)="PromotingMessage()">
                <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(237, 99, 30);" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
                Rejected 
            </button> 
          </ng-container>
       

          <!-- ======================================================== -->
          <!-- ACTIVITY promotion booking (ignore user logged in)  -->
          <ng-container>
            <button 
            *ngIf="(showBookingButtonFromActivityPromotion) && 
            ActivityPromoterUserId.length > 0"
            style="border-radius: 40px;"
            class="btn btn-outline-primary activityBtn"
            (click)="BookActivityForPromoter(entity)">
              Book
            </button>

          </ng-container>

       

     

        </div>
      
      </ng-container>

      
      <!-- event related action(s) -->
      <ng-container 
        *ngIf="isEvent">
        
        <!-- default card usage -->
        <button 
        *ngIf="showButton"
        style="border-radius: 40px;"
        class="btn btn-outline-primary"
        (click)="displayEventHistory(entity.Id!)">View History</button>

        <!-- edit button follows -->


        <!-- promotion related (when user is logged in)-->
        <!-- promote and promotion status (FOR EVENTS) -->
        <ng-container *ngIf="(eventsPromoting$ | async) as eventsPromoting">

          <br><br>
          
          <!-- promotion is yet to be requested for the user -->
          <button 
          *ngIf="(showEventPromoButton) && 
                !EntityIsInEventPromotionList(eventsPromoting, entity)"
          style="border-radius: 40px;"
          class="btn btn-outline-primary activityBtn"
          (click)="PromoteEvent($event, entity, loggedInUser)">
            <!-- Promote-E -->
            Promote
          </button>


          <!-- status of promotion action on this entity (promotion already requested)-->

          <!-- 1. Pending (the owner has not accepted) -->
          <button 
            *ngIf="(showEventPromoButton) && 
              EntityIsInEventPromotionList(eventsPromoting, entity) &&
              findAcceptanceStatusForEvent(eventsPromoting, entity) == 0"
            style="border-radius: 40px;"
            class="btn btn-outline-warning activityBtn"
            (click)="PromotionPendingMessage()">
            <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(220, 220, 36);" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
            <!-- Promotion-E Pending  -->
            Promotion Pending 
          </button> 

          <!-- 2. Accepted (the owner has accepted promotion request for this user) -->
          <button 
            *ngIf="(showEventPromoButton) && 
              EntityIsInEventPromotionList(eventsPromoting, entity) &&
              findAcceptanceStatusForEvent(eventsPromoting, entity) == 1"
            style="border-radius: 40px;"
            class="btn btn-outline-success activityBtn"
            (click)="PromotingMessage()">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(19, 183, 19);" width="16" height="16" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
                <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
              </svg>
              <!-- Promoting-E -->
              Promoting
          </button> 

          <!-- 3. Rejected (the owner has rejected promotion request for this user) -->
          <button 
            *ngIf="(showEventPromoButton) && 
              EntityIsInEventPromotionList(eventsPromoting, entity) &&
              findAcceptanceStatusForEvent(eventsPromoting, entity) == 2"
            style="border-radius: 40px;"
            class="btn btn-outline-danger activityBtn"
            (click)="PromotingMessage()">
              <svg xmlns="http://www.w3.org/2000/svg" style="color: rgb(237, 99, 30);" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
              <!-- Rejected-E -->
              Rejected
          </button> 
        </ng-container>

        <!-- EVENT promotion booking (ignore user logged in)  -->
        <ng-container>
          <button 
          *ngIf="(showBookingButtonFromEventPromotion) && 
          EventPromoterUserId.length > 0"
          style="border-radius: 40px; margin-left: 5px;"
          class="btn btn-outline-primary activityBtn"
          (click)="BookEventForPromoter(entity)">
            Book
          </button>

        </ng-container>

         <!-- ACTIVITY promotion booking (ignore user logged in)  -->
         <!-- <ng-container>
          <button 
          *ngIf="(showBookingButtonFromActivityPromotion) && 
          ActivityPromoterUserId.length > 0"
          style="border-radius: 40px;"
          class="btn btn-outline-primary activityBtn"
          (click)="BookActivityForPromoter(entity)">
            Book
          </button>

        </ng-container> -->

      </ng-container>
      
    </div>
  </article>
  

