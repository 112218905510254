import { createReducer, on } from "@ngrx/store";
import { FeaturedActivityState } from "../state/featuredActivity.state";
import { GET_CREATORS_OF_EVENTS_BY_STATUS, GET_CREATORS_OF_EVENTS_BY_STATUS_FAIL, GET_CREATORS_OF_EVENTS_BY_STATUS_SUCCESS, GET_CURRENT_USER, GET_CURRENT_USER_FAIL, GET_CURRENT_USER_SUCCESS, RESET_LOGGED_IN_USER } from "../actions/current-user.actions";
import { CurrentLoggedInUserState, EventCreatorsByStatusState } from "../state/current-user.state";


//=========================================================
// logged in user
//=========================================================

const initialState: CurrentLoggedInUserState = {
    currentloggedinuser: null,
    loading: false,
    loaded: false,
    error: null,
};



const _currentLoggedinUserReducer = createReducer(
    initialState,
    on(GET_CURRENT_USER, state => ({ ...state, loading: true })),
    on(GET_CURRENT_USER_SUCCESS, (state, { currentloggedinuser }) => ({
        ...state,
        currentloggedinuser,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_CURRENT_USER_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    })),
    on(RESET_LOGGED_IN_USER, () => initialState)
    
);



export function currentLoggedinUserReducer(state: any, action: any) {
    return _currentLoggedinUserReducer(state, action);
}


//=========================================================
// Events creator
//=========================================================

const eventCreatorsInitialState: EventCreatorsByStatusState = {
    eventcreatorsbystatus: [],
    loading: false,
    loaded: false,
    error: null,
};

const _eventCreatorsReducer = createReducer(
    eventCreatorsInitialState,
    on(GET_CREATORS_OF_EVENTS_BY_STATUS, state => ({ ...state, loading: true })),
    on(GET_CREATORS_OF_EVENTS_BY_STATUS_SUCCESS, (state, { eventscreatorsbystatus }) => ({
        ...state,
        eventscreatorsbystatus,
        loading: false,
        loaded: true,
        error: null,
    })),
    on(GET_CREATORS_OF_EVENTS_BY_STATUS_FAIL, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        error,
    }))
    
);
 
export function eventCreatorsReducer(state: any, action: any) {
    return _eventCreatorsReducer(state, action);
}

//=========================================================




