import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import * as EventsPromotingActions from "../actions/events-i-am-promoting.actions";
import { EventsPromoterLogsService } from "src/app/shared/services/events-promoter-logs.service";
import { EventPromotersLog } from "src/app/types/models/eventPromotersLog.model";

@Injectable()
export class EventsIAmPromotingEffects {

    eventsIamPromoting$ = createEffect(() => this.actions$.pipe(
        ofType(EventsPromotingActions.LOAD_EVENTS_I_AM_PROMOTING),
        mergeMap( action => this.eventsPromoterLogsService.ViewEventsIamPromotingWithPagination(
            action.promoterUserId,
            action.pageNumber,
            action.pageSize,
        )
        .pipe(
            tap((eventsiampromoting: EventPromotersLog[]) => {
                if(!environment.production)
                {
                    console.log(`Events I am promoting retrieved: ${ JSON.stringify(eventsiampromoting) }`);
                }
            }),
            map(eventsiampromoting => EventsPromotingActions.LOAD_EVENTS_I_AM_PROMOTING_SUCCESS({ eventsiampromoting })),
            catchError(error => of(EventsPromotingActions.LOAD_EVENTS_I_AM_PROMOTING_FAIL({ error })))
        ))
        )
    );

  constructor(
    private actions$: Actions,
    private eventsPromoterLogsService: EventsPromoterLogsService
  ) {}
}