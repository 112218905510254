<div>

    <div 
        class="card"
        style="width: 100%;">

        <!-- {{imgs$ | async | json}} -->

        
        <div 
        class="carousel carouselContainer slide" 
        id="myCarousel"
        data-ride="carousel">
            <!-- slides -->
            <div 
            class="carousel-inner" 
            role="listbox">

                <ng-container 
                    *ngFor="let x of (imgs$ | async) as imgs; let i = index" 
                    (click)="setCurrentIndex(i)">
                    <div
                    [class.active]="i === currentIndex"
                    class="carousel-item">
                        <img 
                            [src]="rtImgPath + x"
                            alt="Chania"
                            (click)="ViewImage($event, imgs ,i)"
                        >
                    </div>

                </ng-container>
          
            </div>

            <div 
            *ngIf="loading$ | async;" 
            class="spinner-container">
                <div class="spinner"></div>
            </div>
        
            <!-- previous and next controls -->
            <button 
            class="carousel-control-prev" 
            type="button" 
            data-bs-target="#myCarousel" 
            data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button 
            class="carousel-control-next" 
            type="button" 
            data-bs-target="#myCarousel"
            data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>

            <!-- back and location btns -->
            <button 
            (click)="backToActivityList()"
            class="navtor" 
            type="button"
            style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
                        
            </button>

            <span 
                (click)="locationCheck($event)"
                class="locationSpan">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                    </svg>
            </span>

            <span
                (click)="share($event)"
                class="eventShare">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-share-2"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
              
            </span>
        </div>
    </div>

    <!-- actions on activity -->


    <!-- <div class="container" *ngIf="activitySelect$ | async as activitySelect"> -->
    <div class="container" *ngIf="(activitySelect$ | async) as activitySelect">
        <br>
        <!-- book activity button -->
        <div 
            class="row" 
            style="text-align: center;">

            <div class="col-12">
                <button 
                    (click)="BookActivity()"
                    style="border-radius: 25px; width: 250px;"
                    class="btn btn-success">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    Book
                </button>

            </div>
          
            
        </div>
            
        <hr>
        
        <div class="row">
            <div class="col-4" style="text-align: center; font-size: 16px; font-weight: bold;">
                {{ activitySelect.PricePerPerson | currency: 'ZAR':true:'1.2-2'}}
            </div>
           

            <div class="col-8 actvName">
                {{ activitySelect.Name }}
            </div>
        </div>

        <hr>

        <div class="row">

            <!-- if a user is actually found -->
            <div 
            *ngIf="(activitySelectUsr$ | async) as activitySelectUsr; else noActivityUsr"
            class="col-4" 
            style="text-align: center;">
            
                <div>
                   
                    <img 
                        class="mr-3 rounded-circle text-center" 
                        src="{{rtImgPath}}{{activitySelectUsr?.ProfilePicture ?? 'nImg.png'}}" 
                        alt="Generic placeholder image" 
                        style="width:50px; height: 50px; border:solid 3px rgba(255,255,255,0.3);"
                        (click)="ViewAdminImage($event, activitySelectUsr?.ProfilePicture ?? 'nImg.png')"
                    >
                    
                    <br>
                    
                    <span class="text-center">
                        {{activitySelectUsr?.UserName ?? 'Admin'}}
                    </span>
                </div>

                
            </div>

            <!-- if no user is found -->
            <ng-template #noActivityUsr>
                <div class="col-4 text-center">
                    <img 
                    class="mr-3 rounded-circle text-center" 
                    src="{{rtImgPath}}{{'nImg.png'}}" 
                    alt="Generic placeholder image" 
                    style="width:50px; height: 50px; border:solid 3px rgba(255,255,255,0.3);"
                    (click)="ViewAdminImage($event, 'nImg.png')"
                    >
                    
                    <br>

                    <span class="text-center">
                        {{'Admin'}}
                    </span>
                </div>
            </ng-template>

            <!-- {{ activitySelectUsr | json }} -->
            

           


            <div 
                class="col-8 ackDesc">
                {{ activitySelect?.Description }}
            </div>
        </div>

        <hr>

        <!-- review and reporting services -->
        <div 
        class="row"
        style="margin-bottom: 20px;">
            
            <div 
                (click)="InDevMsg()"
                class="col-4 rt">
                
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>
                0 review(s)
            </div>

            <div 
                (click)="InDevMsg()"
                class="col-8 actvName">

                <button 
                    style="border-radius: 25px; width: 150px;"
                    class="btn btn-outline-danger">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stop" viewBox="0 0 16 16">
                        <path d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5z"/>
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag-fill" viewBox="0 0 16 16">
                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
                    </svg>
                    Report
                </button>
            </div>
        </div>
    </div>

</div>