import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserSpecificService } from 'src/app/shared/services/dataService/user-specific.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { VisitorInfoService } from 'src/app/shared/services/visits/visitor-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ObfuscationServiceService } from 'src/app/shared/services/obfuscation-service.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/ngrx/state/app.state';
import { LOAD_EVENTS } from 'src/app/ngrx/actions/maixevent.actions';
import { LOAD_ACTIVITIES } from 'src/app/ngrx/actions/activity.actions';
import { LOAD_FEATURED_ACTIVITIES } from 'src/app/ngrx/actions/featuredActivity.actions';
import { Activity } from 'src/app/types/models/activity.model';
import { Observable, Subscription, filter, map, switchMap, tap } from 'rxjs';
import { MaiXEvent } from 'src/app/types/models/maixevent.model';
import { LOAD_FEATURED_EVENTS } from 'src/app/ngrx/actions/featuredEvent.actions';
import { IpLocation } from 'src/app/ngrx/state/ipLocation.state';
import { GET_IP_LOCATION } from 'src/app/ngrx/actions/ipLocation.actions';
import { GET_IP } from 'src/app/ngrx/actions/ip.actions';
import { selectFutureEventCreators } from 'src/app/ngrx/selectors/maixevent-future-creators.selectors';
import { selectFutureEventsWithPagination } from 'src/app/ngrx/selectors/maixevent-future.selectors';
import { LOAD_FUTURE_EVENTS } from 'src/app/ngrx/actions/maixevents-future.actions';
import { GenericUser } from 'src/app/types/models/genericuser.model';
import { CommonModule } from '@angular/common';
import { CardComponent } from 'src/app/Templates/card/card.component';
import { selectLoggedInUser } from 'src/app/ngrx/selectors/current-user.selectors';
// import { selectFutureEventsByPage } from 'src/app/ngrx/selectors/maixevent-future.selectors';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardComponent
  ]
})
export class CoreComponent implements OnInit {
  rtImgPath: string = environment.fileRetriever;

  activities$: Observable<Activity[]> | undefined 
  featuredActivities$: Observable<Activity[]> | undefined
  maixevents$:  Observable<MaiXEvent[]> | undefined
  featuredEvents$: Observable<MaiXEvent[]> | undefined
  ipLocation$: Observable<IpLocation> | undefined
  ip$: Observable<string> | undefined
  loading$: any
  loaded$: any
  error$: any

  futureEvents$:  Observable<MaiXEvent[]> | undefined
  futureEventCreators$: Observable<GenericUser[]> | undefined

  
  showButtonInSecondCard = false;

  usrObject$!: Observable<GenericUser | null>

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private dialogSvc: DialogService,
    private userSvc: UserSpecificService,
    private addrService: AddressService,
    private visitorInfo: VisitorInfoService,
    private store: Store<AppState>,
    private obfuscationService: ObfuscationServiceService,
    private deviceService: DeviceDetectorService) {
      this.getActivitiesFromStore();
      this.getFeaturedActivitiesFromStore()
      this.getEventsFromStore();
      this.getFeaturedEventsFromStore();
      this.getIp()
      
      this.getFutureEventsFromStore()
  }


  getFutureEventsFromStore(){
    
    this.futureEvents$ = this.store.pipe(select(selectFutureEventsWithPagination));
    this.futureEventCreators$ = this.store.pipe(select(selectFutureEventCreators));
    
  }

  getActivitiesFromStore(){
    this.activities$ = this.store.select(state => state.activities.activities);
    this.loading$ = this.store.select(state => state.activities.loading);
    this.loaded$ = this.store.select(state => state.activities.loaded);
    this.error$ = this.store.select(state => state.activities.error);
  }
  
  getFeaturedActivitiesFromStore(){
    
    // featured activities
    this.featuredActivities$ = this.store.select(state => state.featuredActivities.featuredActivities);
    this.loading$ = this.store.select(state => state.featuredActivities.loading);
    this.loaded$ = this.store.select(state => state.featuredActivities.loaded);
    this.error$ = this.store.select(state => state.featuredActivities.error);

  }

  getEventsFromStore(){
    //#region ngrx variables
    this.maixevents$ = this.store.select(state => state.maixevents.maixevents);
    this.loading$ = this.store.select(state => state.maixevents.loading);
    this.loaded$ = this.store.select(state => state.maixevents.loaded);
    this.error$ = this.store.select(state => state.maixevents.error);

  }

  getFeaturedEventsFromStore(){
    
    // featured activities
    this.featuredEvents$ = this.store.select(state => state.featuredEvents.featuredEvents);
    this.loading$ = this.store.select(state => state.featuredActivities.loading);
    this.loaded$ = this.store.select(state => state.featuredActivities.loaded);
    this.error$ = this.store.select(state => state.featuredActivities.error);

  }


  getIp()
  {
    this.ip$ = this.store.select(state => state.ip.ip);
    this.loading$ = this.store.select(state => state.ipLocation.loading);
    this.loaded$ = this.store.select(state => state.ipLocation.loaded);
    this.error$ = this.store.select(state => state.ipLocation.error);

  }

  getIpLocation()
  {
    this.ipLocation$ = this.store.select(state => state.ipLocation);
    this.loading$ = this.store.select(state => state.ipLocation.loading);
    this.loaded$ = this.store.select(state => state.ipLocation.loaded);
    this.error$ = this.store.select(state => state.ipLocation.error);
  }

  getIpLocationObservable(): Observable<any> {
    this.ipLocation$ = this.store.select(state => state.ipLocation); // Assuming the data is under `data`
    this.loading$ = this.store.select(state => state.ipLocation.loading);
    this.loaded$ = this.store.select(state => state.ipLocation.loaded);
    this.error$ = this.store.select(state => state.ipLocation.error);
    return this.ipLocation$;
  }

  subscription: Subscription | undefined;
  futureEventsStatus: number = 2
  pageNumber: number = 1;
  pageSize: number = 100;

  ngOnInit() 
  {
    // dispatch actions
    this.store.dispatch(LOAD_ACTIVITIES());
    this.store.dispatch(LOAD_FEATURED_ACTIVITIES());
    this.store.dispatch(LOAD_EVENTS());
    this.store.dispatch(LOAD_FEATURED_EVENTS());

    //get/load visitor ip
    this.store.dispatch(GET_IP());
    
    // get events that are in the future
    this.subscription = this.futureEvents$?.subscribe(events => {
      if (!events || events.length === 0) {
        this.store.dispatch(LOAD_FUTURE_EVENTS({ statusCode: this.futureEventsStatus, pageNumber: this.pageNumber, pageSize: this.pageSize }));
      }
    });

    //
    this.ip$?.pipe(
      filter(ip => !!ip && ip.length > 0), //ensure that ip address is available
      switchMap(ip => {
        this.store.dispatch(GET_IP_LOCATION({ ipAddress: ip }));
        // Set up observables for IP location and return the observable
        return this.getIpLocationObservable().pipe(
          filter(ipLocation => !!ipLocation && ipLocation.country.length > 0 ), // Ensure IP location data is available
          tap(ipLocation => {
            this.performAdditionalActions(ip, ipLocation);
          })
        )
      })
    ).subscribe();

    this.usrObject$! = this.store.pipe(select(selectLoggedInUser));
  }

  performAdditionalActions(ip: string, ipLocation: any): void {
    if(!environment.production)
    {
      console.log('Performing additional actions with IP:', ip, 'and IP Location:', ipLocation);
    }

    // Get device with ngx device detector
    const deviceInfo = this.deviceService.getDeviceInfo();
            
    if (!environment.production) {
      console.log(`Device info: ${JSON.stringify(deviceInfo)}`);
    }
  
    // Initialize visitorModel as an empty object
    let visitorModel: any = {};
  
    visitorModel.ip = ip;
    visitorModel.country = ipLocation.country;
    visitorModel.region = ipLocation.region;
    visitorModel.city = ipLocation.city;
    visitorModel.zip = ipLocation.zip;
    visitorModel.latitude = ipLocation.lat;
    visitorModel.longitude = ipLocation.lon;
    visitorModel.deviceType = deviceInfo?.deviceType;
    visitorModel.browser = deviceInfo?.browser;
    visitorModel.os = deviceInfo?.os;
    visitorModel.appBound = environment.AppBoundIdentifier;
  
    if (!environment.production) {
      console.log(`About to send visitor data: ${JSON.stringify(visitorModel)}`);
    }
  
    // Dispatch send visitor information
    this.visitorInfo.sendVisitorInfo(visitorModel)
      .subscribe((response: any) => {
        if (!environment.production) {
          console.log(`Response of visitor add: ${JSON.stringify(response)}`);
        }
      });
  }
 
  ViewActivity(selActivity: any)
  {
    let obfsEventId = this.obfuscationService.encryptEventId(selActivity.Id.toString());

    if(!environment.production)
    {
      console.log(`obfuscation of event ID: ${obfsEventId}`)
    }
    
    this.router.navigate(['activitydetails', obfsEventId]);
  }

  async getUserByIdAsync(userId: number): Promise<any> {
    let evntUsr = await this.validUsers?.find((user: any) => user.Id === userId);
    console.log(`Event user: ${JSON.stringify(evntUsr)}`);

    return evntUsr;
  }

  async ViewEvent(mxEvent: any)
  {

    let obfsEventId = this.obfuscationService
                        .encryptEventId(mxEvent.Id.toString());

    if(!environment.production)
    {
      console.log(`Event object: ${mxEvent} AND obfuscation of event ID: ${obfsEventId}`)
    }
    
    this.router.navigate(['eventdetails', obfsEventId]);
  }

  validUsers: any;

  ViewAdmin(e:any, userId: string)
  {
    //get all the userIds in the activities list
    // const arrayOfIds: string[] = this.activities.map((obj: any) => obj.UserId)
    // const arrayOfIds: string[] = this.activities$?.map((obj: any) => obj.UserId)
    let arrayOfIds: string[] = []

    this.activities$?.pipe(
      map((activities: any) => activities.map((activity: any) => activity.UserId))
    ).subscribe(ids => {
      arrayOfIds = ids;
    });

    //for all the userID that were retrieved, 
    //get user object that match a valid userId
    this.userSvc.GetAllUsersOfAnAppByUserIds(
      environment.AppBoundIdentifier,
      arrayOfIds
    ).subscribe((response: any) => {
      console.log(`User objects of all the IDs sent: ${JSON.stringify(response)}`)
      
      //rematch valid userIds
      this.validUsers = response;

      //use the userID to get the user object
    const usr = this.validUsers.find((x: any) => x.Id == userId);  
    
    if(usr)
    {
      if(!environment.production)
      {
        console.log(`User found: ${JSON.stringify(usr)}`);
      }
      this.router.navigate(['userprofile', { state: usr }]);
    }
    else
    {
      if(!environment.production)
      {
        console.log(`User not found`)
      }

      this.toastr.error("User not found", "Error !")
    }
    })
    
    //this prevents the parent routing click action to kickoff 
    e.stopPropagation();
  }

  locationResponse: any;
  
  ViewLocationOfActivity(e: any, addressId: number)
  {

    if(!environment.production){
      console.log(`Extracted activity address ID: ${addressId}`);
      
    }

    const addrRetrieverObserver = {
      next: async (response: any) =>  {
        this.locationResponse = response;

    
        if(!environment.production)
        {
          console.log("location retrieved successfully:" + JSON.stringify(this.locationResponse));
        }
         
        await this.dialogSvc.ShowLocationDialog(this.locationResponse);

        // //get the longitude and latitude down
        // console.log(`Longitude: ${response?.Longitude}. Latitude: ${this.locationResponse?.Latitude}`);
        
        // let latitude = this.locationResponse?.Longitude; // Example latitude
        // let longitude = this.locationResponse?.Latitude; // Example longitude
        // // Build the map URL with the latitude and longitude parameters
        // // let mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        
        // let mapUrl = `http://maps.google.com/maps?q=${latitude},${longitude}`;

        //  // Open the map in a new browser window or tab
        //  window.open(mapUrl, '_blank');
      },
      error: (err: HttpErrorResponse) => console.log(err),
      complete: () =>{
        console.log("Add activity COMPLETE");
      }
    }

    //get the location of the activity
    this.addrService.GetAddressByAddressId(addressId)
      .subscribe(addrRetrieverObserver);
    
    //open on maps

    // alert("View location of an activity by anyone")
    //this prevents the parent routing click action to kickoff
    e.stopPropagation();
  }

  //#region GO_TOs
  goToActivities() 
  {
    this.router.navigate(['activitylist'])
    // .then(() => window.location.reload())
  }

  goToHowActivities() 
  {
    this.router.navigate(['howactivity'])
    // .then(() => window.location.reload())
  }
  

  goToComps() 
  {
    // this.closeSideBar();

    // alert("In development")
    this.router.navigate(['competees'])
  }

  goToLearnMore()
  {
    if(environment.production)
    {
      
      this.toastr.warning("In development", "status");
    }
    else
    {
      this.router.navigate(['howTo'])
    }
  }

  goToEvents() {
    // this.closeSideBar();

    // alert("In development")
    // this.router.navigate(['eves'])
    this.router.navigate(['eventsperstatus']);
  }

  goToPromotions() {
    // this.closeSideBar();

    // alert("In development")
    // this.router.navigate(['eves'])
    this.router.navigate(['howpromotion']);
  }

  goToHowEvents() 
  {
    this.router.navigate(['howevent'])
    // .then(() => window.location.reload())
  }
  
  signUpAsAPromoter()
  {
    //if user is logged in then to to profile > roles tab
    this.usrObject$.subscribe(user => {
      if (user !== null && user !== undefined) 
      {
        // console.log('User is logged in:', user);
        this.router.navigate(['profile'], 
          { queryParams: { 
            tab: 3 //open roles tab
          }  
        });
      } 
      else 
      {
        // console.log('No user is logged in.');
        this.router.navigate(['signup'], { queryParams: { asAPromoter: true } })
      }
    });
  }
  

  goToClubs() {
    // this.closeSideBar();

    // alert("In development")
    this.router.navigate(['ubs'])
  }
  //#endregion
}


