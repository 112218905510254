<footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h6>About</h6>
          <p class="text-justify">
            maixperience is your go-to platform for viewing and booking activities and seamless event hosting.
            We streamline the process, ensuring every gathering is memorable. 
            With customizable features, Mxp empowers you to create unforgettable experiences effortlessly. 
            Whether it's conferences, workshops, or social events, 
            Mxp provides the tools and support you need.
            Join us and make your event dreams a reality!
          </p>
        </div>
  
        <div class="col-xs-6 col-md-3">
          <h6>Use Mxp</h6>
          <ul class="footer-links">

            <!-- <li><a href="http://scanfcode.com/category/back-end-development/">Profile</a></li> -->
            <li><a [routerLink]="['/activitylist']">Activities</a></li>
            <li><a [routerLink]="['/eves']">Events</a></li>
            <li><a [routerLink]="['/pricing']">Subscription</a></li>
            <li><a [routerLink]="['/termsandconditions']">Terms and Conditions</a></li>
          </ul>
        </div>
  
        <div class="col-xs-6 col-md-3">
          <h6>Connect with us</h6>
          <ul class="footer-links">
            <li><a [routerLink]="['/howTo']">Learn More</a></li>
            <li><a [routerLink]="['/eves']">Plan Event</a></li>
            <li><a [routerLink]="['/activitylist']">Plan Activity</a></li>
            <li><a [routerLink]="['/eves']">Pretoria Events</a></li>
            <li><a [routerLink]="['/activitylist']">Pretoria Activities</a></li>
            <li><a [routerLink]="['/contact']">Contact Support</a></li>
          </ul>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by
            <a href="#">MXP</a>.
          </p>
        </div>
  
        <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <li>
              <a 
                class="facebook" 
                href="#">
                <i class="bi bi-facebook"></i>
              </a>
            </li>

            <li>
              <a class="twitter" href="#">
                <i class="bi bi-twitter"></i>
              </a>
            </li>

            <li>
              <a 
                class="instagram" 
                target="_blank"
                href="https://www.instagram.com/mai.xperience/">
                
                <i class="bi bi-instagram"></i>
              </a>
            </li>

            <li><a class="youtube" href="#"><i class="bi bi-youtube"></i></a></li>
            <li><a class="tiktok" href="#"><i class="bi bi-tiktok"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>