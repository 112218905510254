<div class="container">

    <!-- main definition -->
    <h2 style="margin-top: 8px;">
        # Activities
    </h2>

    <hr>
      
    <p>
        An "activity" in this context is created by an organized or structured entity that 
        involves physical or recreational participation with a fixed and recurring 
        operating duration. We are selectively onboarding activities for leisure, 
        skill development, or competitive purposes which often require specific 
        equipment(s), locations, or facilities and can be done individually or 
        in groups.
    </p>

    <!-- who can create a new activity -->
    <h2>
        # Who can create new activities
    </h2>
    <hr>

    <div>
        An "activity host" is an entity or individual responsible 
        for creating, managing, and facilitating activities. This entity can take 
        the form of a business, organization, or individual who specializes in 
        offering specific activities such as paint balling spot, a shooting range, 
        ice-skating, horse riding, kayaking or other recreational activities. The
        "activity host" gets to define the following:


        <div style="margin-top: 30px;"> 
            <ol class="my-list">
                <li tabindex="1">
                    Details of the activity
                </li>
                <li tabindex="2">
                    Images that customers will see
                </li>
                <li tabindex="3">
                    Location of the activity
                </li>
                <li tabindex="4">
                    Add recurring operating slot ? <span style="color: red;"> in backlog </span> 
                </li>
                <li tabindex="5">
                    Open to activity promoter ? <span style="color: red;"> in backlog </span> 
                </li>
                <li tabindex="6">
                    Open to event hosting (event host) ? <span style="color: red;"> in backlog </span> 
                </li>
            </ol>
        </div>

        The figure below depicts the "activity host" role in the context of maixperience:

        <div 
        style="width: 400px; height: 360px; margin-top: 40px; "
        class="container">
            <div 
            id="carouselExampleIndicators" 
            class="carousel slide">

                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img 
                        [src]="rtImagePath + 'actHost.webp'" 
                        class="d-block w-100" 
                        alt="activity host">
                    </div>

                    <div class="carousel-item">
                        <img 
                        [src]="rtImagePath + 'actHost2.webp'" 
                        class="d-block w-100" 
                        alt="activity host">
                    </div>
                    
                    <div class="carousel-item">
                        <img 
                        [src]="rtImagePath + 'actHost3.webp'" 
                        class="d-block w-100" 
                        alt="activity host">
                    </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>

                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

        </div>
    </div>

    <br><br><br>
    <!-- who can view -->
    <h2>
        # Who can view and book activities
    </h2>
    <hr>
    <p>
        Any user on the platform can book an activity
    </p>


    <!-- activity hosts benefit -->
    <h2>
        # "Activity Host" benefits
    </h2>
    
    <hr>

    <div>
        By applying to be an "activity host" you get access to independent dashboard that advertises the activity, 
        connect to event hosts that is interested in hosting events at your venue (if subscribed), 
        connect to activity promoters to boost business performance (if subscribed),
         customized easy-to-manage customer payments, 
         visibility into your recurring customers etc
    </div>


     <!-- activity promoters benefit -->
     <h2>
        # "Activity Promoter(s)" benefits
    </h2>
    
    <hr>

    <div>
        By applying to be an "activity Promoter" and marketing independently for an activity place, 
        you can be eligible to percentage set by the activity host on each booking.   
    </div>
</div>