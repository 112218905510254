import { createAction, props } from "@ngrx/store";
import { EventPromotersLog } from "src/app/types/models/eventPromotersLog.model";


// get all the events that I am promoting
export const LOAD_EVENTS_I_AM_PROMOTING = createAction('[EventPromotersLog] Load Events I am Promoting',
    props<{ promoterUserId: string, pageNumber: number, pageSize: number }>());

export const LOAD_EVENTS_I_AM_PROMOTING_SUCCESS = createAction('[EventPromotersLog] Load Events I am Promoting Success',
    props<{eventsiampromoting: EventPromotersLog[]}>());

export const LOAD_EVENTS_I_AM_PROMOTING_FAIL = createAction('[EventPromotersLog] Load Events I am Promoting Fail',
    props<{error: string}>());